<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าเกม', 'เกม']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายการเกม
      </h5>
      <div class="card-body">
        <button class="btn btn-success text-white mb-2" @click="openFormCreate" v-show="canEdit">
          เพิ่มเกม
        </button>
        <CDataTable
          :items="games"
          :fields="fields"
          :items-per-page-select="false"
          :items-per-page="20"
          :no-items-view="{
            noResults: 'ไม่พบข้อมูล',
            noItems: 'ไม่พบข้อมูล',
          }"
          :table-filter="true"
          border
          striped
          pagination
          dark
          hover
          size="sm"
          add-table-classes="w-100 text-wrap text-center"
        >
          <template #image="{ item }">
            <td data-toggle="tooltip" :title="item.imageURL">
              <img :src="item.imageURL" alt="" width="60" />
            </td>
          </template>
          <template #edit="{ item }">
            <td>
              <button class="btn btn-primary" @click="openFormEdit(item)" :disabled="!canEdit">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </template>
        </CDataTable>
      </div>
    </div>
    <b-modal
      id="modal-form"
      size="xl"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      :title="(form.id == null ? 'เพิ่ม' : 'แก้ไข') + 'ค่ายเกม'"
      cancel-title="ยกเลิก"
      ok-title="ตกลง"
      hide-footer
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="submitForm"
        novalidate
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group row" v-if="form.id">
              <span class="col-12"> #ID: </span>
              <span class="col-12">{{ form.id }}</span>
            </div>
            <div class="form-group row">
              <label for="form-game-code" class="col-12"> รหัส: </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-code"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.code"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-game-name" class="col-12"> ชื่อ: </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-name"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.name"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-game-product">ค่ายเกม:</label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="form.gameProductId"
                    class="form-control"
                    id="form-game-product"
                  >
                    <option
                      v-for="product in gameProducts"
                      :key="product.id"
                      :value="product.id"
                    >
                      {{ product.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-game-image-url" class="col-12"> รูปภาพ: </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-image-url"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.imageURL"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-show-order" class="col-12">
                ลำดับการแสดง:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required|numeric" v-slot="v">
                  <input
                    id="form-show-order"
                    type="text"
                    class="form-control mr-1"
                    v-model.number="form.showOrder"
                    @keypress="$root.onlyNumber"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-status">สถานะ:</label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="form.status"
                    class="form-control"
                    id="form-status"
                  >
                    <option value="OPEN">เปิด</option>
                    <option value="CLOSE">ปิด</option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <div class="col-12 text-right">
                <button class="btn btn-danger" @click="closeForm" type="button">
                  ยกเลิก
                </button>
                <button class="btn btn-primary ml-3" type="submit">
                  {{ form.id ? "บันทึก" : "เพิ่ม" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style>
</style>

<script>
import GameProvider from "@/resources/game_provider"
import SettingProvider from "@/resources/setting_provider"
const gameService = new GameProvider()
const settingService = new SettingProvider()

export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "code",
          label: "รหัส",
        },
        {
          key: "name",
          label: "ชื่อ",
        },
        {
          key: "gameProductName",
          label: "ค่ายเกม",
        },
        {
          key: "image",
          label: "รูปภาพ",
        },
        {
          key: "showOrder",
          label: "ลำดับการแสดง",
        },
        {
          key: "statusTH",
          label: "สถานะ",
        },
        {
          key: "createdAt",
          label: "เวลาสร้าง",
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
        },
        {
          key: "edit",
          label: "แก้ไข",
        },
      ],
      form: {
        id: null,
        code: "",
        name: "",
        gameProductId: null,
        imageURL: "",
        showOrder: null,
        status: "OPEN",
      },
      games: [],
      gameProducts: [],
      isGameLoading: false,
      isGameProductLoading: false,
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("game_edit") 
    },
    items: function () {
      return this.games
    },
    isLoading: function () {
      return this.isGameLoading || this.isGameProductLoading
    },
    gameProductMap: function () {
      let map = {}
      this.gameProducts.forEach((ele) => {
        map[ele.id] = ele
      })
      return map
    },
  },
  async mounted() {
    await this.getAllGameProduct()
    await this.getAllGame()
  },
  methods: {
    clearForm() {
      this.form.id = null
      this.form.code = ""
      this.form.name = ""
      this.form.gameProductId = null
      this.form.imageURL = ""
      this.form.showOrder = null
      this.form.status = "OPEN"
    },
    openFormCreate() {
      this.clearForm()
      this.$bvModal.show("modal-form")
    },
    openFormEdit(item) {
      this.clearForm()
      this.form.id = item.id
      this.form.code = item.code
      this.form.name = item.name
      this.form.gameProductId = item.gameProductId
      this.form.imageURL = item.imageURL
      this.form.showOrder = item.showOrder
      this.form.status = item.status
      this.$bvModal.show("modal-form")
    },
    closeForm() {
      this.$bvModal.hide("modal-form")
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = !this.form.id
        ? await settingService.addGame(this.form)
        : await settingService.editGame(this.form.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.form.id ? "เพิ่มเกมสำเร็จ" : "แก้ไขเกมสำเร็จ",
          () => {
            this.getAllGame()
            this.closeForm()
          }
        )
      }
    },
    async getAllGameProduct() {
      this.isGameProductLoading = true
      const res = await gameService.getAllGameProduct()
      this.isGameProductLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.gameProducts = res.result.list
    },
    async getAllGame() {
      this.isGameLoading = true
      const res = await gameService.getAllGame()
      this.isGameLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      res.result.list?.forEach((item) => {
        item.createdAt = this.$date(item.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )
        item.updatedAt = this.$date(item.updatedAt).format(
          "DD/MM/YYYY HH:mm:ss"
        )
        item.gameProductName = this.getGameProductName(item.gameProductId)
      })

      this.games = res.result.list
    },
    getGameProductName(gameProductId) {
      return this.gameProductMap[gameProductId]?.name
    },
  },
}
</script>