<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['รายการแทง', pageName]" />
    <platform-nav :name="pageName"></platform-nav>
    <round-detail />
    <b-card no-body>
      <b-tabs card>
        <b-tab
          title="แยกตามแพลตฟอร์ม"
          @click="getByMemberData()"
          :active="!$route.query.page || $route.query.page == 'by-member'"
        >
          <b-card-text>
            <CDataTable
              :loading="tableLoadingByMember"
              :items="betResultByPlatforms"
              :fields="getFields()"
              :noItemsView="{
                noResults: 'ไม่พบข้อมูล',
                noItems: 'ไม่พบข้อมูล',
              }"
              :header="false"
              size="sm"
              dark
              border
              striped
              addTableClasses="table-white report"
            >
              <template v-slot:thead-top>
                <tr>
                  <th rowspan="2">
                    <div class="d-flex flex-column">
                      <span>ชื่อแพลตฟอร์ม</span>
                      <div class="d-flex justify-content-center">
                        <input
                          id="showPlatformName"
                          type="checkbox"
                          class="cursor-pointer mr-1 my-auto"
                          v-model="showPlatformName"
                        />
                        <label for="showPlatformName" class="my-auto"
                          >แสดงชื่อ</label
                        >
                      </div>
                    </div>
                  </th>
                  <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
                  <th :colspan="colspan" class="br-white">สมาชิก</th>
                  <th :colspan="colspan" class="br-white">
                    {{ platform.name }}
                  </th>
                  <th :colspan="colspan">บริษัท</th>
                </tr>
                <tr>
                  <!-- member -->
                  <th>ยอดส่งออก</th>
                  <th v-if="!isWaitingPage">ถูกรางวัล</th>
                  <th>ส่วนลด</th>
                  <th>affiliate</th>
                  <th class="br-white">รวม</th>

                  <!-- me -->
                  <th>ถือหุ้น</th>
                  <th v-if="!isWaitingPage">ถูกรางวัล</th>
                  <th>ส่วนลด</th>
                  <th>affiliate</th>
                  <th class="br-white">รวม</th>

                  <!-- company -->
                  <th>ถือหุ้น</th>
                  <th v-if="!isWaitingPage">ถูกรางวัล</th>
                  <th>ส่วนลด</th>
                  <th>affiliate</th>
                  <th>รวม</th>
                </tr>
              </template>
              <template #code="{ item }">
                <td class="text-left">
                  <router-link
                    :to="{
                      name: getNameRouterLink(),
                      params: { platformId: item.platformId },
                    }"
                    >{{ item.code }}</router-link
                  >
                  <span class="ml-1" v-if="showPlatformName"
                    >({{ item.name }})</span
                  >
                </td>
              </template>
              <template v-slot:footer>
                <tfoot>
                  <tr>
                    <td class="text-left">รวม:</td>

                    <!-- member -->
                    <td class="bg-blue br-white">
                      {{ util.formatMoney(sumBetResultByPlatform.buyAmount) }}
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: sumBetResultByPlatform.member.buyAmount > 0,
                          negative: sumBetResultByPlatform.member.buyAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.member.buyAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByPlatform.member.winAmount > 0,
                          negative: sumBetResultByPlatform.member.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.member.winAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.member.discountAmount > 0,
                          negative:
                            sumBetResultByPlatform.member.discountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.member.discountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.member.affiliateAmount > 0,
                          negative:
                            sumBetResultByPlatform.member.affiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.member.affiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: sumBetResultByPlatform.member.sumAmount > 0,
                          negative: sumBetResultByPlatform.member.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.member.sumAmount
                          )
                        }}</span
                      >
                    </td>

                    <!-- me -->
                    <td>
                      <span
                        :class="{
                          positive: sumBetResultByPlatform.me.shareAmount > 0,
                          negative: sumBetResultByPlatform.me.shareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.me.shareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByPlatform.me.winAmount > 0,
                          negative: sumBetResultByPlatform.me.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(sumBetResultByPlatform.me.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.me.discountAmount > 0,
                          negative:
                            sumBetResultByPlatform.me.discountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.me.discountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.me.affiliateAmount > 0,
                          negative:
                            sumBetResultByPlatform.me.affiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.me.affiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: sumBetResultByPlatform.me.sumAmount > 0,
                          negative: sumBetResultByPlatform.me.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(sumBetResultByPlatform.me.sumAmount)
                        }}</span
                      >
                    </td>

                    <!-- company -->
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.company.shareAmount > 0,
                          negative:
                            sumBetResultByPlatform.company.shareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.company.shareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.company.winAmount > 0,
                          negative:
                            sumBetResultByPlatform.company.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.company.winAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.company.discountAmount > 0,
                          negative:
                            sumBetResultByPlatform.company.discountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.company.discountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.company.affiliateAmount > 0,
                          negative:
                            sumBetResultByPlatform.company.affiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.company.affiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByPlatform.company.sumAmount > 0,
                          negative:
                            sumBetResultByPlatform.company.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByPlatform.company.sumAmount
                          )
                        }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </template>
            </CDataTable>
          </b-card-text>
        </b-tab>
        <b-tab
          title="แยกตามประเภท"
          @click="getByCategoryData()"
          :active="$route.query.page == 'by-category'"
        >
          <b-card-text>
            <loading v-show="isLoading" />
            <div class="table-responsive" v-show="!isLoading">
              <table
                class="table table-bordered table-striped table-dark table-white report"
              >
                <thead>
                  <tr>
                    <th rowspan="2">ประเภท</th>
                    <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
                    <th :colspan="colspan" class="br-white">สมาชิก</th>
                    <th :colspan="colspan" class="br-white">
                      {{ platform.name }}
                    </th>
                    <th :colspan="colspan">บริษัท</th>
                  </tr>
                  <tr>
                    <!-- member -->
                    <th>ยอดส่งออก</th>
                    <th v-if="!isWaitingPage">ถูกรางวัล</th>
                    <th>ส่วนลด</th>
                    <th>affiliate</th>
                    <th class="br-white">รวม</th>

                    <!-- me -->
                    <th>ถือหุ้น</th>
                    <th v-if="!isWaitingPage">ถูกรางวัล</th>
                    <th>ส่วนลด</th>
                    <th>affiliate</th>
                    <th class="br-white">รวม</th>

                    <!-- company -->
                    <th>ถือหุ้น</th>
                    <th v-if="!isWaitingPage">ถูกรางวัล</th>
                    <th>ส่วนลด</th>
                    <th>affiliate</th>
                    <th>รวม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in lottoCategorys" :key="index">
                    <td>{{ item.name }}</td>
                    <td class="bg-blue br-white">
                      {{ util.formatMoney(item.betResult.buyAmount) }}
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.member.buyAmount > 0,
                          negative: item.betResult.member.buyAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.buyAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: item.betResult.member.winAmount > 0,
                          negative: item.betResult.member.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.member.discountAmount > 0,
                          negative: item.betResult.member.discountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.discountAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.member.affiliateAmount > 0,
                          negative: item.betResult.member.affiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            item.betResult.member.affiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: item.betResult.member.sumAmount > 0,
                          negative: item.betResult.member.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.member.sumAmount)
                        }}</span
                      >
                    </td>

                    <!-- me -->
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.me.shareAmount > 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.shareAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: item.betResult.me.winAmount > 0,
                          negative: item.betResult.me.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.me.discountAmount > 0,
                          negative: item.betResult.me.discountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.discountAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.me.affiliateAmount > 0,
                          negative: item.betResult.me.affiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.affiliateAmount)
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: item.betResult.me.sumAmount > 0,
                          negative: item.betResult.me.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.me.sumAmount)
                        }}</span
                      >
                    </td>

                    <!-- company -->
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.shareAmount > 0,
                          negative: item.betResult.company.shareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.company.shareAmount)
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: item.betResult.company.winAmount > 0,
                          negative: item.betResult.company.winAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.company.winAmount)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.discountAmount > 0,
                          negative: item.betResult.company.discountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            item.betResult.company.discountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.affiliateAmount > 0,
                          negative: item.betResult.company.affiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            item.betResult.company.affiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive: item.betResult.company.sumAmount > 0,
                          negative: item.betResult.company.sumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(item.betResult.company.sumAmount)
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-left">รวม:</td>

                    <!-- member -->
                    <td class="bg-blue br-white">
                      {{
                        util.formatMoney(sumBetResultByCategory.sumBuyAmount)
                      }}
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumBuyAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumBuyAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumBuyAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumWinAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumWinAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumWinAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumDiscountAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumDiscountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumDiscountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumaffiliateAmount >
                            0,
                          negative:
                            sumBetResultByCategory.member.sumaffiliateAmount <
                            0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumaffiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.member.sumSumAmount > 0,
                          negative:
                            sumBetResultByCategory.member.sumSumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.member.sumSumAmount
                          )
                        }}</span
                      >
                    </td>

                    <!-- me -->
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.me.sumShareAmount > 0,
                          negative:
                            sumBetResultByCategory.me.sumShareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumShareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive: sumBetResultByCategory.me.sumWinAmount > 0,
                          negative: sumBetResultByCategory.me.sumWinAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumWinAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.me.sumDiscountAmount > 0,
                          negative:
                            sumBetResultByCategory.me.sumDiscountAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumDiscountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.me.sumaffiliateAmount > 0,
                          negative:
                            sumBetResultByCategory.me.sumaffiliateAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumaffiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td class="br-white">
                      <span
                        :class="{
                          positive: sumBetResultByCategory.me.sumSumAmount > 0,
                          negative: sumBetResultByCategory.me.sumSumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.me.sumSumAmount
                          )
                        }}</span
                      >
                    </td>

                    <!-- company -->
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumShareAmount > 0,
                          negative:
                            sumBetResultByCategory.company.sumShareAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumShareAmount
                          )
                        }}</span
                      >
                    </td>
                    <td v-if="!isWaitingPage">
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumWinAmount > 0,
                          negative:
                            sumBetResultByCategory.company.sumWinAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumWinAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumDiscountAmount >
                            0,
                          negative:
                            sumBetResultByCategory.company.sumDiscountAmount <
                            0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumDiscountAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumaffiliateAmount >
                            0,
                          negative:
                            sumBetResultByCategory.company.sumaffiliateAmount <
                            0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumaffiliateAmount
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        :class="{
                          positive:
                            sumBetResultByCategory.company.sumSumAmount > 0,
                          negative:
                            sumBetResultByCategory.company.sumSumAmount < 0,
                        }"
                        >{{
                          util.formatMoney(
                            sumBetResultByCategory.company.sumSumAmount
                          )
                        }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fieldsByMember: [
        {
          key: "code",
        },
        {
          key: "member_buyAmount",
          _classes: "bg-blue br-white",
        },
        {
          key: "member_sendAmount",
        },
        {
          key: "member_winAmount",
        },
        {
          key: "member_discountAmount",
        },
        {
          key: "member_affiliateAmount",
        },
        {
          key: "member_sumAmount",
          _classes: "br-white",
        },
        {
          key: "me_shareAmount",
        },
        {
          key: "me_winAmount",
        },
        {
          key: "me_discountAmount",
        },
        {
          key: "me_affiliateAmount",
        },
        {
          key: "me_sumAmount",
          _classes: "br-white",
        },
        {
          key: "company_shareAmount",
        },
        {
          key: "company_winAmount",
        },
        {
          key: "company_discountAmount",
        },
        {
          key: "company_affiliateAmount",
        },
        {
          key: "company_sumAmount",
        },
      ],
      fieldsWaitingByMember: [
        {
          key: "code",
        },
        {
          key: "member_buyAmount",
          _classes: "bg-blue br-white",
        },
        {
          key: "member_sendAmount",
        },
        {
          key: "member_discountAmount",
        },
        {
          key: "member_affiliateAmount",
        },
        {
          key: "member_sumAmount",
          _classes: "br-white",
        },
        {
          key: "me_shareAmount",
        },
        {
          key: "me_discountAmount",
        },
        {
          key: "me_affiliateAmount",
        },
        {
          key: "me_sumAmount",
          _classes: "br-white",
        },
        {
          key: "company_shareAmount",
        },
        {
          key: "company_discountAmount",
        },
        {
          key: "company_affiliateAmount",
        },
        {
          key: "company_sumAmount",
        },
      ],
      tableLoadingByMember: true,
      sumBetResultByPlatform: {
        member: {},
        me: {},
        company: {},
      },
      betResultByPlatforms: [],
      betResultByCategorys: [],
      sumBetResultByCategory: { member: {}, me: {}, company: {} },
      platform: {},
      lottoCategorys: [],
      isLoading: true,
      isWaitingPage:
        this.$route.name == "ItemByRound" ||
        this.$route.name == "ItemUserByRound",
      showPlatformName: false,
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
    roundId() {
      this.getByMemberData()
    },
  },
  computed: {
    colspan() {
      return this.isWaitingPage ? "4" : "5"
    },
    pageName() {
      return this.isWaitingPage
        ? "ดูของ แพลตฟอร์ม/ประเภท"
        : "แพ้-ชนะ แพลตฟอร์มประเภท"
    },
    roundId() {
      return this.$store.state.auth.roundId
    },
  },
  methods: {
    getNameRouterLink() {
      // if (level == "MEMBER")
      //   return this.isWaitingPage ? "ItemOfMember" : "ReportByMemberOfNumber"
      return this.isWaitingPage ? "ItemUserByRound" : "ReportUserByMember"
    },
    getFields() {
      return this.isWaitingPage
        ? this.fieldsWaitingByMember
        : this.fieldsByMember
    },
    getClassAmount(amount) {
      if (parseFloat(amount) > 0) {
        return "positive"
      } else if (parseFloat(amount) < 0) {
        return "negative"
      }
      return ""
    },
    async getByCategoryData() {
      this.$route.query.page = "by-category"

      return this.isWaitingPage
        ? await this.loadWaitingByCategoryData()
        : await this.loadByCategoryData()
    },
    async getByMemberData() {
      this.$route.query.page = "by-member"

      return this.isWaitingPage
        ? await this.loadWaitingByMemberData()
        : await this.loadByMemberData()
    },
    async loadByCategoryData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-result/by-category",
          params: {
            huayRoundId: this.roundId,
            platformId: this.$route.params.platformId,
          },
        })

        this.makeReportByCategory(res.data.data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadByMemberData() {
      this.tableLoadingByMember = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-result/by-platform",
          params: {
            huayRoundId: this.roundId,
            platformId: this.$route.params.platformId,
          },
        })

        this.makeReportByMember(res.data.data)

        this.tableLoadingByMember = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadWaitingByCategoryData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-waiting-result/by-category",
          params: {
            huayRoundId: this.roundId,
            platformId: this.$route.params.platformId,
          },
        })

        this.makeReportByCategory(res.data.data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadWaitingByMemberData() {
      this.tableLoadingByMember = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/bet-waiting-result/by-platform",
          params: {
            huayRoundId: this.roundId,
            platformId: this.$route.params.platformId,
          },
        })

        this.makeReportByMember(res.data.data)

        this.tableLoadingByMember = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    makeReportByCategory(data) {
      data.huayCategorys?.forEach((item) => {
        let betResult = data.platformBetResultByCategorys?.find(
          (item2) => item2.huayCategoryId == item.id
        )

        if (!betResult) {
          item.betResult = {
            buyAmount: 0.0,
            member: {
              buyAmount: 0.0,
              winAmount: 0.0,
              discountAmount: 0.0,
              affiliateAmount: 0.0,
              sumAmount: 0.0,
            },
            me: {
              shareAmount: 0.0,
              winAmount: 0.0,
              discountAmount: 0.0,
              affiliateAmount: 0.0,
              sumAmount: 0.0,
            },
            company: {
              shareAmount: 0.0,
              winAmount: 0.0,
              discountAmount: 0.0,
              affiliateAmount: 0.0,
              sumAmount: 0.0,
            },
          }
          return
        }

        betResult.me = {
          shareAmount: parseFloat(betResult.buyAmountReceive),
          winAmount: this.isWaitingPage
            ? 0.0
            : parseFloat(betResult.payAmountActual),
          discountAmount: parseFloat(betResult.discountAmount),
          affiliateAmount: parseFloat(betResult.affiliateAmount),
        }

        betResult.me.sumAmount =
          betResult.me.shareAmount +
          betResult.me.winAmount +
          betResult.me.discountAmount +
          betResult.me.affiliateAmount

        betResult.member = {
          buyAmount: parseFloat(betResult.buyAmountRemaining) * -1,
          winAmount: this.isWaitingPage
            ? 0.0
            : parseFloat(betResult.payAmountSumMemberActual),
          discountAmount: parseFloat(betResult.discountAmountSumMember),
          affiliateAmount: parseFloat(betResult.affiliateAmountSumMember),
        }

        betResult.member.sumAmount =
          betResult.member.buyAmount +
          betResult.member.affiliateAmount +
          betResult.member.discountAmount +
          betResult.member.winAmount

        betResult.company = {
          shareAmount:
            (betResult.member.buyAmount + betResult.me.shareAmount) * -1,
          winAmount: (betResult.member.winAmount + betResult.me.winAmount) * -1,
          discountAmount:
            (betResult.member.discountAmount + betResult.me.discountAmount) *
            -1,
          affiliateAmount:
            (betResult.member.affiliateAmount + betResult.me.affiliateAmount) *
            -1,
        }

        betResult.company.sumAmount =
          parseFloat(betResult.company.shareAmount) +
          parseFloat(betResult.company.winAmount) +
          parseFloat(betResult.company.discountAmount) +
          parseFloat(betResult.company.affiliateAmount)

        item.betResult = betResult
      })

      this.lottoCategorys = data.huayCategorys
      this.betResultByCategorys = data.betResultByCategorys

      let sumBetResultByCategory = {
        sumBuyAmount: 0.0,
        member: {
          sumBuyAmount: 0.0,
          sumWinAmount: 0.0,
          sumDiscountAmount: 0.0,
          sumaffiliateAmount: 0.0,
          sumSumAmount: 0.0,
        },
        me: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumDiscountAmount: 0.0,
          sumaffiliateAmount: 0.0,
          sumSumAmount: 0.0,
        },
        company: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumDiscountAmount: 0.0,
          sumaffiliateAmount: 0.0,
          sumSumAmount: 0.0,
        },
      }

      data.huayCategorys?.forEach((item) => {
        if (!item.betResult) {
          return
        }

        sumBetResultByCategory.sumBuyAmount += parseInt(
          item.betResult.buyAmount
        )

        sumBetResultByCategory.member.sumBuyAmount +=
          item.betResult.member.buyAmount
        sumBetResultByCategory.member.sumWinAmount +=
          item.betResult.member.winAmount
        sumBetResultByCategory.member.sumDiscountAmount +=
          item.betResult.member.discountAmount
        sumBetResultByCategory.member.sumaffiliateAmount +=
          item.betResult.member.affiliateAmount
        sumBetResultByCategory.member.sumSumAmount +=
          item.betResult.member.sumAmount

        sumBetResultByCategory.me.sumShareAmount +=
          item.betResult.me.shareAmount
        sumBetResultByCategory.me.sumWinAmount += item.betResult.me.winAmount
        sumBetResultByCategory.me.sumDiscountAmount +=
          item.betResult.me.discountAmount
        sumBetResultByCategory.me.sumaffiliateAmount +=
          item.betResult.me.affiliateAmount
        sumBetResultByCategory.me.sumSumAmount += item.betResult.me.sumAmount

        sumBetResultByCategory.company.sumShareAmount +=
          item.betResult.company.shareAmount
        sumBetResultByCategory.company.sumWinAmount +=
          item.betResult.company.winAmount
        sumBetResultByCategory.company.sumDiscountAmount +=
          item.betResult.company.discountAmount
        sumBetResultByCategory.company.sumaffiliateAmount +=
          item.betResult.company.affiliateAmount
        sumBetResultByCategory.company.sumSumAmount +=
          item.betResult.company.sumAmount
      })

      this.sumBetResultByCategory = sumBetResultByCategory
      this.platform = data.platform
    },
    makeReportByMember(data) {
      data.betResultByPlatforms?.forEach((item) => {
        item.member_buyAmount = this.util.formatMoney(item.buyAmount)
        item.member_sendAmount = this.util.formatMoney(
          item.buyAmountRemaining * -1
        )
        item.member_winAmount = this.isWaitingPage
          ? "0.0"
          : this.util.formatMoney(item.payAmountSumMemberActual)
        item.member_discountAmount = this.util.formatMoney(
          item.discountAmountSumMember
        )
        item.member_affiliateAmount = this.util.formatMoney(
          item.affiliateAmountSumMember
        )
        item.member_sumAmount = this.util.formatMoney(
          parseFloat(item.member_sendAmount.replace(",", "")) +
            parseFloat(item.member_winAmount.replace(",", "")) +
            parseFloat(item.member_discountAmount.replace(",", "")) +
            parseFloat(item.member_affiliateAmount.replace(",", ""))
        )

        item.me_shareAmount = this.util.formatMoney(item.buyAmountReceive)
        item.me_winAmount = this.isWaitingPage
          ? "0.0"
          : this.util.formatMoney(item.payAmountActual)
        item.me_discountAmount = this.util.formatMoney(item.discountAmount)
        item.me_affiliateAmount = this.util.formatMoney(item.affiliateAmount)
        item.me_sumAmount = this.util.formatMoney(
          parseFloat(item.me_shareAmount.replace(",", "")) +
            parseFloat(item.me_winAmount.replace(",", "")) +
            parseFloat(item.me_discountAmount.replace(",", "")) +
            parseFloat(item.me_affiliateAmount.replace(",", ""))
        )

        item.company_shareAmount = this.util.formatMoney(
          (parseFloat(item.member_sendAmount.replace(",", "")) +
            parseFloat(item.me_shareAmount.replace(",", ""))) *
            -1
        )
        item.company_winAmount = this.util.formatMoney(
          (parseFloat(item.member_winAmount.replace(",", "")) +
            parseFloat(item.me_winAmount.replace(",", ""))) *
            -1
        )
        item.company_discountAmount = this.util.formatMoney(
          (parseFloat(item.member_discountAmount.replace(",", "")) +
            parseFloat(item.me_discountAmount.replace(",", ""))) *
            -1
        )
        item.company_affiliateAmount = this.util.formatMoney(
          (parseFloat(item.member_affiliateAmount.replace(",", "")) +
            parseFloat(item.me_affiliateAmount.replace(",", ""))) *
            -1
        )
        item.company_sumAmount = this.util.formatMoney(
          parseFloat(item.company_shareAmount.replace(",", "")) +
            parseFloat(item.company_winAmount.replace(",", "")) +
            parseFloat(item.company_discountAmount.replace(",", "")) +
            parseFloat(item.company_affiliateAmount.replace(",", ""))
        )

        item._cellClasses = {
          member_sendAmount: this.getClassAmount(item.member_sendAmount),
          member_winAmount: this.getClassAmount(item.member_winAmount),
          member_discountAmount: this.getClassAmount(
            item.member_discountAmount
          ),
          member_affiliateAmount: this.getClassAmount(
            item.member_affiliateAmount
          ),
          member_sumAmount: this.getClassAmount(item.member_sumAmount),
          me_shareAmount: this.getClassAmount(item.me_shareAmount),
          me_winAmount: this.getClassAmount(item.me_winAmount),
          me_discountAmount: this.getClassAmount(item.me_discountAmount),
          me_affiliateAmount: this.getClassAmount(item.me_affiliateAmount),
          me_sumAmount: this.getClassAmount(item.me_sumAmount),
          company_shareAmount: this.getClassAmount(item.company_shareAmount),
          company_winAmount: this.getClassAmount(item.company_winAmount),
          company_discountAmount: this.getClassAmount(
            item.company_discountAmount
          ),
          company_affiliateAmount: this.getClassAmount(
            item.company_affiliateAmount
          ),
          company_sumAmount: this.getClassAmount(item.company_sumAmount),
        }
      })

      this.betResultByPlatforms = data.betResultByPlatforms

      let sumBetResultByPlatform = {
        buyAmount: 0.0,
        member: {
          buyAmount: 0.0,
          winAmount: 0.0,
          discountAmount: 0.0,
          affiliateAmount: 0.0,
          sumAmount: 0.0,
        },
        me: {
          shareAmount: 0.0,
          winAmount: 0.0,
          discountAmount: 0.0,
          affiliateAmount: 0.0,
          sumAmount: 0.0,
        },
        company: {
          shareAmount: 0.0,
          winAmount: 0.0,
          discountAmount: 0.0,
          affiliateAmount: 0.0,
          sumAmount: 0.0,
        },
      }

      this.betResultByPlatforms?.forEach((item) => {
        sumBetResultByPlatform.buyAmount += parseFloat(
          item.buyAmount.replace(",", "")
        )
        sumBetResultByPlatform.member.buyAmount += parseFloat(
          item.member_sendAmount.replace(",", "")
        )
        sumBetResultByPlatform.member.winAmount += parseFloat(
          item.member_winAmount.replace(",", "")
        )
        sumBetResultByPlatform.member.discountAmount += parseFloat(
          item.member_discountAmount.replace(",", "")
        )
        sumBetResultByPlatform.member.affiliateAmount += parseFloat(
          item.member_affiliateAmount.replace(",", "")
        )
        sumBetResultByPlatform.member.sumAmount += parseFloat(
          item.member_sumAmount.replace(",", "")
        )

        sumBetResultByPlatform.me.shareAmount += parseFloat(
          item.me_shareAmount.replace(",", "")
        )
        sumBetResultByPlatform.me.winAmount += parseFloat(
          item.me_winAmount.replace(",", "")
        )
        sumBetResultByPlatform.me.discountAmount += parseFloat(
          item.me_discountAmount.replace(",", "")
        )
        sumBetResultByPlatform.me.affiliateAmount += parseFloat(
          item.me_affiliateAmount.replace(",", "")
        )
        sumBetResultByPlatform.me.sumAmount += parseFloat(
          item.me_sumAmount.replace(",", "")
        )

        sumBetResultByPlatform.company.shareAmount += parseFloat(
          item.company_shareAmount.replace(",", "")
        )
        sumBetResultByPlatform.company.winAmount += parseFloat(
          item.company_winAmount.replace(",", "")
        )
        sumBetResultByPlatform.company.discountAmount += parseFloat(
          item.company_discountAmount.replace(",", "")
        )
        sumBetResultByPlatform.company.affiliateAmount += parseFloat(
          item.company_affiliateAmount.replace(",", "")
        )
        sumBetResultByPlatform.company.sumAmount += parseFloat(
          item.company_sumAmount.replace(",", "")
        )
      })

      this.sumBetResultByPlatform = sumBetResultByPlatform
      this.platform = data.platform
    },
  },
  created() {
    !this.$route.query.page || this.$route.query.page == "by-member"
      ? this.getByMemberData()
      : this.getByCategoryData()
  },
}
</script>
