<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'งวดหวยตามวัน']" />
    <div class="card border-primary mb-3" style="width: 700px">
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <div class="d-flex flex-column">
          <div class="d-flex mb-2">
            <div class="my-auto mr-2">วันที่:</div>
            <div>
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
                v-model="date"
                placeholder="เลือกวัน"
                label-no-date-selected="ยังไม่เลือก"
                today-button
                reset-button
                close-button
                label-close-button="ปิด"
                label-reset-button="รีเซ็ต"
                label-today="วันนี้"
                label-today-button="วันนี้"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="mb-2">
            <label for="onlyWaitingResult" class="mr-2">เฉพาะรอออกผล:</label>
            <input
              id="onlyWaitingResult"
              type="checkbox"
              class="cursor-pointer"
              v-model="onlyWaitingResult"
            />
          </div>
          <div class="mb-2">
            <label for="hideYeekee" class="mr-2">ซ่อนยี่กี:</label>
            <input
              id="hideYeekee"
              type="checkbox"
              class="cursor-pointer"
              v-model="hideYeekee"
            />
          </div>
          <div class="d-flex">
            <button class="btn btn-primary mr-2" @click="initData">
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        งวดหวยตามวัน
      </h5>
      <div class="card-body">
        <button
          class="btn btn-success mb-2"
          @click="$router.push('/setting/lotto-round')"
          v-show="canEdit"
        >
          เพิ่มงวดหวย
        </button>
        <loading v-show="isLoading" />
        <div class="table-responsive" v-show="!isLoading">
          <table
            class="table table-bordered table-hover table-dark text-center"
          >
            <thead>
              <tr>
                <th>#ID</th>
                <th>ชนิด</th>
                <th>ชื่อ</th>
                <th>รอบที่</th>
                <th>เวลาเปิด</th>
                <th>เวลาปิด</th>
                <th>สถานะ</th>
                <th>แก้ไขโดย</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filterHuayRounds()" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.typeName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.roundNo }}</td>
                <td>{{ $date(item.openTime).format("DD-MM-YYYY HH:mm") }}</td>
                <td>{{ $date(item.closeTime).format("DD-MM-YYYY HH:mm") }}</td>
                <td>{{ item.statusTH }}</td>
                <td>{{ item.updatedBy }}</td>
                <td>
                  <router-link
                    v-if="['SUPER_ADMIN', 'COMPANY'].includes(user.level)"
                    class="btn btn-secondary mr-1"
                    :to="`/setting/lotto-round/${item.id}`"
                    :class="{disabled:!canEdit}"
                  >
                    แก้ไข
                  </router-link>
                  <router-link
                    class="btn btn-info"
                    :to="`/setting/lotto-result/add/${item.id}`"
                    :class="{disabled:!canEdit}"
                  >
                    ออกผล
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      huayRounds: [],
      date: this.$date().format("YYYY-MM-DD"),
      onlyWaitingResult: true,
      hideYeekee: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("lotto_period_by_date_edit") 
    },
  },
  watch: {
    date() {
      this.initData()
    },
  },
  methods: {
    async updateOpenClose(item, status) {
      let statusTH = status == "OPEN" ? "เปิด" : "ปิด"
      let res = await this.$swal.fire({
        title: `ท่านต้องการ ${statusTH}หวย ใช่หรือไม่`,
        html: `${item.typeName} ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      })
      if (!res.isConfirmed) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "patch",
        url: "huay/round",
        data: {
          huayRoundId: item.id,
          status: status,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "สำเร็จ",
          })
          this.$store.dispatch("auth/forceReloadView")
        })
        .catch((err) => {
          console.log(err)
          this.swal.swalError()
        })
    },
    filterHuayRounds() {
      if (this.onlyWaitingResult && this.hideYeekee) {
        return this.huayRounds.filter(
          (item) =>
            item.status == "OPEN" &&
            this.$date(item.closeTime).isBefore(this.$date()) &&
            !item.multiRoundPerDay
        )
      }
      if (this.onlyWaitingResult) {
        return this.huayRounds.filter(
          (item) =>
            item.status == "OPEN" &&
            this.$date(item.closeTime).isBefore(this.$date())
        )
      }
      if (this.hideYeekee) {
        return this.huayRounds.filter((item) => !item.multiRoundPerDay)
      }
      return this.huayRounds
    },
    initData() {
      this.isLoading = true
      this.axios({
        method: "get",
        url: "setting/huay-round/by-date",
        params: {
          date: this.date,
        },
      })
        .then((res) => {
          let data = res.data.data
          if (data) {
            this.huayRounds = data
          } else {
            this.huayRounds = []
          }
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
  },
  mounted() {
    this.initData()
  },
}
</script>