import HttpRequest from "./http_request";

class BetProvider extends HttpRequest {
  async getBetLimitsRound(roundId) {
    return this.get(`bet/limits/round/${roundId}`);
  }

  async addBetLimit(data) {
    return this.create("bet/limit", data);
  }

  async editBetLimits(data) {
    return this.update("bet/limits", data);
  }

  async deleteBetLimits(data) {
    return this.delete("bet/limits", {}, data);
  }

  async getBillItemOfNumbers(roundId, categoryId, number) {
    return this.get(
      `/report/items/of-number/${roundId}/${categoryId}/${number}`
    );
  }

  async getUserHuayBillItemsByUserHuayBillItemID(huayBillItemId) {
    return this.get(`/bet/user-huay-bill-items/${huayBillItemId}`);
  }
}

export default BetProvider;
