<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มหวย', 'เปิด-ปิด หวย/อัตราจ่าย']"
    />
    <platform-nav name="เปิด-ปิด หวย/อัตราจ่าย"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div v-if="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showPlatformName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showPlatformName"
          />
          <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="เปิด/ปิด กลุ่มหวย"
              class="p-0"
              :active="!$route.query.page || $route.query.page == 'group'"
              @click="setPage('group')"
            >
              <b-card-text>
                <b-tabs card>
                  <b-tab
                    :title="item.name"
                    v-for="(item, index) in huayGroups"
                    :key="index"
                    :active="$route.query.id == item.id"
                    @click="setId(item.id)"
                  >
                    <b-card-text>
                      <div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th>
                                <button
                                  class="btn btn-success mr-1"
                                  @click="saveHuayGroup(item.id, 'OPEN')"
                                  :disabled="!canEdit"
                                >
                                  เปิด
                                </button>
                                <button
                                  class="btn btn-danger mr-1"
                                  @click="saveHuayGroup(item.id, 'CLOSE')"
                                  :disabled="!canEdit"
                                >
                                  ปิด
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  :disabled="!canEdit"
                                  v-model="checkAllHuayGroup[index]"
                                  @change="
                                    toggleCheckAllHuayGroup(item.id, index)
                                  "
                                />
                              </th>
                              <th>สถานะ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                platform, index2
                              ) in filterPlatformHuayGroup(item.id)"
                              :key="index2"
                              :class="{
                                'table-danger':
                                  platform.platformStatus == 'CLOSE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformTypePayOpenCloseByPlatformId',
                                    params: { platformId: platform.platformId },
                                    query: {
                                      page: 'group',
                                      id: item.id,
                                    },
                                  }"
                                >
                                  {{ platform.platformCode }}
                                </router-link>
                                <span v-if="showPlatformName"
                                  >({{ platform.platformName }})</span
                                >
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  :disabled="!canEdit"
                                  v-model="platform.check"
                                />
                              </td>
                              <td>
                                {{
                                  huayGroupStatus(
                                    platform.platformId,
                                    item.id
                                  ) == "OPEN"
                                    ? "เปิด"
                                    : "ปิด"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
            <b-tab
              title="เปิด/ปิด หวย"
              class="p-0"
              :active="$route.query.page == 'type'"
              @click="setPage('type')"
            >
              <b-card no-body>
                <b-tabs card>
                  <b-tab
                    :title="huayGroupItem.name"
                    v-for="(huayGroupItem, huayGroupItemIndex) in huayGroups"
                    :key="huayGroupItemIndex"
                    :active="$route.query.huayGroupId == huayGroupItem.id"
                    @click="$route.query.huayGroupId = huayGroupItem.id"
                    class="p-0"
                  >
                    <b-card-text>
                      <b-tabs card>
                        <b-tab
                          :title="item.name"
                          v-for="(item, index) in filterHuayTypeByHuayGroupId(
                            huayGroupItem.id
                          )"
                          :key="index"
                          :active="$route.query.id == item.id"
                          @click="setId(item.id)"
                        >
                          <b-card-text>
                            <div class="table-responsive">
                              <table
                                class="
                                  table table-bordered table-hover table-dark
                                  text-center
                                  fs-075
                                "
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <button
                                        class="btn btn-success mr-1"
                                        @click="saveHuayType(item.id, 'OPEN')"
                                        :disabled="!canEdit"
                                      >
                                        เปิด
                                      </button>
                                      <button
                                        class="btn btn-danger mr-1"
                                        @click="saveHuayType(item.id, 'CLOSE')"
                                        :disabled="!canEdit"
                                      >
                                        ปิด
                                      </button>
                                    </th>
                                    <th>
                                      <input
                                        type="checkbox"
                                        v-model="checkAllHuayType[index]"
                                        :disabled="!canEdit"
                                        @change="
                                          toggleCheckAllHuayType(item.id, index)
                                        "
                                      />
                                    </th>
                                    <th>สถานะ</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      platform, index2
                                    ) in filterPlatformHuayType(item.id)"
                                    :key="index2"
                                    :class="{
                                      'table-danger':
                                        platform.platformStatus == 'CLOSE',
                                    }"
                                  >
                                    <td class="text-left">
                                      <router-link
                                        :to="{
                                          name: 'EditPlatformTypePayOpenCloseByPlatformId',
                                          params: {
                                            platformId: platform.platformId,
                                          },
                                          query: {
                                            page: 'type',
                                            id: item.id,
                                          },
                                        }"
                                      >
                                        {{ platform.platformCode }}
                                      </router-link>
                                      <span v-if="showPlatformName"
                                        >({{ platform.platformName }})</span
                                      >
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        v-model="platform.check"
                                        :disabled="!canEdit"
                                      />
                                    </td>
                                    <td>
                                      {{
                                        huayTypeStatus(
                                          platform.platformId,
                                          item.id
                                        ) == "OPEN"
                                          ? "เปิด"
                                          : "ปิด"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </b-card-text>
                        </b-tab>
                      </b-tabs>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-tab>
            <b-tab
              title="เปิด/ปิด อัตราจ่าย"
              class="p-0"
              :active="$route.query.page == 'payRate'"
              @click="setPage('payRate')"
            >
              <b-card-text>
                <b-tabs card>
                  <b-tab
                    :title="item.name"
                    v-for="(item, index) in huayPays"
                    :key="index"
                    :active="$route.query.id == item.id"
                    @click="setId(item.id)"
                  >
                    <b-card-text>
                      <div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th>
                                <button
                                  class="btn btn-success mr-1"
                                  @click="saveHuayPay(item.id, 'OPEN')"
                                  :disabled="!canEdit"
                                >
                                  เปิด
                                </button>
                                <button
                                  class="btn btn-danger mr-1"
                                  @click="saveHuayPay(item.id, 'CLOSE')"
                                  :disabled="!canEdit"
                                >
                                  ปิด
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllHuayPay[index]"
                                  :disabled="!canEdit"
                                  @change="
                                    toggleCheckAllHuayPay(item.id, index)
                                  "
                                />
                              </th>
                              <th>สถานะ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                platform, index2
                              ) in filterPlatformHuayPay(item.id)"
                              :key="index2"
                              :class="{
                                'table-danger':
                                  platform.platformStatus == 'CLOSE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformTypePayOpenCloseByPlatformId',
                                    params: { platformId: platform.platformId },
                                    query: {
                                      page: 'payRate',
                                      id: item.id,
                                    },
                                  }"
                                >
                                  {{ platform.platformCode }}
                                </router-link>
                                <span v-if="showPlatformName"
                                  >({{ platform.platformCode }})</span
                                >
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="platform.check"
                                  :disabled="!canEdit"
                                />
                              </td>
                              <td>
                                {{
                                  huayPayStatus(platform.platformId, item.id) ==
                                  "OPEN"
                                    ? "เปิด"
                                    : "ปิด"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPlatformName: true,
      isLoading: true,
      isNotFound: false,
      platformHuayGroups: [],
      platformHuayTypes: [],
      platformHuayPays: [],
      huayGroups: [],
      huayTypes: [],
      huayPays: [],
      checkAllHuayGroup: [],
      checkAllHuayType: [],
      checkAllHuayPay: [],
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_platform_type_pay_open_close_edit") 
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    setId(val) {
      this.$route.query.id = val
    },
    filterHuayTypeByHuayGroupId(huayGroupId) {
      return this.huayTypes.filter((item) => item.huayGroupId === huayGroupId)
    },
    filterPlatformHuayGroup(huayGroupId) {
      return this.platformHuayGroups.filter(
        (item) => item.huayGroupId == huayGroupId
      )
    },
    filterPlatformHuayType(huayTypeId) {
      return this.platformHuayTypes.filter(
        (item) => item.huayTypeId == huayTypeId
      )
    },
    filterPlatformHuayPay(huayPayId) {
      return this.platformHuayPays.filter((item) => item.huayPayId == huayPayId)
    },
    toggleCheckAllHuayGroup(huayGroupId, index) {
      this.platformHuayGroups.forEach((item) => {
        if (item.huayGroupId == huayGroupId) {
          item.check = this.checkAllHuayGroup[index]
        }
      })
    },
    toggleCheckAllHuayType(huayTypeId, index) {
      this.platformHuayTypes.forEach((item) => {
        if (item.huayTypeId == huayTypeId) {
          item.check = this.checkAllHuayType[index]
        }
      })
    },
    toggleCheckAllHuayPay(huayPayId, index) {
      this.platformHuayPays.forEach((item) => {
        if (item.huayPayId == huayPayId) {
          item.check = this.checkAllHuayPay[index]
        }
      })
    },
    huayGroupStatus(platformId, huayGroupId) {
      let result = this.platformHuayGroups.find(
        (item) =>
          item.platformId == platformId && item.huayGroupId == huayGroupId
      )
      if (result) {
        return result.status
      }
    },
    huayTypeStatus(platformId, huayTypeId) {
      let result = this.platformHuayTypes.find(
        (item) => item.platformId == platformId && item.huayTypeId == huayTypeId
      )
      if (result) {
        return result.status
      }
    },
    huayPayStatus(platformId, huayPayId) {
      let result = this.platformHuayPays.find(
        (item) => item.platformId == platformId && item.huayPayId == huayPayId
      )
      if (result) {
        return result.status
      }
    },
    async saveHuayGroup(huayGroupId, status) {
      let platformHuayGroups = this.platformHuayGroups.filter(
        (item) => item.check && item.huayGroupId == huayGroupId
      )

      if (platformHuayGroups.length == 0) {
        return
      }

      platformHuayGroups.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "platform/openclose-huay-group",
          data: {
            platformHuayGroups: platformHuayGroups,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        }).then(this.initData())
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async saveHuayType(huayTypeId, status) {
      let platformHuayTypes = this.platformHuayTypes.filter(
        (item) => item.check && item.huayTypeId == huayTypeId
      )

      if (platformHuayTypes.length == 0) {
        return
      }

      platformHuayTypes.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "platform/openclose-huay-type",
          data: {
            platformHuayTypes: platformHuayTypes,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        }).then(this.initData())
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async saveHuayPay(huayPayId, status) {
      let platformHuayPays = this.platformHuayPays.filter(
        (item) => item.check && item.huayPayId == huayPayId
      )

      if (platformHuayPays.length == 0) {
        return
      }

      platformHuayPays.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "platform/openclose-huay-pay",
          data: {
            platformHuayPays: platformHuayPays,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
      } catch (e) {
        await this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "platform/openclose-huay",
          params: {
            platformId: this.$route.params.platformId,
          },
        })

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }
        let data = res.data.data

        data.platformHuayGroups.forEach((item) => {
          let platform = data.platforms.find(
            (item2) => item2.id == item.platformId
          )
          if (platform) {
            item.platformStatus = platform.status
            item.platformCode = platform.code
            item.platformName = platform.name
            item.platformId = platform.id
            item.check = false
          }
        })

        data.platformHuayTypes.forEach((item) => {
          let platform = data.platforms.find(
            (item2) => item2.id == item.platformId
          )
          if (platform) {
            item.platformStatus = platform.status
            item.platformCode = platform.code
            item.platformName = platform.name
            item.platformId = platform.id
            item.check = false
          }
        })

        data.platformHuayPays.forEach((item) => {
          let platform = data.platforms.find(
            (item2) => item2.id == item.platformId
          )
          if (platform) {
            item.platformStatus = platform.status
            item.platformCode = platform.code
            item.platformName = platform.name
            item.platformId = platform.id
            item.check = false
          }
        })

        this.platformHuayGroups = data.platformHuayGroups
        this.platformHuayTypes = data.platformHuayTypes
        this.platformHuayPays = data.platformHuayPays
        this.huayTypes = data.huayTypes
        this.huayGroups = data.huayGroups
        this.huayPays = data.huayPays

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
