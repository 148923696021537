<template>
  <div class="p-4">
    <div class="row">
      <div class="col-12 col-md-auto" style="font-size: 20px">{{ username }}</div>
      <div class="col-auto">ค่าคอมมิชชั่นหวย {{ commissionHuay }}%</div>
      <div class="col-auto p-0">ค่าคอมมิชชั่นเกม {{ commissionGame }}%</div>
      <div class="col p-0"></div>
      <div class="d-flex col-auto gap mt-2 mt-md-0" style="font-size: 5px;">
        <div>
          <button class="btn btn-sm btn-primary font-button" @click="setDateRange('TODAY')">
            วันนี้
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-primary font-button" @click="setDateRange('YESTERDAY')">
            เมื่อวาน
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-primary font-button" @click="setDateRange('WEEK')">
            สัปดาห์นี้
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-primary font-button" @click="setDateRange('LASTWEEK')">
            สัปดาห์ที่แล้ว
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-primary font-button" @click="setDateRange('MONTH')">
            เดือนนี้
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-primary font-button" @click="setDateRange('LASTMONTH')">
            เดือนที่แล้ว
          </button>
        </div>
      </div>

      <div class="d-flex my-2 my-md-0 col-12 col-md-4">
        <b-form-datepicker class="date" :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }" locale="th" v-model="fromDate" placeholder="เลือกวัน" label-no-date-selected="ยังไม่เลือก" today-button
          reset-button close-button label-close-button="ปิด" label-reset-button="รีเซ็ต" label-today="วันนี้"
          label-today-button="วันนี้"></b-form-datepicker>
        <span class="mx-1 my-auto">ถึง</span>
        <b-form-datepicker class="date" :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }" locale="th" v-model="toDate" placeholder="เลือกวัน" label-no-date-selected="ยังไม่เลือก" today-button
          reset-button close-button label-close-button="ปิด" label-reset-button="รีเซ็ต" label-today="วันนี้"
          label-today-button="วันนี้"></b-form-datepicker>
        <button class="btn btn-primary ml-2" @click="loadData">ค้นหา</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #bedeff; border-radius: 3px">
          <div class="row justify-content-center">จำนวนลูกค้าทั้งหมด</div>
          <div class="row justify-content-center">{{ sumCustomer }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #6bd7ff; border-radius: 3px">
          <div class="row justify-content-center">จำนวนที่สมัครใหม่</div>
          <div class="row justify-content-center">{{ sumNewCustomer }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #a8e28e; border-radius: 3px">
          <div class="row justify-content-center">ยอดฝากรวม</div>
          <div class="row justify-content-center">{{ sumDeposit }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #ff7e7e; border-radius: 3px">
          <div class="row justify-content-center">ยอดถอนรวม</div>
          <div class="row justify-content-center">{{ sumWithdraw }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #d2b595; border-radius: 3px">
          <div class="row justify-content-center">แพ้ชนะ(หวย)</div>
          <div class="row justify-content-center">{{ sumWinLoseHuay }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #e2753f; border-radius: 3px">
          <div class="row justify-content-center">แพ้ชนะ(เกม)</div>
          <div class="row justify-content-center">{{ sumWinLoseGame }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #FFC0CB; border-radius: 3px">
          <div class="row justify-content-center">คอม(หวย)</div>
          <div class="row justify-content-center">{{ sumCommissionHuay }}</div>
        </div>
      </div>
      <div class="col-6 col-md-3 text-black mt-2 p-2">
        <div class="p-4" style="background-color: #FF69B4; border-radius: 3px">
          <div class="row justify-content-center">คอม(เกม)</div>
          <div class="row justify-content-center">{{ sumCommissionGame }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.cardSian {
  border-radius: 3px;
}

.gap {
  gap: 10px;
}

@media screen and (max-width: 800px) {
  .date {
    width: 300px;
    align-self: center;
    font-size: 11px;
  }

  .font-button {
    font-size: 10px;
  }
}
</style>

<script>
export default {
  meta: [{ name: "viewport", content: "width=500" }],

  data() {
    return {
      fromDate: this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      sumCustomer: 0,
      sumNewCustomer: 0,
      sumDeposit: 0,
      sumWithdraw: 0,
      sumWinLoseHuay: 0,
      sumWinLoseGame: 0,
      sumCommissionHuay: 0,
      sumCommissionGame: 0,
      userId: "",
      username: "",
      commissionHuay: "",
      commissionGame: "",
    }
  },
  mounted() {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=device-width, initial-scale=1.0")
    this.loadData()
  },
  methods: {
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.loadData()
    },
    async loadData() {
      this.isLoading = true

      try {
        let userId = this.$route.params.userId
        var id = this.$route.params.id
        console.log(userId)
        console.log(id)
        let res = await this.axios({
          method: "get",
          url: "users/member/result-sian",
          params: {
            startTime: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            endTime: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            userId: this.$route.params.userId,
          },
        })
        let data = res.data.data
        this.sumCustomer = data.sumCustomer
        this.sumNewCustomer = data.sumNewCustomer
        this.sumDeposit = this.util.formatMoney(data.sumDeposit)
        this.sumWinLoseHuay = this.util.formatMoney(data.sumWinLoseHuay)
        this.sumWinLoseGame = this.util.formatMoney(data.sumWinLoseGame)
        this.sumCommissionHuay = this.util.formatMoney(data.sumCommissionHuay)
        this.sumCommissionGame = this.util.formatMoney(data.sumCommissionGame)
        this.sumWithdraw = this.util.formatMoney(data.sumWithdraw)
        this.userId = data.userId
        this.username = data.username
        this.commissionGame = this.util.formatMoney(data.commissionGame)
        this.commissionHuay = this.util.formatMoney(data.commissionHuay)
        // this.makeReport(data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
}
</script>
