<template>
  <div>
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <bread-cum v-bind:breadcrumbItems="['การเงิน', pageName]" />
      <ValidationObserver
        ref="observerEditCredit"
        tag="form"
        @submit.prevent="saveCreditMaximum"
        v-if="showEditCredit"
      >
        <div class="card border-primary mb-3">
          <h5 class="card-header border-primary bg-primary">แก้ไขเครดิต</h5>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อผู้ใช้:</div>
              <div class="col-md-2 p-md-0">{{ memberEditCredit.username }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ระดับ:</div>
              <div class="col-md-2 p-md-0">{{ memberEditCredit.levelTH }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อ:</div>
              <div class="col-md-2 p-md-0">{{ memberEditCredit.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เครดิต:</div>
              <div class="col-md-2 p-md-0">
                <span>{{ memberEditCredit.creditMaximum }}</span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2 text-md-right">
                <span>จำนวนเครดิต:</span>
              </div>
              <div class="col-md-2 p-md-0">
                <ValidationProvider :rules="`required|min_value:0`" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="จำนวนเครดิต"
                    v-model="memberEditCredit.creditMaximumEdit"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2 p-md-0">
                <button class="btn btn-success mr-1" type="submit">
                  บันทึก
                </button>
                <button class="btn btn-danger" @click="showEditCredit = false">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver
        ref="observerCreditDeposit"
        tag="form"
        @submit.prevent="depositCredit"
        v-if="showDeposit"
      >
        <div class="card border-primary mb-3">
          <h5 class="card-header border-primary bg-primary">ฝากเงิน</h5>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อผู้ใช้:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.username }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ระดับ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.levelTH }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือ:</div>
              <div class="col-md-2 p-md-0">
                <span :class="getClassCredit(memberTransfer.creditBalance)">
                  {{ util.formatMoney(memberTransfer.creditBalance) }}
                </span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือตัวเรา:</div>
              <div class="col-md-2 p-md-0">
                <span :class="getClassCredit(user.creditBalance)">
                  {{ util.formatMoney(user.creditBalance) }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2 text-md-right">
                <span>จำนวนฝาก:</span>
              </div>
              <div class="col-md-2 p-md-0">
                <ValidationProvider
                  :rules="`required|min_value:0|max_value:${user.creditBalance}`"
                  v-slot="v"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="จำนวนฝาก"
                    v-model="memberTransfer.creditDeposit"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2 p-md-0">
                <button class="btn btn-success mr-1" type="submit">
                  บันทึก
                </button>
                <button class="btn btn-danger" @click="showDeposit = false">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver
        ref="observerCreditWithdraw"
        tag="form"
        @submit.prevent="withdrawCredit"
        v-if="showWithdraw"
      >
        <div class="card border-primary mb-3" v-if="showWithdraw">
          <h5 class="card-header border-primary bg-primary">ถอนเงิน</h5>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อผู้ใช้:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.username }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ระดับ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.levelTH }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">ชื่อ:</div>
              <div class="col-md-2 p-md-0">{{ memberTransfer.name }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือ:</div>
              <div class="col-md-2 p-md-0">
                <span class="positive">{{
                  util.formatMoney(memberTransfer.creditBalance)
                }}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-2 text-md-right">เงินคงเหลือตัวเรา:</div>
              <div class="col-md-2 p-md-0">
                <span :class="getClassCredit(user.creditBalance)">{{
                  util.formatMoney(user.creditBalance)
                }}</span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2 text-md-right">
                <span>จำนวนถอน:</span>
              </div>
              <div class="col-md-2 p-md-0">
                <ValidationProvider :rules="`required|min_value:0`" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="จำนวนถอน"
                    v-model="memberTransfer.creditWithdraw"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2 p-md-0">
                <button class="btn btn-success mr-1" type="submit">
                  บันทึก
                </button>
                <button class="btn btn-danger" @click="showWithdraw = false">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <CDataTable
        :items="members"
        :fields="fields"
        :items-per-page-select="false"
        :items-per-page.sync="itemsPerPage"
        :column-filter-value.sync="columnFilterValue"
        :noItemsView="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }"
        v-on:pagination-change="paginationChange"
        v-on:update:column-filter-value="columnFilter"
        :column-filter="{ external: !isAgentPage }"
        :sorter="false"
        :header="false"
        pagination
        dark
        border
        striped
        addTableClasses="report"
      >
        <template v-slot:thead-top>
          <tr>
            <th style="width: 100px">#ID</th>
            <th>ชื่อผู้ใช้</th>
            <th>ระดับ</th>
            <th>ชื่อ</th>
            <th>เงินคงเหลือ</th>
            <th>
              <span class="positive">+ฝาก</span>/<span class="negative"
                >-ถอน</span
              >
            </th>
          </tr>
        </template>
        <template #creditBalance="{ item }">
          <td
            :class="{
              positive: item.creditBalance > 0,
              '': item.creditBalance == 0,
              negative: item.creditBalance < 0,
            }"
          >
            {{ util.formatMoney(item.creditBalance) }}
          </td>
        </template>
        <template #editCreditBalance="{ item }">
          <td class="d-flex justify-content-center">
            <button class="btn btn-success mr-1" @click="setShowDeposit(item)" :disabled="!canEdit">
              ฝากเงิน
            </button>
            <button class="btn btn-danger" @click="setShowWithdraw(item)" :disabled="!canEdit">
              ถอนเงิน
            </button>
          </td>
        </template>
      </CDataTable>
      <CPagination
        v-if="!isAgentPage"
        :active-page="activePage"
        :double-arrows="false"
        :pages="totalPage"
        :dots="false"
        v-on:update:activePage="updateActivePage"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          filter: true,
          sorter: false,
        },
        {
          key: "username",
          filter: true,
          sorter: false,
        },
        {
          key: "levelTH",
          filter: false,
          sorter: false,
        },
        {
          key: "name",
          filter: false,
          sorter: false,
        },
        {
          key: "creditBalance",
          filter: false,
          sorter: false,
        },
        {
          key: "editCreditBalance",
          filter: false,
          sorter: false,
        },
      ],
      columnFilterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      memberEditCredit: {},
      memberTransfer: {},
      showEditCredit: false,
      showDeposit: false,
      showDepositBonus: false,
      showWithdraw: false,
      isLoading: true,
      members: [],
      sumCreditBalance: 0,
      user: {},
      checkAllClearCredit: false,
      isAgentPage: this.$route.name === "CreditTransferAgent",
      pageName:
        this.$route.name === "CreditTransferAgent"
          ? "เติมเงินเอเย่นต์"
          : "เติมเงินเมมเบอร์",
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    columnFilter() {
      if (this.isAgentPage) {
        return
      }

      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
    paginationChange(val) {
      if (this.isAgentPage) {
        return
      }

      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (this.isAgentPage) {
        return
      }

      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.members &&
        this.members.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    toggleCheckAllClearCredit() {
      this.members.forEach((item) => {
        item.checkClearCredit = this.checkAllClearCredit
      })
    },
    getClassCredit(a) {
      if (parseFloat(a) == parseFloat(0)) {
        return ""
      }
      if (parseFloat(a) > parseFloat(0)) {
        return "positive"
      }
      return "negative"
    },
    setShowEditCredit(member) {
      this.showEditCredit = true
      this.showDeposit = false
      this.showDepositBonus = false
      this.showWithdraw = false
      this.memberEditCredit = member
      this.$el.querySelector("#info").scrollIntoView({
        block: "center",
      })
    },
    setShowDeposit(member) {
      this.showEditCredit = false
      this.showDeposit = true
      this.showDepositBonus = false
      this.showWithdraw = false
      this.memberTransfer = member
      this.$el.querySelector("#info").scrollIntoView({
        block: "center",
      })
    },
    setShowWithdraw(member) {
      this.showEditCredit = false
      this.showDeposit = false
      this.showDepositBonus = false
      this.showWithdraw = true
      this.memberTransfer = member
      this.$el.querySelector("#info").scrollIntoView({
        block: "center",
      })
    },
    setShowDepositBonus(member) {
      this.showEditCredit = false
      this.showDeposit = false
      this.showDepositBonus = true
      this.showWithdraw = false
      this.memberTransfer = member
      this.$el.querySelector("#info").scrollIntoView({
        block: "center",
      })
    },
    async saveCreditMaximum() {
      let observer = this.$refs.observerEditCredit
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "credit/set-credit-maximum",
        data: this.memberEditCredit,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            text: "แก้ไขเครดิตสำเร็จ",
          }).then(() => {
            this.loadData()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: error.response.data.description,
          })
        })
    },
    async withdrawCredit() {
      let observer = this.$refs.observerCreditWithdraw
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "credit/transfer",
        data: {
          userId: this.memberTransfer.id,
          creditWithdraw: this.memberTransfer.creditWithdraw,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            text: "ถอนเงินสำเร็จ",
          }).then(() => {
            this.$store.dispatch("auth/forceReload")
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: error.response.data.description,
          })
        })
    },
    async depositCredit() {
      let observer = this.$refs.observerCreditDeposit
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "credit/transfer",
        data: {
          userId: this.memberTransfer.id,
          creditDeposit: this.memberTransfer.creditDeposit,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            text: "ฝากเงินสำเร็จ",
          }).then(() => {
            this.$store.dispatch("auth/forceReload")
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: error.response.data.description,
          })
        })
    },
    clearCredit() {
      let checked = this.members.find((item) => item.checkClearCredit)
      if (!checked) {
        return
      }

      let transferCredits = []
      this.members.forEach((item) => {
        if (item.checkClearCredit) {
          let creditDeposit, creditWithdraw
          if (parseFloat(item.creditDiff) > parseFloat(0)) {
            creditWithdraw = item.creditDiff
          } else if (parseFloat(item.creditDiff) < parseFloat(0)) {
            creditDeposit = parseFloat(item.creditDiff) * -1
          } else {
            return
          }

          transferCredits.push({
            userId: item.id,
            creditDeposit: creditDeposit,
            creditWithdraw: creditWithdraw,
          })
        }
      })

      if (transferCredits.length == 0) {
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "credit/clear",
        data: {
          transferCredits: transferCredits,
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            text: "เคลียยอดสำเร็จ",
          }).then(() => {
            this.$store.dispatch("auth/forceReload")
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: error.response.data.description,
          })
        })
    },
    async loadData() {
      try {
        let res = {}

        if (!this.isAgentPage) {
          res = await this.axios({
            method: "get",
            url: "users/credit-transfer-data",
            params: {
              ...this.columnFilterValue,
              page: this.activePage,
              pageSize: this.itemsPerPage,
            },
          })
        } else {
          res = await this.axios({
            method: "get",
            url: "users/credit-transfer-data/agent",
          })
        }

        if (res.data.code == -4) {
          this.members = []
          this.isLoading = false
          return
        }

        this.sumCreditBalance = parseFloat(0.0)
        this.showEditCredit = false
        this.showWithdraw = false
        this.showDeposit = false
        this.checkAllClearCredit = false

        let data = res.data.data
        data.members.data?.forEach((item) => {
          item.creditMaximum = parseFloat(item.creditMaximum).toFixed(2)
          item.creditBalance = parseFloat(item.creditBalance).toFixed(2)
          item.creditDeposit = null
          item.creditWithdraw = null
          item.creditMaximumEdit = null
          item.checkClearCredit = false

          item.creditDiff = (
            parseFloat(item.creditBalance) - parseFloat(item.creditMaximum)
          ).toFixed(2)

          item._cellClasses = {
            creditDiff: this.getClassCredit(item.creditDiff),
            creditBalance: this.getClassCredit(item.creditBalance),
            creditBonus: this.getClassCredit(item.creditBonus),
          }

          this.sumCreditBalance =
            parseFloat(this.sumCreditBalance) + parseFloat(item.creditBalance)
        })

        // this.totalPage = data.members.totalPage;
        this.$store.state.auth.user.userData = data.user
        this.user = data.user
        this.sumCreditBalance = this.sumCreditBalance.toFixed(2)
        this.members = data.members.data
        this.isLoading = false
      } catch (e) {
        console.log(e)

        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
  },
  mounted() {
    this.loadData()
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes(this.$route.name === "CreditTransferAgent" ?"credit_transfer_agent_edit":"credit_transfer_member_edit") 
    }
  }
}
</script>