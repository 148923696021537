import $ from 'jquery';
<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'เพิ่มงวดหวยเป็นชุด']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        เพิ่มงวดหวยเป็นชุด
      </h5>
      <div class="card-body">
        <ValidationObserver ref="observer">
          <div class="form-group row">
            <div class="col-md-1 text-md-right">ชื่อ:</div>
            <div class="col-md-2">
              <input type="text" class="form-control" v-model="form.name" />
            </div>
          </div>
          <div class="form-group row pb-4 mb-4 border-bottom">
            <div class="col-md-1 text-md-right my-auto">ตั้งแต่วันที่</div>
            <div class="col-md-2">
              <ValidationProvider rules="required" v-slot="v">
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="th"
                  v-model="form.startDate"
                  placeholder="เลือกวัน"
                  label-no-date-selected="ยังไม่เลือก"
                  today-button
                  reset-button
                  close-button
                  label-close-button="ปิด"
                  label-reset-button="รีเซ็ต"
                  label-today="วันนี้"
                  label-today-button="วันนี้"
                />
                <input type="text" hidden v-model="form.startDate" />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-1 text-md-right my-auto">ถึง</div>
            <div class="col-md-2">
              <ValidationProvider rules="required" v-slot="v">
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="th"
                  v-model="form.endDate"
                  placeholder="เลือกวัน"
                  label-no-date-selected="ยังไม่เลือก"
                  today-button
                  reset-button
                  close-button
                  label-close-button="ปิด"
                  label-reset-button="รีเซ็ต"
                  label-today="วันนี้"
                  label-today-button="วันนี้"
                />
                <input type="text" hidden v-model="form.endDate" />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <h3 class="text-gold">เลือกชนิด</h3>
          <loading v-show="isLoading" />
          <div v-show="!isLoading">
            <div class="table-responsive">
              <table
                class="table table-bordered table-hover table-dark text-center"
              >
                <thead>
                  <tr>
                    <th>ชื่อกลุ่ม</th>
                    <th>ทั้งหมด</th>
                    <th>ชนิด</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in huayGroups" :key="index">
                    <td>{{ item.name }}</td>
                    <td>
                      <input type="checkbox" @click="checkAllGroup(item.id)" />
                    </td>
                    <td class="text-left">
                      <div
                        v-for="huayType in filterHuayTypes(item.id)"
                        :key="huayType.id"
                        class="d-inline"
                      >
                        <input
                          type="checkbox"
                          class="mr-2"
                          :id="huayType.id"
                          v-model="huayType.checked"
                        />
                        <label :for="huayType.id" class="mr-4 mb-0">
                          {{ huayType.name }}
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn btn-danger mr-2" @click="$router.go()">ยกเลิก</div>
            <div class="btn btn-primary" @click="save">บันทึก</div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      isLoading: true,
      huayTypes: {},
      huayGroups: {},
    }
  },
  methods: {
    async save() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "setting/huay-round/multi",
        data: {
          huayTypes: this.huayTypes.filter((item) => item.checked),
          name: this.form.name,
          startDate: this.$date(this.form.startDate).format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          endDate: this.$date(this.form.endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
        },
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "เพิ่มงวดสำเร็จ",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/setting/lotto-period-by-date")
            }
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    checkAllGroup(huayGroupId) {
      this.huayTypes.forEach((item) => {
        if (item.huayGroupId == huayGroupId) {
          item.checked = !item.checked
        }
      })
    },
    filterHuayTypes(huayGroupId) {
      return this.huayTypes.filter((item) => item.huayGroupId == huayGroupId)
    },
    initData() {
      this.axios({
        method: "get",
        url: "setting/huay-round/multi",
      })
        .then((res) => {
          let data = res.data.data
          data.huayTypes.forEach((item) => {
            item.checked = false
          })
          this.huayTypes = data.huayTypes
          this.huayGroups = data.huayGroups
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
  },
  mounted() {
    this.initData()
  },
}
</script>