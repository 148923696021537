<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['การเงิน', 'ประวัติการเงิน']" />
    <credit-transfer-history></credit-transfer-history>
  </div>
</template>

<script>
import CreditTransferHistory from "@/components/CreditTransferHistory.vue"
export default {
  components: { CreditTransferHistory },
}
</script>