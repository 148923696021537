<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "assets/scss/style";
</style>


<script>
import update from "./mixins/update";

export default {
  mixins: [update],
};
</script>