<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="[
        'ตั้งค่าแพลตฟอร์มหวย',
        'ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข',
      ]"
    />
    <platform-nav name="ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showPlatformName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showPlatformName"
          />
          <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="ขั้นต่ำ"
              :active="!$route.query.page || $route.query.page == 'buyMin'"
              @click="setPage('buyMin')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th>
                          <button
                            class="btn btn-primary mr-1"
                            @click="saveCategorys"
                            :disabled="!canEdit"
                          >
                            บันทึก
                          </button>
                          <button class="btn btn-danger" @click="clearBuyMin" :disabled="!canEdit">
                            ยกเลิก
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            v-model="checkAllBuyMin"
                            @change="toggleCheckAllBuyMin"
                            :disabled="!canEdit"
                          />
                        </th>
                        <th
                          v-for="(item, index) in huayCategorys"
                          :key="index"
                          class="align-top"
                        >
                          <ValidationProvider
                            :rules="`min_value:${item.fixBuyMin}|numeric`"
                            v-slot="v"
                          >
                            <div class="d-flex flex-column min-width-5">
                              <span>{{ item.huayCategoryName }}</span>
                              <input
                                type="number"
                                :readonly="!canEdit"
                                class="form-control form-control-sm form-small"
                                @keypress="$root.onlyNumber"
                                v-model.number="item.buyMin"
                                v-if="showItem(item.status)"
                              />
                              <span v-if="showItem(item.status)">
                                >=
                                <span class="text-gold">
                                  {{ item.fixBuyMin }}
                                </span>
                              </span>
                              <span class="text-danger">
                                {{ v.errors[0] }}
                              </span>
                            </div>
                          </ValidationProvider>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in platforms"
                        :key="index"
                        :class="{ 'table-danger': item.status == 'INACTIVE' }"
                      >
                        <td class="text-left">
                          <router-link
                            :to="{
                              name: 'EditPlatformCategoryByPlatformId',
                              params: { platformId: item.id },
                              query: { page: 'buyMin' },
                            }"
                          >
                            {{ item.code }}
                          </router-link>
                          <!-- <span>{{ item.code }} </span> -->
                          <span v-if="showPlatformName">({{ item.name }})</span>
                        </td>
                        <td>
                          <input type="checkbox" v-model="item.checkBuyMin" :disabled="!canEdit"/>
                        </td>
                        <td
                          v-for="(
                            itemBuyMin, indexBuyMin
                          ) in filterPlatformHuayCategorys(item.id)"
                          :key="indexBuyMin"
                        >
                          <span v-if="showItem(itemBuyMin.status)">{{
                            itemBuyMin.buyMin
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab
              title="สูงสุดต่อโพย"
              :active="$route.query.page == 'buyMaxPerBill'"
              @click="setPage('buyMaxPerBill')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th>
                          <button
                            class="btn btn-primary mr-1"
                            @click="saveCategorys"
                            :disabled="!canEdit"
                          >
                            บันทึก
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="clearBuyMaxPerBill"
                            :disabled="!canEdit"
                          >
                            ยกเลิก
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            v-model="checkAllBuyMaxPerBill"
                            @change="toggleCheckAllBuyMaxPerBill"
                            :disabled="!canEdit"
                          />
                        </th>
                        <th
                          v-for="(item, index) in huayCategorys"
                          :key="index"
                          class="align-top"
                        >
                          <ValidationProvider
                            :rules="`max_value:${item.fixBuyMaxPerBill}|min_value:0|numeric`"
                            v-slot="v"
                          >
                            <div class="d-flex flex-column min-width-5">
                              <span>{{ item.huayCategoryName }}</span
                              ><input
                                type="number"
                                class="form-control form-control-sm form-small"
                                @keypress="$root.onlyNumber"
                                v-model.number="item.buyMaxPerBill"
                                v-if="showItem(item.status)"
                                :readonly="!canEdit"
                              />
                              <span v-if="showItem(item.status)">
                                &#x3C;=
                                <span class="text-gold">
                                  {{ item.fixBuyMaxPerBill }}
                                </span>
                              </span>
                              <span class="text-danger">{{ v.errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in platforms"
                        :key="index"
                        :class="{ 'table-danger': item.status == 'INACTIVE' }"
                      >
                        <td class="text-left">
                          <router-link
                            :to="{
                              name: 'EditPlatformCategoryByPlatformId',
                              params: { platformId: item.id },
                              query: { page: 'buyMaxPerBill' },
                            }"
                          >
                            {{ item.code }}
                          </router-link>
                          <!-- <span>{{ item.code }}</span> -->
                          <span v-if="showPlatformName">({{ item.name }})</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            v-model="item.checkBuyMaxPerBill"
                            :disabled="!canEdit"
                          />
                        </td>
                        <td
                          v-for="(
                            itemBuyMaxPerBill, indexBuyMaxPerBill
                          ) in filterPlatformHuayCategorys(item.id)"
                          :key="indexBuyMaxPerBill"
                        >
                          <span v-if="showItem(itemBuyMaxPerBill.status)">{{
                            itemBuyMaxPerBill.buyMaxPerBill
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab
              title="สูงสุดต่อเลขต่อคน"
              :active="$route.query.page == 'buyMaxPerNumber'"
              @click="setPage('buyMaxPerNumber')"
            >
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th>
                          <button
                            class="btn btn-primary mr-1"
                            @click="saveCategorys"
                            :disabled="!canEdit"
                          >
                            บันทึก
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="clearBuyMaxPerNumber"
                            :disabled="!canEdit"
                          >
                            ยกเลิก
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            v-model="checkAllBuyMaxPerNumber"
                            @change="toggleCheckAllBuyMaxPerNumber"
                            :disabled="!canEdit"
                          />
                        </th>
                        <th
                          v-for="(item, index) in huayCategorys"
                          :key="index"
                          class="align-top"
                        >
                          <ValidationProvider
                            :rules="`max_value:${item.fixBuyMaxPerNumber}|min_value:0|numeric`"
                            v-slot="v"
                          >
                            <div class="d-flex flex-column min-width-5">
                              <span>{{ item.huayCategoryName }}</span
                              ><input
                                type="number"
                                :readonly="!canEdit"
                                class="form-control form-control-sm form-small"
                                @keypress="$root.onlyNumber"
                                v-model.number="item.buyMaxPerNumber"
                                v-if="showItem(item.status)"
                              />
                              <span v-if="showItem(item.status)">
                                &#x3C;=
                                <span class="text-gold">
                                  {{ item.fixBuyMaxPerNumber }}
                                </span>
                              </span>
                              <span class="text-danger">{{ v.errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in platforms"
                        :key="index"
                        :class="{ 'table-danger': item.status == 'INACTIVE' }"
                      >
                        <td class="text-left">
                          <router-link
                            :to="{
                              name: 'EditPlatformCategoryByPlatformId',
                              params: { platformId: item.id },
                              query: { page: 'buyMaxPerNumber' },
                            }"
                          >
                            {{ item.code }}
                          </router-link>
                          <!-- <span>{{ item.code }}</span> -->
                          <span v-if="showPlatformName">({{ item.name }})</span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            v-model="item.checkBuyMaxPerNumber"
                            :disabled="!canEdit"
                          />
                        </td>
                        <td
                          v-for="(
                            itemBuyMaxPerNumber, indexBuyMaxPerNumber
                          ) in filterPlatformHuayCategorys(item.id)"
                          :key="indexBuyMaxPerNumber"
                        >
                          <span v-if="showItem(itemBuyMaxPerNumber.status)">{{
                            itemBuyMaxPerNumber.buyMaxPerNumber
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-small {
  width: 100px;
}
</style>

<script>
import PlatformProvider from "@/resources/platform_provider"

const platformService = new PlatformProvider()
export default {
  data() {
    return {
      showPlatformName: true,
      checkAllBuyMin: false,
      checkAllBuyMaxPerBill: false,
      checkAllBuyMaxPerNumber: false,
      huayCategorys: [],
      platformHuayCategorys: [],
      platforms: [],
      isLoading: true,
      isNotFound: false,
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_platform_category_edit") 
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    showItem(status) {
      return status == "OPEN"
    },
    filterPlatformHuayCategorys(platformId) {
      return this.platformHuayCategorys.filter(
        (el) => el.platformId == platformId
      )
    },
    clearBuyMin() {
      this.huayCategorys.forEach((item) => {
        item.buyMin = null
      })
    },
    clearBuyMaxPerBill() {
      this.huayCategorys.forEach((item) => {
        item.buyMaxPerBill = null
      })
    },
    clearBuyMaxPerNumber() {
      this.huayCategorys.forEach((item) => {
        item.buyMaxPerNumber = null
      })
    },
    toggleCheckAllBuyMin() {
      this.platforms.forEach((item) => (item.checkBuyMin = this.checkAllBuyMin))
    },
    toggleCheckAllBuyMaxPerBill() {
      this.platforms.forEach(
        (item) => (item.checkBuyMaxPerBill = this.checkAllBuyMaxPerBill)
      )
    },
    toggleCheckAllBuyMaxPerNumber() {
      this.platforms.forEach(
        (item) => (item.checkBuyMaxPerNumber = this.checkAllBuyMaxPerNumber)
      )
    },
    async saveCategorys() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      await this.$swal({
        icon: "warning",
        text: "การตั้งค่าจะส่งผลถึงทุกแพลตฟอร์ม ที่อยู่ชั้นถัดลงไป",
      })

      this.huayCategorys.forEach((item) => {
        if (item.buyMin) {
          item.checkBuyMin = true
        }
        if (item.buyMaxPerBill) {
          item.checkBuyMaxPerBill = true
        }
        if (item.buyMaxPerNumber) {
          item.checkBuyMaxPerNumber = true
        }
      })

      this.swal.processing()

      let data = {
        platformRefHuayCategorys: this.huayCategorys.filter(
          (item) =>
            item.checkBuyMin ||
            item.checkBuyMaxPerBill ||
            item.checkBuyMaxPerNumber
        ),
        platformHuayCategorys: this.platformHuayCategorys,
        platforms: this.platforms.filter(
          (item) =>
            item.checkBuyMin ||
            item.checkBuyMaxPerBill ||
            item.checkBuyMaxPerNumber
        ),
      }

      const res = await platformService.changeCategory(data)
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      let result = await this.$swal({
        icon: "success",
        text: "สำเร็จ",
      })

      if (result.isConfirmed) {
        this.checkAllBuyMin = false
        this.checkAllBuyMaxPerBill = false
        this.checkAllBuyMaxPerNumber = false
        this.initData()
      }
    },
    async initData() {
      const res = await platformService.getEditPlatformCategoryData(
        this.$route.params.platformId
      )
      if (res.error) {
        if (res.error.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }
        this.swal.swalError(res.error.message)
        return
      }

      let data = res.result

      data.platformRefHuayCategorys.sort(function (a, b) {
        return a.huayCategoryId > b.huayCategoryId ? 1 : -1
      })
      data.platformRefHuayCategorys.forEach((item) => {
        item.fixBuyMin = item.buyMin
        item.fixBuyMaxPerBill = item.buyMaxPerBill
        item.fixBuyMaxPerNumber = item.buyMaxPerNumber
        item.buyMin = null
        item.buyMaxPerBill = null
        item.buyMaxPerNumber = null
      })
      this.huayCategorys = data.platformRefHuayCategorys
      data.platformHuayCategorys.sort(function (a, b) {
        return a.huayCategoryId > b.huayCategoryId ? 1 : -1
      })
      this.platformHuayCategorys = data.platformHuayCategorys
      data.platforms.forEach((item) => {
        item.checkBuyMaxPerBill = false
        item.checkBuyMaxPerNumber = false
        item.checkBuyMin = false
      })
      this.platforms = data.platforms
      this.isLoading = false
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
