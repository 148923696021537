import swal from "@/helpers/swal";
import DashboardProvider from "@/resources/dashboard_provider";

const dashboardService = new DashboardProvider();

const state = {
  lottoNumberSums: [],
  betReceiveCategories: [],
  isLottoNumberSumLoading: false,
  limitBuyAmount: 0,
};

const getters = {};

const mutations = {
  setLottoNumberSums: (state, lottoNumberSums) => {
    state.lottoNumberSums = lottoNumberSums;
  },
  setBetLimitCategories: (state, betLimitCategories) => {
    state.betLimitCategories = betLimitCategories;
  },
  setIsLottoNumberSumLoading: (state, isLoading) => {
    state.isLottoNumberSumLoading = isLoading;
  },
  setLimitBuyAmount: (state, limitAmount) => {
    state.limitBuyAmount = limitAmount;
  }
};

const actions = {
  async getLottoNumberSumList({ commit }, roundId) {
    commit("setIsLottoNumberSumLoading", true);
    const data = await dashboardService.getLottoNumberSumByRoundId(roundId);
    commit("setIsLottoNumberSumLoading", false);
    if (data.error) {
      swal.swalError(data.error.message);
      commit("setLottoNumberSums", []);
      commit("setBetLimitCategories", []);
      commit("setLimitBuyAmount", 0)
    } else {
      commit("setLottoNumberSums", data.result.list);
      commit("setBetLimitCategories", data.result.betLimitCategories);
      commit("setLimitBuyAmount", data.result.limitBuyAmountPerNumber);
    }
  },
};

const modules = {
  // lottogroup,
  // lottotype,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
};
