import swal from "@/helpers/swal";
import SettingProvider from "@/resources/setting_provider";

const settingService = new SettingProvider();

const state = {
  payRates: [],
  isPayRateLoading: false,
  cancelBillConfig: {},
  isCancelBillConfigLoading: false,
  lottoNumberSums: [],
  isLottoNumberSumLoading: false
};

const getters = {};

const mutations = {
  setIsPayRateLoading: (state, isLoading) => {
    state.isPayRateLoading = isLoading;
  },
  setPayRates: (state, payRates) => {
    state.payRates = payRates;
  },
  setIsCancelBillConfigLoading: (state, isLoading) => {
    state.isCancelBillConfigLoading = isLoading;
  },
  setCancelBillConfig: (state, cancelBillConfig) => {
    state.cancelBillConfig = cancelBillConfig;
  },
  setLottoNumberSums: (state, lottoNumberSums) => {
    state.lottoNumberSums = lottoNumberSums;
  },
  setIsLottoNumberSumLoading: (state, isLoading) => {
    state.isLottoNumberSumLoading = isLoading;
  }
};

const actions = {
  async getPayRateList({ commit }) {
    commit("setIsPayRateLoading", true);
    const data = await settingService.getPayRateList();
    commit("setIsPayRateLoading", false);
    if (data.error) {
      swal.swalError(data.error.message);
      commit("setPayRates", []);
    } else {
      commit("setPayRates", data.result.list);
    }
  },
  async getLottoNumberSumList({ commit }, roundId) {
    commit("setIsLottoNumberSumLoading", true);
    const data = await settingService.getLottoNumberSumByRoundId(roundId);
    commit("setIsLottoNumberSumLoading", false);
    if (data.error) {
      swal.swalError(data.error.message);
      commit("setLottoNumberSums", []);
    } else {
      commit("setLottoNumberSums", data.result.list);
    }
  }
};

const modules = {
  // lottogroup,
  // lottotype,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
};
