<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มเกม', 'แบ่งหุ้น/เก็บของ เกม']" />
    <platform-nav name="แบ่งหุ้น/เก็บของ"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab :title="gameProduct.name" v-for="(gameProduct, index) in gameProducts" :key="index"
              :active="$route.query.gameProductCode == gameProduct.code" @click="loadData(gameProduct)">
              <b-card-text>
                <div class="table-responsive">
                  <table class="
                      table table-sm table-bordered table-hover table-dark
                      text-center
                    ">
                    <thead>
                      <tr class="bg-info">
                        <th>
                          <span>รหัสแพลตฟอร์ม</span>
                          <div class="d-flex justify-content-center">
                            <input id="showPlatformName" type="checkbox" class="cursor-pointer mr-1 my-auto"
                              v-model="showPlatformName" />
                            <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
                          </div>
                        </th>
                        <th>แบ่งหุ้น</th>
                        <!-- <th>เก็บของ</th> -->
                        <!-- <th>เก็บของเกิน</th> -->
                        <td>บันทึก</td>
                        <td class="bg-dark">
                          <button class="btn btn-sm btn-primary" :disabled="!canEdit" @click="saveSelected(gameProduct)">
                            บันทึกที่เลือก
                          </button>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-secondary" v-for="(
                          item, index2
                        ) in filterPlatformRefGameProductSharesByGameProductCode(
                          gameProduct.code
                        )" :key="'head' + index2">
                        <td>เปลี่ยนทั้งหมด</td>
                        <td>
                          <select class="form-control form-control-sm" v-model="item.sharePercent" :disabled="!canEdit">
                            <option v-for="(item2, index3) in makeSharePercentAll(
                              item
                            )" :key="index3" v-bind:value="item2">
                              {{ item2 }}%
                            </option>
                          </select>
                        </td>
                        <!-- <td>
                          <select class="form-control form-control-sm" v-model="item.keepSharePercent"
                            :disabled="!canEdit">
                            <option v-for="(item2, index3) in makeKeepShareItem(item)" :key="index3" v-bind:value="item2">
                              {{ item2 }}%
                            </option>
                          </select>
                        </td> -->
                        <!-- <td>
                          <div class="form-check">
                            <input
                              :id="`inputKeepShareAll${item.id}`"
                              type="checkbox"
                              class="form-check-input"
                              v-model="item.keepBetExceed"
                            />
                            <label
                              class="form-check-label"
                              :for="`inputKeepShareAll${item.id}`"
                              >เก็บของที่เกิน</label
                            >
                          </div>
                        </td> -->
                        <td>
                          <button class="btn btn-sm btn-primary" :disabled="!canEdit" @click="saveAll(gameProduct)">
                            บันทึก
                          </button>
                        </td>
                        <td>
                          <input type="checkbox" v-model="gameProduct.checked" @change="setCheck(gameProduct)"
                            :disabled="!canEdit" />
                        </td>
                      </tr>
                      <tr v-for="(
                          item, index2
                        ) in gameProduct.platformGameProductShares" :key="index2" :class="{
                          'table-danger': item.status == 'INACTIVE',
                        }">
                        <td class="text-left">
                          <router-link :to="{
                            name: 'EditPlatformGameProductShareByPlatformId',
                            params: { platformId: item.platformId },
                            query: {
                              gameProductCode: gameProduct.code,
                            },
                          }">
                            {{ item.code }}
                          </router-link>
                          <span v-if="showPlatformName">
                            ({{ item.name }})</span>
                        </td>
                        <td>
                          <select class="form-control form-control-sm" v-model="item.sharePercent" :disabled="!canEdit"
                            @change="setKeepSharePercent(item)">
                            <option v-for="(item2, index3) in makeSharePercentItem(
                              item
                            )" :key="index3" v-bind:value="item2">
                              {{ item2 }}%
                            </option>
                          </select>
                        </td>
                        <!-- <td>
                          <select class="form-control form-control-sm" v-model="item.keepSharePercent"
                            :disabled="!canEdit">
                            <option v-for="(item2, index3) in makeKeepShareItem(item)" :key="index3" v-bind:value="item2">
                              {{ item2 }}%
                            </option>
                          </select>
                        </td> -->
                        <!-- <td>
                          <div class="form-check">
                            <input
                              :id="`inputKeepShare${item.id}`"
                              type="checkbox"
                              class="form-check-input"
                              v-model="item.keepBetExceed"
                            />
                            <label
                              class="form-check-label"
                              :for="`inputKeepShare${item.id}`"
                              >เก็บของที่เกิน</label
                            >
                          </div>
                        </td> -->
                        <td>
                          <button class="btn btn-sm btn-primary" @click="save(item)" :disabled="!canEdit">
                            บันทึก
                          </button>
                        </td>
                        <td>
                          <input type="checkbox" v-model="item.checked" :disabled="!canEdit" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  th {
    padding: 10px;
  }

  td {
    padding: 5px;
  }
}
</style>

<script>
import PlatformProvider from "@/resources/platform_provider"

const platformService = new PlatformProvider()
export default {
  data() {
    return {
      showPlatformName: true,
      gameProducts: [],
      platformRefGameProductShares: [],
      platformRefGameProductSharesForm: [],
      isLoading: true,
      isNotFound: false,
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_platform_game_product_share_edit")
    },
  },
  methods: {
    setCheck(gameProduct) {
      gameProduct.platformGameProductShares.forEach((item) => {
        item.checked = gameProduct.checked
      })
    },
    setKeepSharePercent(item) {
      let sharePercent = this.findPlatformRefGameProductSharesByGameProductCode(
        item.gameProductCode
      ).sharePercent

      item.keepSharePercent = sharePercent - item.sharePercent
    },
    makeSharePercentAll(item) {
      let sharePercents = []
      for (
        let i = parseFloat(
          this.findPlatformRefGameProductSharesByGameProductCode(
            item.gameProductCode
          ).oldSharePercent
        );
        i >= 0;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }

      return sharePercents
    },
    makeSharePercentItem(item) {
      let sharePercents = []
      for (
        let i = parseFloat(
          this.findPlatformRefGameProductSharesByGameProductCode(
            item.gameProductCode
          ).sharePercent
        );
        i >= 0;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeKeepShareItem(item) {
      let keepShares = []
      let sharePercent = this.findPlatformRefGameProductSharesByGameProductCode(
        item.gameProductCode
      ).sharePercent
      let keepShare = sharePercent - item.sharePercent
      //   let keepShare =
      //     item.level == "MEMBER"
      //       ? sharePercent
      //       : sharePercent - item.sharePercent;

      for (let i = keepShare; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }

      return keepShares
    },
    filterPlatformRefGameProductSharesByGameProductCode(gameProductCode) {
      return this.platformRefGameProductShares.filter(
        (item) => item.gameProductCode == gameProductCode
      )
    },
    findPlatformRefGameProductSharesByGameProductCode(gameProductCode) {
      return this.platformRefGameProductSharesForm.find(
        (item) => item.gameProductCode == gameProductCode
      )
    },
    setPage(val) {
      this.$route.query.page = val
    },
    async save(item) {
      this.swal.processing()
      const res = await platformService.setPlatformGameProductShare(
        this.$route.params.platformId,
        [item]
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      this.swal.swalSuccess()
      this.$store.dispatch("auth/forceReload")
    },
    async saveSelected(gameProduct) {
      this.swal.processing()

      let data = gameProduct.platformGameProductShares.filter(
        (item) => item.checked
      )
      const res = await platformService.setPlatformGameProductShare(
        this.$route.params.platformId,
        data
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      this.swal.swalSuccess()
      this.$store.dispatch("auth/forceReload")
    },
    async saveAll(gameProduct) {
      this.swal.processing()

      const platformRefGameProductShare =
        this.platformRefGameProductShares.filter(
          (item) => item.gameProductCode == gameProduct.code
        )[0]

      gameProduct.platformGameProductShares.forEach((item) => {
        item.sharePercent = platformRefGameProductShare.sharePercent
        item.keepSharePercent = platformRefGameProductShare.keepSharePercent
        // item.keepBetExceed = platformRefGameProductShare.keepBetExceed
      })

      const res = await platformService.setPlatformGameProductShare(
        this.$route.params.platformId,
        gameProduct.platformGameProductShares
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      this.swal.swalSuccess()
      this.$store.dispatch("auth/forceReload")
    },
    async loadData(gameProduct) {
      this.isLoading = true

      this.$route.query.gameProductCode = gameProduct
        ? gameProduct.code
        : this.$route.query.gameProductCode || ""

      const res = await platformService.getPlatformGameProductShare(
        this.$route.query.gameProductCode,
        this.$route.params.platformId
      )

      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      let data = res.result

      if (data.platformGameProductShares.length === 0) {
        this.isNotFound = true
        this.isLoading = false
        return
      }

      this.gameProducts = data.gameProducts.map((item) => {
        item.checked = false

        item.platformGameProductShares = data.platformGameProductShares
          ?.filter((item2) => item2.gameProductCode == item.code)
          .sort((a, b) => (a.platformId < b.platformId ? 1 : -1))

        return item
      })

      data.platformRefGameProductShares.forEach((item) => {
        item.oldSharePercent = item.sharePercent
        item.keepSharePercent = "0"
        item.checked = false
      })

      this.platformRefGameProductShares = data.platformRefGameProductShares

      this.platformRefGameProductSharesForm = JSON.parse(
        JSON.stringify(data.platformRefGameProductShares)
      )

      this.isLoading = false
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
