<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มเกม', 'ตั้งค่า AF']" />
    <platform-nav name="ตั้งค่า AF"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <ValidationObserver ref="observer" v-show="!isNotFound && !isLoading">
      <div class="alert alert-info" role="alert">
        สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
      </div>
      <div class="d-flex mb-1">
        <input
          id="showPlatformName"
          type="checkbox"
          class="cursor-pointer mr-1 my-auto"
          v-model="showPlatformName"
        />
        <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
      </div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="affiliate" class="p-0">
            <b-card-text>
              <b-tabs card>
                <b-tab
                  :title="gameProduct.name"
                  v-for="(gameProduct, gameProductIndex) in gameProducts"
                  :key="gameProductIndex"
                >
                  <b-card-text
                    ><div class="table-responsive">
                      <table
                        class="
                          table table-bordered table-hover table-dark
                          text-center
                          fs-075
                        "
                      >
                        <thead>
                          <tr>
                            <th>
                              <button
                                class="btn btn-primary mr-1"
                                @click="saveData(gameProduct.id)"
                                :disabled="!canEdit"
                              >
                                บันทึก
                              </button>
                              <button
                                class="btn btn-danger"
                                @click="clearCheckAF(gameProduct.id)"
                                :disabled="!canEdit"
                              >
                                ยกเลิก
                              </button>
                            </th>
                            <th>
                              <input
                                type="checkbox"
                                v-model="checkAllAF"
                                @change="toggleCheckAllAF"
                                :disabled="!canEdit"
                              />
                            </th>
                            <th class="align-top">
                              <ValidationProvider
                                :rules="`min_value:0`"
                                v-slot="v"
                              >
                                <div class="d-flex flex-column min-width-5">
                                  <input
                                    type="number"
                                    :readonly="!canEdit"
                                    class="
                                      form-control form-control-sm form-small
                                    "
                                    v-model="affiliatePercent"
                                  />
                                  <span class="text-danger">{{
                                    v.errors[0]
                                  }}</span>
                                </div>
                              </ValidationProvider>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(platform, platformIndex) in platforms"
                            :key="platformIndex"
                            :class="{
                              'table-danger': platform.status == 'INACTIVE',
                            }"
                          >
                            <td class="text-left">
                              <router-link
                                :to="{
                                  name: 'PlatformGameAFByPlatformId',
                                  params: {
                                    platformId: platform.id,
                                  },
                                  query: {
                                    page: 'af',
                                    gameProductId: gameProduct.id,
                                  },
                                }"
                              >
                                {{ platform.code }}
                              </router-link>
                              <span v-if="showPlatformName"
                                >({{ platform.name }})</span
                              >
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="platform.checkAF"
                                :disabled="!canEdit"
                              />
                            </td>
                            <td>
                              <span>{{
                                findPlatformGameProduct(
                                  gameProduct.id,
                                  platform.id
                                ).affiliatePercent
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </ValidationObserver>
  </div>
</template>
<style lang="scss" scoped>
.form-small {
  width: 100px;
}
</style>

<script>
export default {
  data() {
    return {
      isNotFound: false,
      showPlatformName: true,
      platformGameProducts: [],
      gameProducts: [],
      platforms: [],
      checkAllAF: false,
      affiliatePercent: null,
      isLoading: true,
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("platform_game_af_edit") 
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    toggleCheckAllAF() {
      this.platforms.forEach((item) => {
        item.checkAF = this.checkAllAF
      })
    },
    findPlatformGameProduct(gameProductId, platformId) {
      return this.platformGameProducts.find(
        (item) =>
          item.gameProductId === gameProductId && item.platformId === platformId
      )
    },
    clearCheckAF() {},
    async saveData(gameProductId) {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      let data = this.platformGameProducts.filter((item) => {
        const platform = this.platforms.find(
          (platform) => platform.id === item.platformId
        )
        return item.gameProductId === gameProductId && platform.checkAF
      })

      data.forEach((item) => {
        item.affiliatePercent = this.affiliatePercent
      })

      try {
        await this.axios({
          method: "post",
          url: "platform/game/af",
          data: data,
        })

        await this.swal.swalSuccess()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async getGameAFData() {
      this.isLoading = true
      this.swal.processing()

      try {
        let res = await this.axios({
          method: "get",
          url: "platform/game/af",
          params: {
            platformId: this.$route.params.platformId,
          },
        })

        this.swal.close()
        this.isLoading = false

        if (res.data.code == -4) {
          this.isNotFound = true
          return
        }

        const data = res.data.data

        data.platforms.forEach((item) => {
          item.checkAF = false
        })

        this.platforms = data.platforms
        this.platformGameProducts = data.platformGameProducts
        this.gameProducts = data.gameProducts
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.getGameAFData()
  },
}
</script>
