<template>
  <div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <bread-cum v-bind:breadcrumbItems="[
        {
          name: 'ตั้งค่าแพลตฟอร์มหวย',
          action: () => {
            $router.push('/platform')
          },
        },
        'เพิ่ม',
      ]" />
      <ValidationObserver ref="observer" tag="form" @submit.prevent="addPlatform">
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            ข้อมูล
          </h5>
          <div class="card-body">
            <div class="form-group row">
              <label for="code" class="col-lg-1 col-form-label text-lg-right">
                code:
              </label>
              <div class="col-lg-3">
                <ValidationProvider rules="required|alpha_num" v-slot="v">
                  <input id="code" type="text" class="form-control" placeholder="เช่น superhuay168"
                    v-model="platform.code" maxlength="50" :readonly="!canEdit" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="name" class="col-lg-1 col-form-label text-lg-right">
                ชื่อ:
              </label>
              <div class="col-lg-3">
                <ValidationProvider rules="required" v-slot="v">
                  <input id="name" type="text" class="form-control" placeholder="ชื่อ" v-model="platform.name"
                    maxlength="100" :readonly="!canEdit" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="name" class="col-lg-1 col-form-label text-lg-right">
                ระดับ:
              </label>
              <div class="col-lg-3">
                <ValidationProvider rules="required" v-slot="v">
                  <select v-model="platform.level" class="form form-control w-auto" :disabled="!canEdit">
                    <option value="MASTER">MASTER</option>
                    <option value="AGENT">AGENT</option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            แบ่งหุ้น / เก็บของ หวย
          </h5>
          <div class="card-body">
            <div class="form-inline">
              <span class="mr-3">ได้รับสูงสุด: {{ maxTypeShare.sharePercent }}%</span>
              <div class="form-group mr-3">
                <label class="col-form-control form-control-sm text-md-right mr-1">
                  แบ่งหุ้น:
                </label>
                <select class="form-control form-control-sm" v-model="giveShare" @change="setKeepShares"
                  :disabled="!canEdit">
                  <option v-for="(item, index) in giveShares" :key="index" v-bind:value="item">
                    {{ item }}%
                  </option>
                </select>
              </div>
              <div class="form-group mr-3">
                <label class="col-form-control form-control-sm text-md-right mr-1">
                  เก็บของ:
                </label>
                <select class="form-control form-control-sm" v-model="keepShare" @change="setKeepSharePercentAll"
                  :disabled="!canEdit">
                  <option v-for="(item, index) in keepShares" :key="index" v-bind:value="item">
                    {{ item }}%
                  </option>
                </select>
              </div>
              <!-- <div class="form-group mr-3" v-if="user.level == 'COMPANY'">
                <label
                  class="col-form-control form-control-sm text-md-right mr-1"
                >
                  แบ่งหุ้นเก็บของเกิน:
                </label>
                <select
                  class="form-control form-control-sm"
                  v-model="platform.betExceedPercent"
                >
                  <option
                    v-for="(item, index) in betExceedPercentList"
                    :key="index"
                    v-bind:value="item"
                  >
                    {{ item }}%
                  </option>
                </select>
              </div> -->
              <div class="form-check mr-2">
                <input id="inputKeepShare" type="checkbox" class="form-check-input" v-model="keepBetExceed"
                  @change="setKeepBetExceedAll" :disabled="!canEdit" />
                <label class="form-check-label" for="inputKeepShare">
                  เก็บของที่เกิน
                </label>
              </div>
              <button class="btn btn-info" @click="showDetailSetSharePercent = !showDetailSetSharePercent" type="button"
                v-show="canEdit">
                เพิ่มเติม
              </button>
            </div>
            <div class="table-responsive mt-2" v-if="showDetailSetSharePercent">
              <table class="table table-sm table-bordered table-dark text-center">
                <thead>
                  <tr>
                    <th rowspan="2" class="align-middle">ชนิดหวย</th>
                    <th rowspan="2" class="align-middle">แบ่งหุ้น</th>
                    <th rowspan="2" class="align-middle">เก็บของ</th>
                    <th rowspan="2" class="align-middle">เก็บของเกิน</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, index) in huayGroups">
                    <tr :key="'name' + index">
                      <td colspan="9" class="bg-primary">{{ item.name }}</td>
                    </tr>
                    <tr :key="'type' + huayType.id" v-for="huayType in filterHuayTypes(item.id)">
                      <td>{{ huayType.name }}</td>
                      <td>
                        <select class="form-control form-control-sm" v-model="huayType.typeShare.sharePercent" @change="
                          huayType.typeShare.keepSharePercent =
                          huayType.typeShare.platformSharePercent -
                          huayType.typeShare.sharePercent
                          ">
                          <option v-for="(item2, index2) in huayType.typeShare
                            .sharePercents" :key="index2" v-bind:value="item2">
                            {{ item2 }}%
                          </option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control form-control-sm" v-model="huayType.typeShare.keepSharePercent">
                          <option v-for="(item2, index2) in makeKeepShareItem(
                            huayType.typeShare
                          )" :key="index2" v-bind:value="item2">
                            {{ item2 }}%
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type="checkbox" v-model="huayType.typeShare.keepBetExceed" />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            แบ่งหุ้น / เก็บของ เกม
          </h5>
          <div class="card-body">
            <div class="form-inline">
              <span class="mr-3">ได้รับสูงสุด: {{ maxGameProductShare.sharePercent }}%</span>
              <div class="form-group mr-3">
                <label class="col-form-control form-control-sm text-md-right mr-1">
                  แบ่งหุ้น:
                </label>
                <select class="form-control form-control-sm" v-model="gameGiveShare" @change="setGameKeepShares"
                  :disabled="!canEdit">
                  <option v-for="(item, index) in gameGiveShares" :key="index" v-bind:value="item">
                    {{ item }}%
                  </option>
                </select>
              </div>
              <!-- <div class="form-group mr-3">
                <label
                  class="col-form-control form-control-sm text-md-right mr-1"
                >
                  เก็บของ:
                </label>
                <select
                  class="form-control form-control-sm"
                  v-model="gameKeepShare"
                  @change="setGameKeepSharePercentAll"
                  :disabled="!canEdit"
                >
                  <option
                    v-for="(item, index) in gameKeepShares"
                    :key="index"
                    v-bind:value="item"
                  >
                    {{ item }}%
                  </option>
                </select>
              </div> -->
              <button class="btn btn-info" v-show="canEdit" @click="
                showDetailSetGameSharePercent = !showDetailSetGameSharePercent
                " type="button">
                เพิ่มเติม
              </button>
            </div>
            <div class="table-responsive mt-2" v-if="showDetailSetGameSharePercent">
              <table class="table table-sm table-bordered table-dark text-center">
                <thead>
                  <tr>
                    <th rowspan="2" class="align-middle">ค่ายเกม</th>
                    <th rowspan="2" class="align-middle">แบ่งหุ้น</th>
                    <!-- <th rowspan="2" class="align-middle">เก็บของ</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr :key="'game-product-' + gameProduct.id" v-for="gameProduct in gameProducts">
                    <td>{{ gameProduct.name }}</td>
                    <td>
                      <select class="form-control form-control-sm" v-model="gameProduct.gameProductShare.sharePercent"
                        @change="
                          gameProduct.gameProductShare.keepSharePercent =
                          gameProduct.gameProductShare.platformSharePercent -
                          gameProduct.gameProductShare.sharePercent
                          ">
                        <option v-for="(item2, index2) in gameProduct.gameProductShare
                          .sharePercents" :key="index2" v-bind:value="item2">
                          {{ item2 }}%
                        </option>
                      </select>
                    </td>
                    <!-- <td>
                      <select class="form-control form-control-sm"
                        v-model="gameProduct.gameProductShare.keepSharePercent">
                        <option v-for="(item2, index2) in makeGameKeepShareItem(
                          gameProduct.gameProductShare
                        )" :key="index2" v-bind:value="item2">
                          {{ item2 }}%
                        </option>
                      </select>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            ข้อมูลการแทง
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-dark text-center fs-075">
                <thead>
                  <tr>
                    <th rowspan="2">ประเภท</th>
                    <th colspan="3">3 ตัวท้าย / 3 ตัวหัว</th>
                  </tr>
                  <tr>
                    <th>ขั้นต่ำ</th>
                    <th>สูงสุดต่อโพย</th>
                    <th>สูงสุดต่อเลข</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in categoryList" :key="index">
                    <td class="text-gold">{{ item.huayCategoryName }}</td>
                    <td>
                      <ValidationProvider :rules="`required|min_value:${item.fixBuyMin}`" v-slot="v"
                        v-if="showItem(item.status)">
                        <div class="form-inline">
                          <input type="number" class="form-control form-control-sm mr-1" v-model.number="item.buyMin"
                            @keypress="$root.onlyNumber" :readonly="!canEdit" />
                          <span>/{{ item.fixBuyMin }}</span>
                        </div>
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider :rules="`required|min_value:0|max_value:${item.fixBuyMaxPerBill}`" v-slot="v"
                        v-if="showItem(item.status)">
                        <div class="form-inline">
                          <input type="number" class="form-control form-control-sm mr-1"
                            v-model.number="item.buyMaxPerBill" @keypress="$root.onlyNumber"
                            :readonly="!canEdit" /><span>/{{ item.fixBuyMaxPerBill }}</span>
                        </div>
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider :rules="`required|min_value:0|max_value:${item.fixBuyMaxPerNumber}`" v-slot="v"
                        v-if="showItem(item.status)">
                        <div class="form-inline">
                          <input type="number" class="form-control form-control-sm mr-1"
                            v-model.number="item.buyMaxPerNumber" @keypress="$root.onlyNumber" :readonly="!canEdit" />
                          <span>/{{ item.fixBuyMaxPerNumber }}</span>
                        </div>
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            อัตราจ่าย เปิด/ปิด
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-dark text-center fs-075">
                <thead>
                  <tr>
                    <th>อัตราจ่าย</th>
                    <th>เปิด/ปิด</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in payList" :key="index">
                    <td class="text-gold">{{ item.huayPayName }}</td>
                    <td>
                      <input type="checkbox" v-model="item.open" v-if="showItem(item.status)" :disabled="!canEdit" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            อัตราจ่ายและคอมมิชชั่น
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-dark text-center fs-075">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th v-for="(item, index) in categoryList" :key="index">
                      {{ item.huayCategoryName }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in payList">
                    <tr :key="'payrate' + item.id">
                      <td rowspan="3" class="text-gold">
                        {{ item.huayPayName }}
                      </td>
                      <td>อัตราจ่าย</td>
                      <td v-for="(item2, index2) in filterCategoryPayList(
                        item.huayPayId
                      )" :key="index2">
                        <ValidationProvider :rules="`required|min_value:0|max_value:${item2.fixPayRate}`" v-slot="v"
                          v-if="showItem(item2.status)">
                          <div class="d-flex align-items-center min-width-5">
                            <input type="number" class="form-control form-control-sm form-pay mr-1"
                              v-model="item2.payrate" :readonly="!canEdit" />
                            <span>/{{ item2.fixPayRate }}</span>
                          </div>
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr :key="'discount' + item.id">
                      <td>ส่วนลด</td>
                      <td v-for="(item2, index2) in filterCategoryPayList(
                        item.huayPayId
                      )" :key="index2">
                        <ValidationProvider :rules="`required|min_value:0|max_value:${item2.fixDiscount}`" v-slot="v"
                          v-if="showItem(item2.status)">
                          <div class="d-flex align-items-center min-width-5">
                            <input type="number" class="form-control form-control-sm form-pay mr-1"
                              v-model="item2.discount" :readonly="!canEdit" />
                            <span>/{{ item2.fixDiscount }}</span>
                          </div>
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <tr :key="'sff' + item.id">
                      <td>Affiliate</td>
                      <td v-for="(item2, index2) in filterCategoryPayList(
                        item.huayPayId
                      )" :key="index2">
                        <ValidationProvider :rules="`required|min_value:0|max_value:${item2.fixAffiliate}`" v-slot="v"
                          v-if="showItem(item2.status)">
                          <div class="d-flex align-items-center min-width-5">
                            <input type="number" class="form-control form-control-sm form-pay mr-1"
                              v-model="item2.affiliate" :readonly="!canEdit" />
                            <span>/{{ item2.fixAffiliate }}</span>
                          </div>
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr>
                    <!-- <tr :key="'comm' + item.id">
                      <td>คอมมิชชั่น</td>
                      <td
                        v-for="(item2, index2) in filterCategoryPayList(
                          item.huayPayId
                        )"
                        :key="index2"
                      >
                        <ValidationProvider
                          :rules="`required|min_value:0|max_value:${item2.fixCommission}`"
                          v-slot="v"
                          v-if="showItem(item2.status)"
                        >
                          <div class="d-flex align-items-center min-width-5">
                            <input
                              type="number"
                              class="form-control form-control-sm form-pay mr-1"
                              v-model="item2.commission"
                            />
                            <span>/{{ item2.fixCommission }}</span>
                          </div>
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                    </tr> -->
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" v-show="canEdit">เพิ่มแพลตฟอร์ม</button>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 1rem;
}

.form-pay {
  width: 60px;
}
</style>

<script>
import PlatformProvider from "@/resources/platform_provider"
const platformService = new PlatformProvider()

export default {
  name: "AddPlatform",
  data() {
    return {
      showTheme: false,
      showDeposit: true,
      showBillSetting: true,
      showImageURLs: true,
      openCloseOptions: [
        {
          text: "เปิด",
          value: true,
        },
        {
          text: "ปิด",
          value: false,
        },
      ],
      colors: [
        {
          label: "Primary",
          key: "colorPrimary",
        },
        {
          label: "Secondary",
          key: "colorSecondary",
        },
        {
          label: "Success",
          key: "colorSuccess",
        },
        {
          label: "Info",
          key: "colorInfo",
        },
        {
          label: "Warning",
          key: "colorWarning",
        },
        {
          label: "Danger",
          key: "colorDanger",
        },
        {
          label: "Light",
          key: "colorLight",
        },
        {
          label: "Dark",
          key: "colorDark",
        },
        {
          label: "Blue",
          key: "colorBlue",
        },
        // {
        //   label: "Indigo",
        //   key: "colorIndigo",
        // },
        // {
        //   label: "Purple",
        //   key: "colorPurple",
        // },
        // {
        //   label: "Pink",
        //   key: "colorPink",
        // },
        {
          label: "Red",
          key: "colorRed",
        },
        // {
        //   label: "Orange",
        //   key: "colorOrange",
        // },
        // {
        //   label: "Yellow",
        //   key: "colorYellow",
        // },
        {
          label: "Green",
          key: "colorGreen",
        },
        // {
        //   label: "Teal",
        //   key: "colorTeal",
        // },
        // {
        //   label: "Cyan",
        //   key: "colorCyan",
        // },
        // {
        //   label: "White",
        //   key: "colorWhite",
        // },
        {
          label: "Gray",
          key: "colorGray",
        },
        // {
        //   label: "Gray Dark",
        //   key: "colorGrayDark",
        // },
        // {
        //   label: "Black",
        //   key: "colorBlack",
        // },
        {
          label: "รายการหวย",
          key: "colorHuayListItem",
        },
      ],
      platform: {
        code: "",
        name: "",
        description: "",
        loginDescription: "",
        howToDeposit: "",
        howToWithdraw: "",
        howToPlay: "",
        affiliateDescription: "",
        colorPrimary: "#000",
        colorSecondary: "#6c757d",
        colorSuccess: "#28a745",
        colorInfo: "#17a2b8",
        colorWarning: "#ffc107",
        colorDanger: "#dc3545",
        colorLight: "#f8f9fa",
        colorDark: "#343a40",
        colorBlue: "#3579f6",
        colorIndigo: "#6610f2",
        colorPurple: "#6f42c1",
        colorPink: "#e83e8c",
        colorRed: "#dc3545",
        colorOrange: "#fd7e14",
        colorYellow: "#ffc107",
        colorGreen: "#55bb52",
        colorTeal: "#20c997",
        colorCyan: "#17a2b8",
        colorWhite: "#fff",
        colorGray: "#d6d7db",
        colorGrayDark: "#343a40",
        colorBlack: "#000",
        colorHuayListItem: "#55bb52",
        isDepositOpen: false,
        isWithdrawOpen: false,
        depositBankCode: "KBANK",
        withdrawBankCode: "KTB",
        withdrawAmountMin: 0,
        withdrawAmountMax: 0,
        depositAmountMin: 0,
        cancelbillMaxNumberPerRound: 0,
        cancelbillMinuteBeforeClose: 0,
        cancelbillMinuteAfterBet: 0,
        bodyBackground: "",
        creditBackground: "",
        menuBackground: "",
        logo: "",
        logoHamburger: "",
        loginBtnBackground: "",
        registerBtnBackground: "",
        loginFormBackground: "",
        topbarBorderBackground: "",
        betBtnBackground: "",
        huayPayBackground: "",
        betWinImage: {
          img: "",
          imgSm: "",
        },
        adsLoginItems: [],
        adsLoginBottom: {
          img: "",
          imgSm: "",
        },
        betExceedPercent: 100,
        level: "AGENT",
      },
      // code: "",
      // name: "",
      // phoneNo: "",
      // level: "",
      // name: "",
      categoryList: [],
      payList: [],
      categoryPayList: [],
      // user: {},
      giveShares: [],
      giveShare: 0,
      keepShares: [],
      keepShare: 0,
      keepBetExceed: false,
      isLoading: true,
      huayTypes: [],
      huayGroups: [],
      showDetailSetSharePercent: false,
      maxTypeShare: {},
      typeShares: [],
      gameProductShares: [],
      maxGameProductShare: {},
      gameGiveShares: [],
      gameGiveShare: 0,
      gameKeepShares: [],
      gameKeepShare: 0,
      showDetailSetGameSharePercent: false,
      gameProducts: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("platform_add_edit")
    },
    betExceedPercentList() {
      const betExceedPercentList = []
      for (let i = 0; i <= 100; i += 0.5) {
        betExceedPercentList.push(i)
      }
      return betExceedPercentList
    },
  },
  methods: {
    setKeepBetExceedAll() {
      this.typeShares.forEach(
        (item) => (item.keepBetExceed = this.keepBetExceed)
      )
    },
    setSharePercentAll() {
      this.typeShares.forEach((item) => {
        if (this.giveShare <= item.platformSharePercent) {
          item.sharePercent = this.giveShare
          item.keepSharePercent = item.platformSharePercent - item.sharePercent
        } else {
          item.sharePercent = item.platformSharePercent
          item.keepSharePercent = 0
        }
      })
    },
    setKeepSharePercentAll() {
      this.typeShares.forEach((item) => {
        if (this.giveShare == item.sharePercent) {
          item.keepSharePercent = this.keepShare
        }
      })
    },
    makeSharePercentItem(item) {
      let sharePercents = []
      for (let i = parseInt(item.sharePercent); i >= 0; i -= 0.5) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeKeepShareItem(item) {
      let keepShares = []
      for (let i = item.keepSharePercent; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }

      return keepShares
    },
    makeGameSharePercentItem(item) {
      let sharePercents = []
      for (let i = parseInt(item.sharePercent); i >= 0; i -= 0.5) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeGameKeepShareItem(item) {
      let keepShares = []
      for (let i = item.keepSharePercent; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }

      return keepShares
    },
    findTypeSharesByTypeId(typeId) {
      return this.typeSharesForm.find((item) => item.huayTypeId == typeId)
    },
    filterHuayTypes(groupId) {
      return this.huayTypes.filter((item) => item.huayGroupId == groupId)
    },
    showItem(status) {
      return status == "OPEN"
    },
    setKeepShares() {
      this.keepShare = this.maxTypeShare.sharePercent - this.giveShare
      this.keepShares = []
      for (let i = this.keepShare; i >= 0; i -= 0.5) {
        this.keepShares.push(i)
      }

      this.setSharePercentAll()
    },
    filterCategoryPayList(huayPayId) {
      return this.categoryPayList.filter((item) => item.huayPayId == huayPayId)
    },
    async addPlatform() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      this.payList.forEach((item) =>
        item.open ? (item.status = "OPEN") : (item.status = "CLOSE")
      )

      const res = await platformService.addPlatform({
        platform: this.platform,
        categories: this.categoryList,
        pays: this.payList,
        categoryPays: this.categoryPayList,
        typeShares: this.typeShares,
        gameProductShares: this.gameProductShares,
      })
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess("เพิ่มแพลตฟอร์มสำเร็จ", () => {
          this.$store.dispatch("auth/updateUserData")
          this.$router.push("/platform")
        })
      }
    },
    async loadData() {
      this.isLoading = true
      const res = await platformService.getAddPlatformData()
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.swal.close()
      let data = res.result

      data.typeShares.forEach((item) => {
        item.sharePercents = this.makeSharePercentItem(item)
        item.platformSharePercent = item.sharePercent
        item.keepSharePercent = item.platformSharePercent
        item.sharePercent = 0
        item.keepBetExceed = false
      })

      data.huayTypes.forEach((item) => {
        item.typeShare = data.typeShares.find(
          (item2) => item.id == item2.huayTypeId
        )
      })

      this.typeShares = data.typeShares
      this.huayTypes = data.huayTypes
      this.huayGroups = data.huayGroups
      this.maxTypeShare = data.maxTypeShare

      data.categories?.forEach((item) => {
        item.fixBuyMaxPerBill = item.buyMaxPerBill
        item.fixBuyMaxPerNumber = item.buyMaxPerNumber
        item.fixBuyMin = item.buyMin
      })

      this.categoryList = data.categories

      this.payList = data.pays
      this.payList?.forEach((item) => {
        data.categoryPays.forEach((item2) => {
          if (item.huayPayId == item2.huayPayId) {
            item2.status = item.status
          }
        })
      })

      data.categoryPays?.forEach((item) => {
        item.fixPayRate = item.payrate
        item.fixCommission = item.commission
        item.fixDiscount = item.discount
        item.fixAffiliate = item.affiliate
      })
      this.categoryPayList = data.categoryPays

      for (
        let i = parseFloat(this.maxTypeShare.sharePercent);
        i >= 0;
        i -= 0.5
      ) {
        this.giveShares.push(i)
      }

      this.keepShare = this.maxTypeShare.sharePercent
      for (let i = this.keepShare; i >= 0; i -= 0.5) {
        this.keepShares.push(i)
      }

      data.gameProductShares.forEach((item) => {
        item.sharePercents = this.makeGameSharePercentItem(item)
        item.platformSharePercent = item.sharePercent
        item.keepSharePercent = item.platformSharePercent
        item.sharePercent = 0
      })

      data.gameProducts.forEach((item) => {
        item.gameProductShare = data.gameProductShares.find(
          (item2) => item.code == item2.gameProductCode
        )
      })

      this.gameProductShares = data.gameProductShares
      this.gameProducts = data.gameProducts
      this.maxGameProductShare = data.maxGameProductShare

      for (
        let i = parseFloat(this.maxGameProductShare.sharePercent);
        i >= 0;
        i -= 0.5
      ) {
        this.gameGiveShares.push(i)
      }

      this.gameKeepShare = this.maxGameProductShare.sharePercent
      for (let i = this.keepShare; i >= 0; i -= 0.5) {
        this.gameKeepShares.push(i)
      }

      this.isLoading = false
    },
    setGameKeepShares() {
      this.gameKeepShare =
        this.maxGameProductShare.sharePercent - this.gameGiveShare

      this.gameKeepShares = []
      for (let i = this.gameKeepShare; i >= 0; i -= 0.5) {
        this.gameKeepShares.push(i)
      }

      this.setGameSharePercentAll()
    },
    setGameSharePercentAll() {
      this.gameProductShares.forEach((item) => {
        if (this.gameGiveShare <= item.platformSharePercent) {
          item.sharePercent = this.gameGiveShare
          item.keepSharePercent = item.platformSharePercent - item.sharePercent
        } else {
          item.sharePercent = item.platformSharePercent
          item.keepSharePercent = 0
        }
      })
    },
    setGameKeepSharePercentAll() {
      this.gameProductShares.forEach((item) => {
        if (this.gameGiveShare == item.sharePercent) {
          item.keepSharePercent = this.gameKeepShare
        }
      })
    },
  },
  created() {
    this.loadData()
  },
}
</script>
