<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', pageName]" />
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบสมาชิก
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading && !isNotFound">
      <div class="alert alert-info" role="alert">
        สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
      </div>
      <ValidationObserver ref="observer">
        <b-card no-body>
          <b-tabs card>
            <b-tab :title="pageName" :active="!$route.query.page || $route.query.page == 'info'" @click="setPage('info')">
              <b-card-text>
                <CDataTable :items="members" :fields="fieldsNormal" :column-filter="{ external: externalFilter }"
                  :items-per-page-select="{ label: 'จำนวนแถว' }" :table-filter-value.sync="tableFilterValue"
                  :column-filter-value.sync="columnFilterValue" :items-per-page="itemsPerPage" :loading="tableLoading"
                  :noItemsView="{
                    noResults: 'ไม่พบข้อมูล',
                    noItems: 'ไม่พบข้อมูล',
                  }" v-on:pagination-change="paginationChange" v-on:update:column-filter-value="columnFilter"
                  :sorter="false" pagination hover dark border size="sm" add-table-classes="text-center">
                  <template #username="{ item }">
                    <td>
                      {{ item.username }}
                    </td>
                  </template>
                  <template #nameEdit="{ item }">
                    <td class="td-input">
                      <input type="text" class="form-control form-control-sm" v-model="item.name" :readonly="!canEdit" />
                    </td>
                  </template>
                  <template #phoneNoEdit="{ item }">
                    <td class="td-input">
                      <input type="text" class="form-control form-control-sm" v-model="item.phoneNo"
                        :readonly="!canEdit" />
                    </td>
                  </template>
                  <template #statusEdit="{ item }">
                    <td class="td-input-status">
                      <select class="form-control form-control-sm" v-model="item.status">
                        <option value="ACTIVE">ปกติ</option>
                        <option value="INACTIVE">ระงับ</option>
                      </select>
                    </td>
                  </template>
                  <template #save="{ item }">
                    <td>
                      <button class="btn btn-primary" @click="saveData(item)" v-show="canEdit">
                        บันทึก
                      </button>
                    </td>
                  </template>
                  <template #reportBet="{ item }">
                    <td class="text-left">
                      <router-link class="btn btn-sm btn-danger" :to="{
                        name: 'MemberDetail',
                        params: { userId: item.id },
                      }">
                        <i class="fas fa-user"></i> ข้อมูลสมาชิก
                      </router-link>
                      <button class="btn btn-sm btn-info ml-1" v-if="item.level != 'MEMBER'"
                        @click="openModalForm(item)">สิทธิ์เข้าใช้งาน</button>
                    </td>
                  </template>
                </CDataTable>
                <CPagination v-if="!isEditMemberAgentPage" :active-page="activePage" :double-arrows="false"
                  :pages="totalPage" :dots="false" v-on:update:activePage="updateActivePage" />
              </b-card-text>
            </b-tab>
            <b-tab title="รหัสผ่าน" :active="$route.query.page == 'password'" @click="setPage('password')">
              <b-card-text>
                <CDataTable :items="members" :fields="fieldsPassword" :itemsPerPageSelect="{ label: 'จำนวนแถว' }"
                  :items-per-page="itemsPerPage" :loading="tableLoading" :noItemsView="{
                    noResults: 'ไม่พบข้อมูล',
                    noItems: 'ไม่พบข้อมูล',
                  }" column-filter pagination hover :sorter="false" dark border size="sm"
                  add-table-classes="text-center">
                  <template #username="{ item }">
                    <td>
                      {{ item.username }}
                    </td>
                  </template>
                  <template #passwordEdit="{ item }">
                    <td class="td-input">
                      <ValidationProvider rules="min:6" v-slot="v">
                        <input type="text" class="form-control form-control-sm" v-model="item.password" minlength="6"
                          :readonly="!canEdit" />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </template>
                  <template #save="{ item }">
                    <td>
                      <button class="btn btn-primary" @click="savePassword(item)" v-show="canEdit">
                        บันทึก
                      </button>
                    </td>
                  </template>
                </CDataTable>
                <CPagination v-if="!isEditMemberAgentPage" :active-page="activePage" :double-arrows="false"
                  :pages="totalPage" :dots="false" v-on:update:activePage="updateActivePage" />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
    <b-modal id="modal-form" size="xl" body-bg-variant="dark" body-text-variant="light" header-bg-variant="dark"
      header-text-variant="light" footer-bg-variant="dark" footer-text-variant="light" title="สิทธิ์เข้าใช้งาน">
      <ValidationObserver ref="observer" tag="form" @submit.prevent="savePermission()">
        <template v-if="user">
          <div class="form-group row">
            <div class="col-12">
              <b-form-group label="" v-slot="{ ariaDescribedby }" class="" v-for="(item, index) in permissionGroups"
                :key="'checkbox-group-permission-' + index">
                <b-form-checkbox-group :id="'checkbox-group-permission-' + index" v-model="user.permissions"
                  :options="item[1]" :aria-describedby="ariaDescribedby"
                  :name="'permission-' + index"></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </template>
      </ValidationObserver>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="savePermission()">บันทึก</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      members: [],
      giveShares: [],
      isLoading: true,
      isNotFound: false,
      fieldsNormal: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "platform",
          label: "แพลตฟอร์ม",
        },
        {
          key: "levelTH",
          label: "ระดับ",
        },
        {
          key: "nameEdit",
          label: "ชื่อ",
          filter: false,
        },
        {
          key: "phoneNoEdit",
          label: "โทร",
          filter: false,
        },
        {
          key: "statusEdit",
          label: "สถานะ",
          filter: false,
        },
        {
          key: "createdAt",
          label: "สมัครเมื่อ",
          filter: false,
        },
        {
          key: "save",
          label: "บันทึก",
          filter: false,
        },
        {
          key: "creditBalance",
          label: "จำนวนเงิน",
          filter: false,
        },
        {
          key: "reportBet",
          label: "",
          filter: false,
        },
      ],
      fieldsPassword: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "platform",
          label: "แพลตฟอร์ม",
        },
        {
          key: "levelTH",
          label: "ระดับ",
        },
        {
          key: "name",
          label: "ชื่อ",
        },
        {
          key: "passwordEdit",
          label: "รหัสผ่าน",
          filter: false,
        },
        {
          key: "save",
          label: "บันทึก",
          filter: false,
        },
      ],
      tableFilterValue: "",
      columnFilterValue: {},
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
      externalFilter: true,
      permissions: [],
    }
  },
  computed: {
    isEditMemberAgentPage() {
      return this.$route.name == "EditMemberAgent"
    },
    pageName() {
      return this.isEditMemberAgentPage
        ? "ข้อมูลทั่วไปเอเย่นต์"
        : "ข้อมูลทั่วไปเมมเบอร์"
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(this.isEditMemberAgentPage ? "edit_member_agent_edit" : "edit_member_member_edit")
    },
    permissionGroups() {
      return this.util.groupBy(this.permissions, "groupId")
    },
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    openModalForm(user) {
      this.user = user
      this.$bvModal.show("modal-form")
    },
    async saveData(item) {
      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/change-profile",
          data: {
            id: item.id,
            name: item.name,
            phoneNo: item.phoneNo,
            status: item.status,
          },
        })
        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }
    },
    async savePassword(item) {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/change-password",
          data: {
            id: item.id,
            password: item.password,
          },
        })
        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
        this.$store.dispatch("auth/forceReload")
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async savePermission() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      console.log(this.user.permissions)

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/change-permission",
          data: {
            id: this.user.id,
            permissions: this.user.permissions,
          },
        })
        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        })
        this.$store.dispatch("auth/forceReload")
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.description,
        })
      }
    },
    async loadDataAgent() {
      this.tableLoading = true

      try {
        let res = await this.axios({
          mehotd: "get",
          url: "users/edit/member/agent",
          params: {
            userId: this.$route.params.userId,
          },
        })

        let data = res.data.data

        const permissions = data.permissions.map((item) => {
          item.text = item.name
          item.value = item.code
          return item
        })
        this.permissions = permissions

        if (data.members.length == 0) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        data.members.forEach((item) => {
          item._classes = item.status == "INACTIVE" ? "table-danger" : ""
          item._cellClasses = {
            creditBalance: this.util.getClassAmount(item.creditBalance),
          }
          item.creditBalance = this.util.formatMoney(item.creditBalance)
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.platform = `${item.platformCode}`
        })

        this.members = data.members
        this.user = data.user

        this.tableLoading = false
        this.isLoading = false
      } catch (e) {
        console.log(e)

        this.swal.swalError()
      }
    },
    async loadDataMember() {
      this.tableLoading = true

      try {
        const res = await this.axios.get("users/edit/member/member", {
          params: {
            page: this.activePage,
            pageSize: this.itemsPerPage,
            id: this.columnFilterValue.id,
            username: this.columnFilterValue.username,
          },
        })

        let data = res.data.data.data

        const permissions = data.permissions.map((item) => {
          item.text = item.name
          item.value = item.code
          return item
        })
        this.permissions = permissions

        data.members.forEach((item) => {
          item._classes = item.status == "INACTIVE" ? "table-danger" : ""
          item._cellClasses = {
            creditBalance: item.creditBalance > 0 ? "positive" : "",
          }
          item.creditBalance = this.util.formatMoney(item.creditBalance)
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.platform = `${item.platformCode}`
        })

        this.members = data.members

        this.tableLoading = false
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
    paginationChange(val) {
      this.itemsPerPage = val

      if (!this.isEditMemberAgentPage) {
        this.loadDataMember()
        return
      }
    },
    updateActivePage(val) {
      if (this.isEditMemberAgentPage) {
        return
      }

      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.members &&
        this.members.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val

      if (!this.isEditMemberAgentPage) {
        this.loadDataMember()
        return
      }
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        if (!this.isEditMemberAgentPage) {
          this.loadDataMember()
          return
        }
      }, 500)
    },
  },
  mounted() {
    this.externalFilter = this.isEditMemberAgentPage ? false : true

    if (!this.isEditMemberAgentPage) {
      this.columnFilterValue.id = this.$route.query.id
        ? "=" + this.$route.query.id
        : null
    }

    this.isEditMemberAgentPage ? this.loadDataAgent() : this.loadDataMember()
  },
}
</script>

<style scoped>
.td-input {
  min-width: 150px;
}

.td-input-status {
  min-width: 75px;
}
</style>