<template>
  <div class="d-flex flex-column justify-content-center">
    <template v-if="!isWebRockett888">
      <!-- <img
        src="~@/assets/logo.png"
        width="350"
        class="mx-auto"
        style="margin-top: 6rem"
      /> -->
      <div class="card mx-auto" style="width: 300px; background-color: rgb(0, 0, 0, 0.5) !important; margin-top: 6rem">
        <div class="card-body d-flex flex-column px-4 py-5">
          <h3 class="text-center mb-4 font-weight-light">AGENT</h3>
          <form @submit.prevent="handleLogin">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-user-alt mx-auto"></i>
                </div>
              </div>
              <input type="text" class="form-control" placeholder="Username" v-model="user.username" required />
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-unlock-alt mx-auto"></i>
                </div>
              </div>
              <input type="password" class="form-control" placeholder="Password" v-model="user.password" required />
            </div>
            <button type="submit" class="btn btn-login text-white w-100">
              เข้าสู่ระบบ
            </button>
          </form>
        </div>
      </div>
    </template>
    <template v-if="isWebRockett888">
      <!-- <img src="~@/assets/images/logo-rkt-login.png" width="150" class="mx-auto mt-4" /> -->
      <div class="card mx-auto" style="width: 300px; background-color: rgb(0, 0, 0, 0.5) !important; margin-top: 6rem">
        <div class="card-body d-flex flex-column px-4 py-5">
          <h3 class="text-center mb-4 font-weight-light">AGENT</h3>
          <form @submit.prevent="handleLogin">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-user-alt mx-auto"></i>
                </div>
              </div>
              <input type="text" class="form-control" placeholder="Username" v-model="user.username" required />
            </div>
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-unlock-alt mx-auto"></i>
                </div>
              </div>
              <input type="password" class="form-control" placeholder="Password" v-model="user.password" required />
            </div>
            <button type="submit" class="btn btn-primary w-100">
              เข้าสู่ระบบ
            </button>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<style>
body.login {
  background: url("~@/assets/images/bg-login.jpg") no-repeat bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

body.loginRockett888 {
  background: linear-gradient(-5deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(27, 35, 44, 1) 56%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.btn-login {
  background: linear-gradient(90deg,
      rgba(178, 47, 22, 1) 9%,
      rgba(241, 90, 34, 1) 34%,
      rgba(244, 147, 33, 1) 70%,
      rgba(241, 90, 34, 1) 91%);
}
</style>

<script>
export default {
  data() {
    return {
      user: {},
    }
  },
  computed: {
    isWebRockett888() {
      return process.env.VUE_APP_WEB_NAME === "rockett888"
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  beforeCreate() {
    document.body.className =
      process.env.VUE_APP_WEB_NAME === "rockett888"
        ? "loginRockett888"
        : "login"
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/welcome")
    }
  },
  methods: {
    async handleLogin() {
      this.swal.processing()
      this.$store.dispatch("auth/login", this.user).then(
        async (data) => {
          console.log(data)
          if (data.data.code == -2002) { // Password Expire
            let res = await this.$swal({
              icon: "warning",
              title: "Login ไม่สำเร็จ",
              text: data.data.description,
              showCancelButton: false,
              confirmButtonText: "ยืนยัน",
              // cancelButtonText: "ยกเลิก",
            })
            if (!res.isConfirmed) return
            let encoded = encodeURI(`${data.data.data.passwordToken}`);
            this.$router.push(`/password-expire?token=${encoded}`)
          } else if (data.data.data.closeToExpire) {  // Password Expire is comming soon
            let res = await this.$swal({
              icon: "warning",
              title: "รหัสใกล้หมดอายุ",
              text: "รหัสใกล้หมดอายุ กรุณาเปลี่ยนรหัสผ่านใหม่",
              showCancelButton: true,
              confirmButtonText: "ยืนยัน",
              // cancelButtonText: "ยกเลิก",
            })
            if (!res.isConfirmed) {
              this.$router.push("/welcome")
            } else {
              this.$router.push("/profile/setting")
            }

          } else {
            this.$router.push("/welcome")
          }
        },
        (error) => {
          console.log(error.response)
          this.$swal({
            icon: "error",
            title: "Login ไม่สำเร็จ",
            text: error.response ? error.response.data.description : "",
          })
        }
      )
    },
  },
}
</script>
