import HttpRequest from "./http_request";

class SettingProvider extends HttpRequest {
  async addLottoGroup(data) {
    return this.create("/setting/huay-group/add", data);
  }

  async editLottoGroup(id, data) {
    return this.update(`/setting/huay-group/${id}/edit`, data);
  }

  async addLottoType(data) {
    return this.create("/setting/huay-type/add", data);
  }

  async editLottoType(id, data) {
    return this.update(`/setting/huay-type/${id}/edit`, data);
  }

  async addLottoCategory(data) {
    return this.create("/setting/huay-category/add", data);
  }

  async editLottoCategory(id, data) {
    return this.update(`/setting/huay-category/${id}/edit`, data);
  }

  async getPayRateList() {
    return this.get("/setting/pay-rate/list");
  }

  async getPayRateDetail(id) {
    return this.get(`setting/pay-rate/${id}/detail`);
  }

  async addPayRate(data) {
    return this.create("/setting/pay-rate/add", data);
  }

  async editPayRate(id, data) {
    return this.update(`/setting/pay-rate/${id}/edit`, data);
  }

  async editLottoCategories(data) {
    return this.update("/setting/huay-categories/edit", data);
  }

  async editLottoGroupPays(data) {
    return this.update("/setting/huay-group-pays/edit", data);
  }

  async addGameProduct(data) {
    return this.create("/setting/game-product/add", data);
  }

  async editGameProduct(id, data) {
    return this.update(`/setting/game-product/${id}/edit`, data);
  }

  async addGame(data) {
    return this.create("/setting/game/add", data);
  }

  async editGame(id, data) {
    return this.update(`/setting/game/${id}/edit`, data);
  }

  async addGameBetLimit(data) {
    return this.create("/setting/game-bet-limit/add", data);
  }

  async editGameBetLimit(id, data) {
    return this.update(`/setting/game-bet-limit/${id}/edit`, data);
  }
}

export default SettingProvider;
