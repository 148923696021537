<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มหวย', 'แบ่งหุ้น/เก็บของ']"
    />
    <platform-nav name="แบ่งหุ้น/เก็บของ"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              class="p-0"
              v-for="(group, groupIndex) in huayGroups"
              :key="groupIndex"
              :title="group.name"
              :active="$route.query.groupId == group.id"
              @click="loadData(filterHuayTypeByGroup(group.id)[0])"
            >
              <b-card-text>
                <b-tabs card>
                  <b-tab
                    :title="huayType.name"
                    v-for="(huayType, index) in filterHuayTypeByGroup(group.id)"
                    :key="index"
                    :active="$route.query.typeId == huayType.id"
                    @click="loadData(huayType)"
                  >
                    <b-card-text>
                      <div class="d-flex mb-3">
                        <h5 class="my-auto mr-2">
                          ตั้งค่ากลุ่มหวยตาม{{ huayType.name }} :
                        </h5>
                        <button
                          class="btn btn-sm btn-warning mr-2"
                          v-for="(item, index2) in huayGroups"
                          :key="index2"
                          @click="setGroupLikeType(huayType.id, item.id)"
                        >
                          กลุ่ม{{ item.name }}
                        </button>
                      </div>
                      <div class="table-responsive">
                        <table
                          class="
                            table table-sm table-bordered table-hover table-dark
                            text-center
                          "
                        >
                          <thead>
                            <tr class="bg-info">
                              <th>
                                <span>รหัสแพลตฟอร์ม</span>
                                <div class="d-flex justify-content-center">
                                  <input
                                    id="showPlatformName"
                                    type="checkbox"
                                    class="cursor-pointer mr-1 my-auto"
                                    v-model="showPlatformName"
                                  />
                                  <label for="showPlatformName" class="my-auto"
                                    >แสดงชื่อ</label
                                  >
                                </div>
                              </th>
                              <th>แบ่งหุ้น</th>
                              <th>เก็บของ</th>
                              <th>เก็บของเกิน</th>
                              <td>บันทึก</td>
                              <td class="bg-dark">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click="saveSelected(huayType)"
                                  :disabled="!canEdit"
                                >
                                  บันทึกที่เลือก
                                </button>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="bg-secondary"
                              v-for="(
                                item, index2
                              ) in filterPlatformRefHuayTypeSharesByTypeId(
                                huayType.id
                              )"
                              :key="'head' + index2"
                            >
                              <td>เปลี่ยนทั้งหมด</td>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="item.sharePercent"
                                  :disabled="!canEdit"
                                >
                                  <option
                                    v-for="(
                                      item2, index3
                                    ) in makeSharePercentAll(item)"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="item.keepSharePercent"
                                  :disabled="!canEdit"
                                >
                                  <option
                                    v-for="(item2, index3) in makeKeepShareItem(
                                      item
                                    )"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <div class="form-check">
                                  <input
                                    :id="`inputKeepShareAll${item.id}`"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="item.keepBetExceed"
                                    :disabled="!canEdit"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="`inputKeepShareAll${item.id}`"
                                    >เก็บของที่เกิน</label
                                  >
                                </div>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click="saveAll(huayType)"
                                  :disabled="!canEdit"
                                >
                                  บันทึก
                                </button>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="huayType.checked"
                                  @change="setCheck(huayType)"
                                  :disabled="!canEdit"
                                />
                              </td>
                            </tr>
                            <tr
                              v-for="(
                                item, index2
                              ) in huayType.platformHuayTypeShares"
                              :key="index2"
                              :class="{
                                'table-danger': item.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformTypeShareByPlatformId',
                                    params: { platformId: item.platformId },
                                    query: {
                                      typeId: huayType.id,
                                      groupId: group.id,
                                    },
                                  }"
                                >
                                  {{ item.code }}
                                </router-link>
                                <span v-if="showPlatformName">
                                  ({{ item.name }})</span
                                >
                              </td>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="item.sharePercent"
                                  @change="setKeepSharePercent(item)"
                                  :disabled="!canEdit"
                                >
                                  <option
                                    v-for="(
                                      item2, index3
                                    ) in makeSharePercentItem(item)"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="item.keepSharePercent"
                                  :disabled="!canEdit"
                                >
                                  <option
                                    v-for="(item2, index3) in makeKeepShareItem(
                                      item
                                    )"
                                    :key="index3"
                                    v-bind:value="item2"
                                  >
                                    {{ item2 }}%
                                  </option>
                                </select>
                              </td>
                              <td>
                                <div class="form-check">
                                  <input
                                    :id="`inputKeepShare${item.id}`"
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="item.keepBetExceed"
                                    :disabled="!canEdit"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="`inputKeepShare${item.id}`"
                                    >เก็บของที่เกิน</label
                                  >
                                </div>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click="save(item)"
                                  :disabled="!canEdit"
                                >
                                  บันทึก
                                </button>
                              </td>
                              <td>
                                <input type="checkbox" v-model="item.checked" :disabled="!canEdit"/>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  th {
    padding: 10px;
  }

  td {
    padding: 5px;
  }
}
</style>

<script>
import PlatformProvider from "@/resources/platform_provider"

const platformService = new PlatformProvider()
export default {
  data() {
    return {
      showPlatformName: true,
      huayTypes: [],
      huayGroups: [],
      platformRefHuayTypeShares: [],
      platformRefHuayTypeSharesForm: [],
      isLoading: true,
      isNotFound: false,
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_platform_type_share_edit") 
    },
  },
  methods: {
    setCheck(huayType) {
      huayType.platformHuayTypeShares.forEach((item) => {
        item.checked = huayType.checked
      })
    },
    setGroup(id) {
      this.$route.query.groupId = id
    },
    filterHuayTypeByGroup(groupId) {
      return this.huayTypes.filter((item) => item.huayGroupId === groupId)
    },
    setKeepSharePercent(item) {
      let sharePercent = this.findPlatformRefHuayTypeSharesByTypeId(
        item.huayTypeId
      ).sharePercent

      item.keepSharePercent = sharePercent - item.sharePercent
    },
    makeSharePercentAll(item) {
      let sharePercents = []
      for (
        let i = parseFloat(
          this.findPlatformRefHuayTypeSharesByTypeId(item.huayTypeId)
            .oldSharePercent
        );
        i >= 0;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }

      return sharePercents
    },
    makeSharePercentItem(item) {
      let sharePercents = []
      for (
        let i = parseFloat(
          this.findPlatformRefHuayTypeSharesByTypeId(item.huayTypeId)
            .sharePercent
        );
        i >= 0;
        i -= 0.5
      ) {
        sharePercents.push(i)
      }
      return sharePercents
    },
    makeKeepShareItem(item) {
      let keepShares = []
      let sharePercent = this.findPlatformRefHuayTypeSharesByTypeId(
        item.huayTypeId
      ).sharePercent
      let keepShare = sharePercent - item.sharePercent
      //   let keepShare =
      //     item.level == "MEMBER"
      //       ? sharePercent
      //       : sharePercent - item.sharePercent;

      for (let i = keepShare; i >= 0; i -= 0.5) {
        keepShares.push(i)
      }

      return keepShares
    },
    filterPlatformRefHuayTypeSharesByTypeId(typeId) {
      return this.platformRefHuayTypeShares.filter(
        (item) => item.huayTypeId == typeId
      )
    },
    findPlatformRefHuayTypeSharesByTypeId(typeId) {
      return this.platformRefHuayTypeSharesForm.find(
        (item) => item.huayTypeId == typeId
      )
    },
    setPage(val) {
      this.$route.query.page = val
    },
    async setGroupLikeType(huayTypeId, huayGroupId) {
      this.swal.processing()

      const res = await platformService.setPlatformHuayTypeShareByGroup(
        this.$route.params.platformId,
        huayTypeId,
        huayGroupId
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.swal.swalSuccess()

      this.$store.dispatch("auth/forceReload")
    },
    async save(item) {
      this.swal.processing()
      const res = await platformService.setPlatformHuayTypeShare(
        this.$route.params.platformId,
        [item]
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      this.swal.swalSuccess()
      this.$store.dispatch("auth/forceReload")
    },
    async saveSelected(huayType) {
      this.swal.processing()

      let data = huayType.platformHuayTypeShares.filter((item) => item.checked)
      const res = await platformService.setPlatformHuayTypeShare(
        this.$route.params.platformId,
        data
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      this.swal.swalSuccess()
      this.$store.dispatch("auth/forceReload")
    },
    async saveAll(huayType) {
      this.swal.processing()

      const platformRefHuayTypeShare = this.platformRefHuayTypeShares.filter(
        (item) => item.huayTypeId == huayType.id
      )[0]

      huayType.platformHuayTypeShares.forEach((item) => {
        item.sharePercent = platformRefHuayTypeShare.sharePercent
        item.keepSharePercent = platformRefHuayTypeShare.keepSharePercent
        item.keepBetExceed = platformRefHuayTypeShare.keepBetExceed
      })

      const res = await platformService.setPlatformHuayTypeShare(
        this.$route.params.platformId,
        huayType.platformHuayTypeShares
      )
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      this.swal.swalSuccess()
      this.$store.dispatch("auth/forceReload")
    },
    async loadData(huayType) {
      this.isLoading = true

      this.$route.query.typeId = huayType
        ? huayType.id
        : this.$route.query.typeId

      this.$route.query.groupId = huayType
        ? huayType.huayGroupId
        : this.$route.query.groupId

      const res = await platformService.getPlatformHHuayTypeShare(
        this.$route.query.typeId,
        this.$route.params.platformId
      )

      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      let data = res.result

      if (data.platformHuayTypeShares == null) {
        this.isNotFound = true
        this.isLoading = false
        return
      }

      this.huayGroups = data.huayGroups
      this.huayTypes = data.huayTypes.map((item) => {
        item.checked = false

        item.platformHuayTypeShares = data.platformHuayTypeShares
          ?.filter((item2) => item2.huayTypeId == item.id)
          .sort((a, b) => (a.platformId < b.platformId ? 1 : -1))

        return item
      })

      data.platformRefHuayTypeShares.forEach((item) => {
        item.oldSharePercent = item.sharePercent
        item.keepSharePercent = "0"
        item.checked = false
      })

      this.platformRefHuayTypeShares = data.platformRefHuayTypeShares

      this.platformRefHuayTypeSharesForm = JSON.parse(
        JSON.stringify(data.platformRefHuayTypeShares)
      )

      this.isLoading = false
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
