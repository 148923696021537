<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['การเงิน', 'แจ้งฝากถอน']" />
    <user-transfer-request></user-transfer-request>
  </div>
</template>

<script>
import UserTransferRequest from "@/components/UserTransferRequest.vue"
export default {
  components: { UserTransferRequest },
}
</script>