import HttpRequest from "./http_request";

class AuthProvider extends HttpRequest {
  async changePassword(data) {
    return this.update("/auth/change-password", data);
  }
  async changePasswordExpire(token,data) {
    return this.update("/auth/change-password-expire", data,{ Authorization: `Bearer ${token}`});
  }
}

export default AuthProvider;
