<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าเกม', 'ค่ายเกม']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายการค่ายเกม
      </h5>
      <div class="card-body">
        <button class="btn btn-success mb-2" @click="openFormCreate" v-show="canEdit">
          เพิ่มค่ายเกม
        </button>
        <loading v-show="isLoading" />
        <h5 class="text-center" v-if="!isLoading && !items.length">
          ไม่มีข้อมูล
        </h5>
        <div class="table-responsive" v-show="!isLoading && items.length">
          <table
            class="
              table table-sm table-bordered table-striped table-hover table-dark
              text-center text-wrap
            "
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>รหัส</th>
                <th>ชื่อ</th>
                <th>ชนิด</th>
                <th>รูปภาพ</th>
                <th>affiliate</th>
                <th>ลำดับการแสดง</th>
                <th>สถานะ</th>
                <th>ปิดปรับปรุง</th>
                <th>เวลาสร้าง</th>
                <th>แก้ไขล่าสุด</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>
                  {{ getGameCategoryName(item.category) }}
                </td>
                <td
                  data-toggle="tooltip"
                  :title="item.imageURL"
                  style="max-width: 200px; white-space: normal"
                >
                  <img :src="item.imageURL" :alt="item.imageURL" width="60px" />
                  <!-- {{ item.imageURL }} -->
                </td>
                <td>{{ item.affiliatePercent }}%</td>
                <td>{{ item.showOrder }}</td>
                <td>{{ item.statusTH }}</td>
                <td>{{ item.maintenance ? "ใช่" : "ไม่" }}</td>
                <td>
                  {{ $date(item.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  {{ $date(item.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  <button class="btn btn-primary" @click="openFormEdit(item)" :disabled="!canEdit">
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-form"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      :title="(form.id == null ? 'เพิ่ม' : 'แก้ไข') + 'ค่ายเกม'"
      cancel-title="ยกเลิก"
      ok-title="ตกลง"
      hide-footer
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="submitForm"
        novalidate
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group row" v-if="form.id">
              <span class="col-12"> #ID: </span>
              <span class="col-12">{{ form.id }}</span>
            </div>
            <div class="form-group row">
              <label for="form-game-product-code" class="col-12"> รหัส: </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-product-code"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.code"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-game-product-name" class="col-12"> ชื่อ: </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-product-name"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.name"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-game-product-category" class="col-12">
                ชนิด:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="form.category"
                    class="form-control"
                    id="form-game-product-category"
                  >
                    <option
                      :value="category.code"
                      v-for="category in gameProductCategories"
                      :key="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-game-product-image-url" class="col-12">
                รูปภาพ:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-product-image-url"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.imageURL"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-affiliate">affiliate:</label>
              <div class="col-12">
                <ValidationProvider rules="" v-slot="v">
                  <select
                    v-model="form.affiliatePercent"
                    class="form-control"
                    id="form-affiliate"
                  >
                    <option
                      v-for="percent in affPercentList"
                      :key="percent"
                      :value="percent"
                    >
                      {{ percent }}%
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label for="form-show-order" class="col-12">
                ลำดับการแสดง:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required|numeric" v-slot="v">
                  <input
                    id="form-show-order"
                    type="text"
                    class="form-control mr-1"
                    v-model.number="form.showOrder"
                    @keypress="$root.onlyNumber"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-status">สถานะ:</label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="form.status"
                    class="form-control"
                    id="form-status"
                  >
                    <option value="OPEN">เปิด</option>
                    <option value="CLOSE">ปิด</option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12" for="form-maintenance">ปิดปรับปรุง:</label>
              <div class="col-12">
                <b-form-radio-group
                  id="form-maintenance"
                  v-model="form.maintenance"
                  :options="yesNoOptions"
                  :aria-describedby="''"
                  name="radio-deposit-options"
                ></b-form-radio-group>
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <div class="col-12 text-right">
                <button class="btn btn-danger" @click="closeForm" type="button">
                  ยกเลิก
                </button>
                <button class="btn btn-primary ml-3" type="submit">
                  {{ form.id ? "บันทึก" : "เพิ่ม" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style>
</style>

<script>
import GameProvider from "@/resources/game_provider"
import SettingProvider from "@/resources/setting_provider"
const gameService = new GameProvider()
const settingService = new SettingProvider()
export default {
  data() {
    return {
      form: {
        id: null,
        code: "",
        name: "",
        category: "",
        imageURL: "",
        showOrder: null,
        status: "OPEN",
        affiliatePercent: 0,
        maintenance: false,
      },
      items: [],
      gameProductCategories: [],
      isLoading: false,
      yesNoOptions: [
        {
          text: "ใช่",
          value: true,
        },
        {
          text: "ไม่",
          value: false,
        },
      ],
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("game_product_edit") 
    },
    affPercentList() {
      const affPercentList = []
      for (let i = 0; i <= 100; i += 0.5) {
        affPercentList.push(i)
      }
      return affPercentList
    },
    gameProductCategoryMapCode() {
      let map = {}
      this.gameProductCategories.forEach((ele) => {
        map[ele.code] = ele
      })
      return map
    },
  },
  mounted() {
    this.clearForm()
    this.getAllGameProduct()
  },
  methods: {
    clearForm() {
      this.form.id = null
      this.form.code = ""
      this.form.name = ""
      this.form.category = ""
      this.form.imageURL = ""
      this.form.showOrder = null
      this.form.status = "OPEN"
      this.form.affiliatePercent = 0
      this.form.maintenance = false
    },
    openFormCreate() {
      this.clearForm()
      this.$bvModal.show("modal-form")
    },
    openFormEdit(item) {
      this.clearForm()
      this.form.id = item.id
      this.form.code = item.code
      this.form.name = item.name
      this.form.category = item.category
      this.form.imageURL = item.imageURL
      this.form.showOrder = item.showOrder
      this.form.status = item.status
      this.form.affiliatePercent = item.affiliatePercent
      this.form.maintenance = item.maintenance
      this.$bvModal.show("modal-form")
    },
    closeForm() {
      this.$bvModal.hide("modal-form")
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = !this.form.id
        ? await settingService.addGameProduct(this.form)
        : await settingService.editGameProduct(this.form.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.form.id ? "เพิ่มค่ายเกมสำเร็จ" : "แก้ไขค่ายเกมสำเร็จ",
          () => {
            this.getAllGameProduct()
            this.closeForm()
          }
        )
      }
    },
    async getAllGameProduct() {
      this.isLoading = true
      const res = await gameService.getAllGameProduct()
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.items = res.result.list
      this.gameProductCategories = res.result.gameProductCategories
    },
    getGameCategoryName(categoryCode) {
      return this.gameProductCategoryMapCode[categoryCode]?.name || categoryCode
    },
  },
}
</script>