import HttpRequest from "./http_request"

class LottoProvider extends HttpRequest {
  async getLottoGroupList() {
    return this.get("huay/group/list")
  }

  async getAllLottoGroupList() {
    return this.get("huay/group/list/all")
  }

  async getLottoGroupDetail(id) {
    return this.get(`huay/group/${id}/detail`)
  }

  async getLottoTypeList() {
    return this.get("huay/type/list")
  }

  async getLottoTypeDetail(id) {
    return this.get(`huay/type/${id}/detail`)
  }

  async getLottoCategoryList() {
    return this.get("huay/category/list")
  }

  async getLottoCategoryDetail(id) {
    return this.get(`huay/category/${id}/detail`)
  }

  async getLottoGroupPayList() {
    return this.get("huay/group-pay/list")
  }

  async getLottoPayList() {
    return this.get("huay/pay/list")
  }
}

export default LottoProvider
