import axios from "axios"

const user = JSON.parse(localStorage.getItem("user"))

export const auth = {
  namespaced: true,
  state: {
    status: {
      loggedIn: user ? true : false
    },
    user: user,
    typeName: "",
    roundName: "",
    roundDate: "",
    roundId: 0,
    typeId: 1,
    forceReload: 0
  },
  actions: {
    async login({ commit }, user) {
      return axios
        .post("auth/login", {
          username: user.username,
          password: user.password
        })
        .then((response) => {
          const data = response.data.data

          if (data) {
            localStorage.setItem("user", JSON.stringify(data))
          }

          commit("loginSuccess", data)

          return Promise.resolve(response)
        })
        .catch((error) => {
          commit("loginFailure")
          return Promise.reject(error)
        })
    },
    async updateUserData({ commit }) {
      return axios
        .get("users/")
        .then((response) => {
          commit("updateUserData", response.data.data)
          return Promise.resolve()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateTypeName({ commit }, typeName) {
      commit("setTypeName", typeName)
    },
    logout({ commit }) {
      localStorage.removeItem("user")
      commit("logout")
    },
    forceReload({ commit }) {
      commit("forceReload")
    }
  },
  mutations: {
    setTypeName(state, typeName) {
      state.typeName = typeName
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    updateUserData(state, data) {
      state.user.userData = data
    },
    forceReload(state) {
      state.forceReload++
    }
  }
}
