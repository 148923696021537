<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['การเงิน', 'สรุปการเงิน']" />
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <div class="card border-primary mb-3" style="width: 700px">
        <h6 class="card-header border-primary bg-primary text-white p-2">
          ตัวเลือกการค้นหา
        </h6>
        <div class="card-body">
          <div class="d-flex mb-2">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="fromDate"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <span class="mx-2 my-auto">ถึง</span>
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="toDate"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <button class="btn btn-primary ml-2" @click="loadData">
              ค้นหา
            </button>
          </div>

          <div class="d-flex">
            <button
              v-for="(item, index) in searchDateButtons"
              :key="index"
              class="btn btn-sm btn-warning mr-2"
              @click="setDateRange(item.key)"
            >
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="card border-primary mb-3" style="width: 700px">
        <h6 class="card-header border-primary bg-primary text-white p-2">
          เงินเข้าออกบัญชีธนาคาร
        </h6>
        <div class="card-body">
          <table
            class="
              table table-bordered table-hover table-dark
              text-center
              w-100
            "
          >
            <thead>
              <tr>
                <th></th>
                <th v-for="item in bankStatements" :key="item.id">
                  {{ item.code }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>เงินเข้า</td>
                <td
                  v-for="item in bankStatements"
                  :key="item.id"
                  :class="util.getClassAmount(item.depositAmount)"
                >
                  {{ util.formatMoney(item.depositAmount) }}
                </td>
              </tr>
              <tr>
                <td>เงินออก</td>
                <td
                  v-for="item in bankStatements"
                  :key="item.id"
                  :class="util.getClassAmount(item.withdrawAmount)"
                >
                  {{ util.formatMoney(item.withdrawAmount) }}
                </td>
              </tr>
              <tr>
                <td>รวม</td>
                <td
                  v-for="item in bankStatements"
                  :key="item.id"
                  :class="util.getClassAmount(item.sum)"
                >
                  {{ util.formatMoney(item.sum) }}
                </td>
              </tr>
              <tr>
                <td>ทั้งหมด</td>
                <td colspan="3" :class="util.getClassAmount(sumBankStatement)">
                  {{ util.formatMoney(sumBankStatement) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

      <div class="card border-primary mb-3" style="width: 700px">
        <h6 class="card-header border-primary bg-primary text-white p-2">
          แจ้งฝากถอน
        </h6>
        <div class="card-body d-flex">
          <table
            class="
              table table-bordered table-hover table-dark
              text-center
              mr-3
              w-100
            "
          >
            <thead>
              <tr>
                <th></th>
                <th v-for="item in bankStatements" :key="item.id">
                  {{ item.code }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>แจ้งฝาก</td>
                <td
                  v-for="item in bankStatements"
                  :key="item.id"
                  :class="util.getClassAmount(item.depositRequest)"
                >
                  {{ util.formatMoney(item.depositRequest) }}
                </td>
              </tr>
              <tr>
                <td>แจ้งถอน</td>
                <td
                  v-for="item in bankStatements"
                  :key="item.id"
                  :class="util.getClassAmount(item.withdrawRequest)"
                >
                  {{ util.formatMoney(item.withdrawRequest) }}
                </td>
              </tr>
              <tr>
                <td>รวม</td>
                <td
                  v-for="item in bankStatements"
                  :key="item.id"
                  :class="util.getClassAmount(item.sumRequest)"
                >
                  {{ util.formatMoney(item.sumRequest) }}
                </td>
              </tr>
              <tr>
                <td>ทั้งหมด</td>
                <td
                  colspan="3"
                  :class="util.getClassAmount(transferRequestSum)"
                >
                  {{ util.formatMoney(transferRequestSum) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card border-primary mb-3" style="width: 700px">
        <h6 class="card-header border-primary bg-primary text-white p-2">
          เครดิตในระบบทั้งหมด
        </h6>
        <div class="card-body">
          <table
            class="
              table table-bordered table-hover table-dark
              text-center
              w-100
            "
          >
            <thead>
              <tr>
                <th></th>
                <th>จำนวน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>เครดิต</td>
                <td :class="util.getClassAmount(sumUserCredit.creditBalance)">
                  {{ util.formatMoney(sumUserCredit.creditBalance) }}
                </td>
              </tr>
              <!-- <tr>
                <td>เครดิตโบนัส</td>
                <td :class="util.getClassAmount(sumUserCredit.creditBonus)">
                  {{ util.formatMoney(sumUserCredit.creditBonus) }}
                </td>
              </tr> -->
              <!-- <tr>
                <td>รวม</td>
                <td
                  :class="
                    util.getClassAmount(parseFloat(sumUserCredit.creditBalance))
                  "
                >
                  {{
                    util.formatMoney(parseFloat(sumUserCredit.creditBalance))
                  }}
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      fromDate: this.$date(this.fromDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date(this.toDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      sumBankStatement: null,
      userTransferRequetSummary: null,
      sumUserCredit: null,
      transferRequestSum: null,
      bankStatements: null,
      searchDateButtons: [
        {
          name: "วันนี้",
          key: "TODAY",
        },
        {
          name: "เมื่อวาน",
          key: "YESTERDAY",
        },
        {
          name: "สัปดาห์นี้",
          key: "WEEK",
        },
        {
          name: "สัปดาห์ที่แล้ว",
          key: "LASTWEEK",
        },
        {
          name: "เดือนนี้",
          key: "MONTH",
        },
        {
          name: "เดือนที่แล้ว",
          key: "LASTMONTH",
        },
      ],
    }
  },
  methods: {
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.loadData()
    },
    async loadData() {
      this.isLoading = true
      this.sumBankStatement = 0
      this.userTransferRequetSummary = []
      this.sumUserCredit = {}
      this.transferRequestSum = 0
      this.bankStatements = [
        { code: "SCB" },
        { code: "KBANK" },
        { code: "KTB" },
      ]

      try {
        let res = await this.axios({
          method: "get",
          url: "credit/summary",
          params: {
            fromDate: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            toDate: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        let data = res.data.data
        this.bankStatements.forEach((item) => {
          let itemDeposit = data.bankStatementDepositSummary?.find(
            (item2) => item2.bankStatementCode == item.code
          )
          let itemWithdraw = data.bankStatementWithdrawSummary?.find(
            (item2) => item2.bankStatementCode == item.code
          )
          let itemWithdrawRequest = data.userTransferRequestSummary?.find(
            (item2) => item.code == "KTB" && item2.code == "WITHDRAW"
          )
          let itemDepositRequest = data.userTransferRequestSummary?.find(
            (item2) => item2.bankCode == item.code && item2.code == "DEPOSIT"
          )
          item.depositAmount = itemDeposit?.amount || 0
          item.withdrawAmount = itemWithdraw?.amount || 0
          item.depositRequest = itemDepositRequest?.amount || 0
          item.withdrawRequest = itemWithdrawRequest?.amount * -1 || 0
          item.sumRequest =
            parseFloat(item.depositRequest) + parseFloat(item.withdrawRequest)
          item.sum =
            parseFloat(item.depositAmount) + parseFloat(item.withdrawAmount)
          this.transferRequestSum += parseFloat(item.sumRequest)
          this.sumBankStatement += parseFloat(item.sum)
        })
        this.userTransferRequetSummary = data.userTransferRequetSummary
        this.sumUserCredit = data.sumUserCredit
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>