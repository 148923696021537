import HttpRequest from "./http_request"

class GameProvider extends HttpRequest {
  async getAllGameProduct() {
    return this.get("game/product/all")
  }

  async getAllGame() {
    return this.get("game/all")
  }

  async getAllGameBetLimit() {
    return this.get("game/bet-limit/all")
  }
}

export default GameProvider
