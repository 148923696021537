import HttpRequest from "./http_request"

class ReportProvider extends HttpRequest {
    getGameResults(resultDate) {
        return this.get(`report/game-results?resultDate=${resultDate}`)
    }

    getWinnings(roundId) {
        return this.get(`report/winnings/${roundId}`);
    }
}

export default ReportProvider;