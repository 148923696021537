<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มหวย', 'อัตราจ่าย/ส่วนลด/af']"
    />
    <platform-nav name="อัตราจ่าย/ส่วนลด/af"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <ValidationObserver ref="observer" v-show="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showPlatformName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showPlatformName"
          />
          <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="อัตราจ่าย"
              class="p-0"
              :active="!$route.query.page || $route.query.page == 'payRate'"
              @click="setPage('payRate')"
            >
              <b-card-text>
                <b-tabs card>
                  <b-tab
                    :title="huayPay.huayPayName"
                    v-for="(huayPay, index) in filterHuayPayOpen"
                    :key="index"
                    :active="$route.query.payId == huayPay.huayPayId"
                    @click="setPayId(huayPay.huayPayId)"
                  >
                    <b-card-text
                      ><div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th>
                                <button
                                  class="btn btn-primary mr-1"
                                  @click="saveData"
                                  :disabled="!canEdit"
                                >
                                  บันทึก
                                </button>
                                <button
                                  class="btn btn-danger"
                                  @click="clearPayrate(huayPay.huayPayId)"
                                  :disabled="!canEdit"
                                >
                                  ยกเลิก
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllPayrate"
                                  @change="toggleCheckAllPayrate"
                                  :disabled="!canEdit"
                                />
                              </th>
                              <th
                                v-for="(
                                  platformRefHuayCategoryPay, index2
                                ) in filterPlatformRefHuayCategoryPay(
                                  huayPay.huayPayId
                                )"
                                :key="index2"
                                class="align-top"
                              >
                                <ValidationProvider
                                  :rules="`min_value:0|numeric`"
                                  v-slot="v"
                                >
                                  <div class="d-flex flex-column min-width-5">
                                    <span>
                                      {{
                                        platformRefHuayCategoryPay.huayCategoryName
                                      }}
                                    </span>
                                    <input
                                      type="number"
                                      class="
                                        form-control form-control-sm form-small
                                      "
                                      :readonly="!canEdit"
                                      @keypress="$root.onlyNumber"
                                      v-model="
                                        platformRefHuayCategoryPay.payrate
                                      "
                                      v-if="
                                        showItem(
                                          platformRefHuayCategoryPay.status
                                        )
                                      "
                                    />
                                    <span class="text-danger">{{
                                      v.errors[0]
                                    }}</span>
                                  </div>
                                </ValidationProvider>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(platform, index2) in platforms"
                              :key="index2"
                              :class="{
                                'table-danger': platform.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformPayByPlatformId',
                                    params: { platformId: platform.id },
                                    query: {
                                      page: 'payRate',
                                      payId: huayPay.huayPayId,
                                    },
                                  }"
                                >
                                  {{ platform.code }}
                                </router-link>
                                <span v-if="showPlatformName"
                                  >({{ platform.name }})</span
                                >
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="platform.checkPayrate"
                                  :disabled="!canEdit"
                                />
                              </td>
                              <td
                                v-for="(
                                  platformHuayCategoryPay, index3
                                ) in filterPlatformHuayCategoryPay(
                                  platform.id,
                                  huayPay.huayPayId
                                )"
                                :key="index3"
                              >
                                <span
                                  v-if="
                                    showItem(platformHuayCategoryPay.status)
                                  "
                                  >{{ platformHuayCategoryPay.payrate }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
            <b-tab
              title="ส่วนลด"
              class="p-0"
              :active="$route.query.page == 'Discount'"
              @click="setPage('Discount')"
            >
              <b-card-text
                ><b-tabs card>
                  <b-tab
                    :title="huayPay.huayPayName"
                    v-for="(huayPay, index) in filterHuayPayOpen"
                    :key="index"
                    :active="$route.query.payId == huayPay.huayPayId"
                    @click="setPayId(huayPay.huayPayId)"
                  >
                    <b-card-text
                      ><div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th>
                                <button
                                  class="btn btn-primary mr-1"
                                  @click="saveData"
                                  :disabled="!canEdit"
                                >
                                  บันทึก
                                </button>
                                <button
                                  class="btn btn-danger"
                                  @click="clearDiscount(huayPay.huayPayId)"
                                  :disabled="!canEdit"
                                >
                                  ยกเลิก
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllDiscount"
                                  @change="toggleCheckAllDiscount"
                                  :disabled="!canEdit"
                                />
                              </th>
                              <th
                                v-for="(
                                  platformRefHuayCategoryPay, index2
                                ) in filterPlatformRefHuayCategoryPay(
                                  huayPay.huayPayId
                                )"
                                :key="index2"
                                class="align-top"
                              >
                                <ValidationProvider
                                  :rules="`min_value:0|numeric`"
                                  v-slot="v"
                                >
                                  <div class="d-flex flex-column min-width-5">
                                    <span>{{
                                      platformRefHuayCategoryPay.huayCategoryName
                                    }}</span>
                                    <input
                                      type="number"
                                      :readonly="!canEdit"
                                      class="
                                        form-control form-control-sm form-small
                                      "
                                      @keypress="$root.onlyNumber"
                                      v-model="
                                        platformRefHuayCategoryPay.discount
                                      "
                                      v-if="
                                        showItem(
                                          platformRefHuayCategoryPay.status
                                        )
                                      "
                                    />
                                    <span class="text-danger">{{
                                      v.errors[0]
                                    }}</span>
                                  </div>
                                </ValidationProvider>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(platform, index2) in platforms"
                              :key="index2"
                              :class="{
                                'table-danger': platform.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformPayByPlatformId',
                                    params: { platformId: platform.id },
                                    query: {
                                      page: 'Discount',
                                      payId: huayPay.huayPayId,
                                    },
                                  }"
                                >
                                  {{ platform.code }}
                                </router-link>
                                <span v-if="showPlatformName"
                                  >({{ platform.name }})</span
                                >
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="platform.checkDiscount"
                                  :disabled="!canEdit"
                                />
                              </td>
                              <td
                                v-for="(
                                  platformHuayCategoryPay, index3
                                ) in filterPlatformHuayCategoryPay(
                                  platform.id,
                                  huayPay.huayPayId
                                )"
                                :key="index3"
                              >
                                <span
                                  v-if="
                                    showItem(platformHuayCategoryPay.status)
                                  "
                                >
                                  {{ platformHuayCategoryPay.discount }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
            <b-tab
              title="affiliate"
              class="p-0"
              :active="$route.query.page == 'affiliate'"
              @click="setPage('affiliate')"
            >
              <b-card-text
                ><b-tabs card>
                  <b-tab
                    :title="huayPay.huayPayName"
                    v-for="(huayPay, index) in filterHuayPayOpen"
                    :key="index"
                    :active="$route.query.payId == huayPay.huayPayId"
                    @click="setPayId(huayPay.huayPayId)"
                  >
                    <b-card-text
                      ><div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th>
                                <button
                                  class="btn btn-primary mr-1"
                                  @click="saveData"
                                  :disabled="!canEdit"
                                >
                                  บันทึก
                                </button>
                                <button
                                  class="btn btn-danger"
                                  @click="clearDiscount(huayPay.huayPayId)"
                                  :disabled="!canEdit"
                                >
                                  ยกเลิก
                                </button>
                              </th>
                              <th>
                                <input
                                  type="checkbox"
                                  v-model="checkAllAffiliate"
                                  @change="toggleCheckAllAffiliate"
                                  :disabled="!canEdit"
                                />
                              </th>
                              <th
                                v-for="(
                                  platformRefHuayCategoryPay, index2
                                ) in filterPlatformRefHuayCategoryPay(
                                  huayPay.huayPayId
                                )"
                                :key="index2"
                                class="align-top"
                              >
                                <ValidationProvider
                                  :rules="`min_value:0|numeric`"
                                  v-slot="v"
                                >
                                  <div class="d-flex flex-column min-width-5">
                                    <span>{{
                                      platformRefHuayCategoryPay.huayCategoryName
                                    }}</span>
                                    <input
                                      type="number"
                                      :readonly="!canEdit"
                                      class="
                                        form-control form-control-sm form-small
                                      "
                                      @keypress="$root.onlyNumber"
                                      v-model="
                                        platformRefHuayCategoryPay.affiliate
                                      "
                                      v-if="
                                        showItem(
                                          platformRefHuayCategoryPay.status
                                        )
                                      "
                                    />
                                    <span class="text-danger">{{
                                      v.errors[0]
                                    }}</span>
                                  </div>
                                </ValidationProvider>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(platform, index2) in platforms"
                              :key="index2"
                              :class="{
                                'table-danger': platform.status == 'INACTIVE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformPayByPlatformId',
                                    params: { platformId: platform.id },
                                    query: {
                                      page: 'affiliate',
                                      payId: huayPay.huayPayId,
                                    },
                                  }"
                                >
                                  {{ platform.code }}
                                </router-link>
                                <span v-if="showPlatformName"
                                  >({{ platform.name }})</span
                                >
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="platform.checkAffiliate"
                                  :disabled="!canEdit"
                                />
                              </td>
                              <td
                                v-for="(
                                  platformHuayCategoryPay, index3
                                ) in filterPlatformHuayCategoryPay(
                                  platform.id,
                                  huayPay.huayPayId
                                )"
                                :key="index3"
                              >
                                <span
                                  v-if="
                                    showItem(platformHuayCategoryPay.status)
                                  "
                                >
                                  {{ platformHuayCategoryPay.affiliate }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-small {
  width: 100px;
}
</style>

<script>
export default {
  data() {
    return {
      showPlatformName: true,
      checkAllPayrate: false,
      checkAllDiscount: false,
      checkAllAffiliate: false,
      platformRefHuayCategoryPays: [],
      platformHuayCategoryPays: [],
      platforms: [],
      huayPays: [],
      isLoading: true,
      isNotFound: false,
    }
  },
  computed: {
    filterHuayPayOpen() {
      return this.huayPays.filter((item) => item.status == "OPEN")
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_pay_edit") 
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    setPayId(val) {
      this.$route.query.payId = val
    },
    showItem(status) {
      return status == "OPEN"
    },
    filterPlatformRefHuayCategoryPay(huayPayId) {
      return this.platformRefHuayCategoryPays.filter(
        (item2) => item2.huayPayId == huayPayId
      )
    },
    filterPlatformHuayCategoryPay(platformId, huayPayId) {
      return this.platformHuayCategoryPays.filter(
        (item) => item.platformId == platformId && item.huayPayId == huayPayId
      )
    },
    clearPayrate(huayPayId) {
      this.platformRefHuayCategoryPays.forEach((item2) => {
        if (item2.huayPayId == huayPayId) {
          item2.payrate = null
        }
      })
    },
    clearDiscount(huayPayId) {
      this.platformRefHuayCategoryPays.forEach((item2) => {
        if (item2.huayPayId == huayPayId) {
          item2.discount = null
        }
      })
    },
    clearAffiliate(huayPayId) {
      this.platformRefHuayCategoryPays.forEach((item2) => {
        if (item2.huayPayId == huayPayId) {
          item2.discount = null
        }
      })
    },
    toggleCheckAllPayrate() {
      this.platforms.forEach((item) => {
        item.checkPayrate = this.checkAllPayrate
      })
    },
    toggleCheckAllDiscount() {
      this.platforms.forEach((item) => {
        item.checkDiscount = this.checkAllDiscount
      })
    },
    toggleCheckAllAffiliate() {
      this.platforms.forEach((item) => {
        item.checkAffiliate = this.checkAllAffiliate
      })
    },
    async saveData() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      let res = await this.$swal({
        html: `การตั้งค่าจะส่งผลถึงทุกแพลตฟอร์ม ที่อยู่ชั้นถัดลงไป`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      })
      if (!res.isConfirmed) {
        return
      }

      this.platformRefHuayCategoryPays.forEach((item) => {
        if (item.payrate) {
          item.checkPayrate = true
        }
        if (item.discount) {
          item.checkDiscount = true
        }
        if (item.affiliate) {
          item.checkAffiliate = true
        }
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "platform/change-category-pay",
          data: {
            platformRefHuayCategoryPays:
              this.platformRefHuayCategoryPays.filter(
                (item) =>
                  item.checkPayrate || item.checkDiscount || item.checkAffiliate
              ),
            platformHuayCategoryPays: this.platformHuayCategoryPays,
            platforms: this.platforms.filter(
              (item) =>
                item.checkPayrate || item.checkDiscount || item.checkAffiliate
            ),
          },
        })
        await this.swal.swalSuccess()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      try {
        let res = await this.axios({
          method: "get",
          url: "platform/category-pay-data",
          params: {
            platformId: this.$route.params.platformId,
          },
        })

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }

        let data = res.data.data

        this.huayPays = data.huayPays

        data.platformHuayCategoryPays.sort(function (a, b) {
          return a.huayCategoryId > b.huayCategoryId ? 1 : -1
        })
        this.platformHuayCategoryPays = data.platformHuayCategoryPays

        data.platformRefHuayCategoryPays.sort(function (a, b) {
          return a.huayCategoryId > b.huayCategoryId ? 1 : -1
        })

        data.platformRefHuayCategoryPays.forEach((item) => {
          item.fixPayrate = item.payrate
          item.fixDiscount = item.discount
          item.fixAffiliate = item.affiliate
          item.payrate = null
          item.discount = null
          item.affiliate = null
        })
        this.platformRefHuayCategoryPays = data.platformRefHuayCategoryPays

        data.platforms.forEach((item) => {
          item.checkPayrate = false
          item.checkDiscount = false
          item.checkAffiliate = false
        })
        this.platforms = data.platforms

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
