import HttpRequest from "./http_request"

class PlatformProvider extends HttpRequest {
  async getPlatformList(platformId) {
    return this.get(`platform/list?platformId=${platformId}`)
  }

  async getPlatform() {
    return this.get("platform/")
  }

  async getAddPlatformData() {
    return this.get(`platform/add-platform-data`)
  }

  async addPlatform(data) {
    return this.create(`platform/add`, data)
  }

  async getPlatformHHuayTypeShare(typeId, platformId) {
    return this.get(`platform/type/${typeId}/share`, {
      platformId: platformId
    })
  }

  async setPlatformHuayTypeShare(platformId, data) {
    return this.update(`platform/type/share?platformId=${platformId}`, data)
  }

  async setPlatformHuayTypeShareByGroup(
    platformId,
    huayTypeId,
    huayGroupId,
    data
  ) {
    let url = `platform/type/share/by-group?platformId=${platformId}&huayTypeId=${huayTypeId}&huayGroupId=${huayGroupId}`
    return this.update(url, data)
  }

  async getEditPlatformCategoryData(platformId) {
    return this.get(`platform/category-data?platformId=${platformId}`)
  }

  async changeCategory(data) {
    return this.create(`platform/change-category`, data)
  }

  async editPlatform(data) {
    return this.update("platform/edit", data)
  }

  async setPlatformGameProductShare(platformId, data) {
    return this.update(`platform/game-product/share?platformId=${platformId}`, data)
  }

  async getPlatformGameProductShare(gameProductCode, platformId) {
    return this.get(`platform/game-product/${gameProductCode}/share`, {
      platformId: platformId
    })
  }
}

export default PlatformProvider
