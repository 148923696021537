<template>
  <ol class="breadcrumb user-nav">
    <li class="breadcrumb-item">{{ name }}</li>
    <li class="breadcrumb-item" v-for="(item, index) in platforms" :key="index">
      <router-link
        v-if="platforms.length - 1 != index"
        :to="{
          name: $route.name,
          params: { platformId: item.id },
          query: $route.query,
        }"
        >{{ item.code }}</router-link
      >
      <span v-if="platforms.length - 1 == index">{{ item.code }}</span>
    </li>
  </ol>
</template>

<style lang="scss">
.user-nav {
  border-bottom: 1px dotted #e2e2e2 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;

  li + li:before {
    color: #fff !important;
    content: ">>" !important;
  }
}
</style>

<script>
export default {
  props: ["name"],
  data() {
    return {
      platforms: [],
    }
  },
  methods: {
    async loadData() {
      try {
        let res = await this.axios({
          method: "get",
          url: "platform/ref",
          params: {
            id: this.$route.params.platformId,
          },
        })

        this.platforms = res.data.data
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  created() {
    this.loadData()
  },
}
</script>