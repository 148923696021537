<template>
  <div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'เพิ่มสมาชิก']" />
      <ValidationObserver ref="observer" tag="form" @submit.prevent="addMember">
        <div class="card border-primary">
          <h5 class="card-header border-primary bg-primary text-white">
            ข้อมูลสมาชิก
          </h5>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-1 col-form-label text-right">ระดับ:</label>
              <div class="col-2">
                <select class="form-control" v-model="form.level" :disabled="!canEdit">
                  <option v-bind:value="item.levelEN" v-for="(item, index) in optionLevel" :key="index">
                    {{ item.levelTH }}
                  </option>
                </select>
              </div>
              <template v-if="['COMPANY', 'AGENT', 'MEMBER'].indexOf(form.level) !== -1">
                <label class="col-1 col-form-label text-right">
                  จำนวนเงิน:
                </label>
                <div class="col-2">
                  <ValidationProvider :rules="`required|min_value:0|max_value:${user.creditBalance}`" v-slot="v">
                    <input type="number" class="form-control" placeholder="จำนวนเงิน" step="1"
                      v-model="form.creditBalance" :readonly="!canEdit" />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <span class="col">
                  *สูงสุด {{ util.formatMoney(user.creditBalance) }}
                </span>
              </template>
            </div>
            <div class="form-group row">
              <label class="col-1 col-form-label text-right">แพลตฟอร์ม:</label>
              <div class="col-2">
                <ValidationProvider :rules="`required`" v-slot="v">
                  <select class="form-control" v-model="form.platformId" :disabled="!canEdit">
                    <option v-bind:value="item.id" v-for="(item, index) in platforms" :key="index">
                      {{ item.name }}
                    </option>
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-1 col-form-label text-right">ชื่อผู้ใช้:</label>
              <div class="col-2">
                <ValidationProvider rules="required|alpha_num" v-slot="v">
                  <div class="input-group">
                    <div class="input-group-prepend" v-if="form.level != 'MEMBER'">
                      <span class="input-group-text">{{ platformName }}@</span>
                    </div>
                    <input type="text" class="form-control" placeholder="ชื่อผู้ใช้" v-model="form.username"
                      :readonly="!canEdit" />
                  </div>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-1 col-form-label text-right">รหัสผ่าน:</label>
              <div class="col-2">
                <ValidationProvider rules="required|min:6" v-slot="v">
                  <input type="text" class="form-control" placeholder="รหัสผ่าน" v-model="form.password"
                    :readonly="!canEdit" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col">
                * อย่างน้อย 8 ตัวอักษร และต้องมีภาษาอังกฤษอย่างน้อย 2 ตัวอักษร
              </div>
            </div>
            <div class="form-group row">
              <label class="col-1 col-form-label text-right">ชื่อสกุล:</label>
              <div class="col-2">
                <input type="text" class="form-control" placeholder="ชื่อสกุล" v-model="form.name" :readonly="!canEdit" />
              </div>
              <label class="col-1 col-form-label text-right">เบอร์โทร:</label>
              <div class="col-2">
                <ValidationProvider rules="numeric" v-slot="v">
                  <input type="text" class="form-control" placeholder="เบอร์โทร" v-model="form.phoneNo"
                    :readonly="!canEdit" />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div v-if="form.level != 'MEMBER'" class="form-group row">
              <label class="col-1 col-form-label text-right">สิทธิ์เข้าใช้งาน:</label>
              <div class="col-11">
                <b-form-group label="" v-slot="{ ariaDescribedby }" class="" v-for="(item, index) in permissionGroups"
                  :key="'checkbox-group-permission-' + index">
                  <b-form-checkbox-group :id="'checkbox-group-permission-' + index" v-model="form.permissions"
                    :options="item[1]" :aria-describedby="ariaDescribedby"
                    :name="'permission-' + index"></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" v-show="canEdit">เพิ่มสมาชิก</button>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 1rem;
}

.form-pay {
  width: 60px;
}
</style>

<script>
export default {
  name: "AddMember",
  data() {
    return {
      form: {
        platformId: 0,
        level: "",
        permissions: [],
      },
      optionLevels: [
        { levelTH: "บริษัท", levelEN: "COMPANY", level: 99 },
        { levelTH: "customer support", levelEN: "CUSTOMER_SUPPORT", level: 32 },
        {
          levelTH: "คอลเซ็นเตอร์แอดมิน",
          levelEN: "CALL_CENTER_ADMIN",
          level: 31,
        },
        { levelTH: "เอเย่นต์", levelEN: "AGENT", level: 10 },
        { levelTH: "เมเนเจอร์", levelEN: "MANAGER", level: 9.1 },
        { levelTH: "คอลเซ็นเตอร์", levelEN: "CALL_CENTER", level: 9 },
        { levelTH: "เมมเบอร์", levelEN: "MEMBER", level: 0 },
      ],
      user: {},
      platforms: [],
      isLoading: true,
      permissions: [],
      permissionLevelTemplate: {},
    }
  },
  computed: {
    level() {
      return this.form.level
    },
    optionLevel() {
      var levels = this.optionLevels.filter(
        (item) => item.level <= this.user.levelNumber
      )
      return levels
    },
    platformName() {
      return this.platforms.find((item) => item.id === this.form.platformId)
        ?.code
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("add_member_edit")
    },
    permissionGroups() {
      return this.util.groupBy(this.permissions, "groupId")
    },
  },
  watch: {
    level() {
      this.setPermissionTemplate()
    },
    permissionGroups() {
      this.setPermissionTemplate()
    },
  },
  methods: {
    setPermissionTemplate() {
      const templatePermissions = this.permissionLevelTemplate[this.level] || []
      this.form.permissions = this.permissions
        .filter((item) => templatePermissions.includes(item.code))
        .map((x) => x.code)
    },
    async addMember() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "auth/register",
          data: {
            user: this.form,
          },
        })

        let result = await this.$swal({
          icon: "success",
          text: "เพิ่มสมาชิกสำเร็จ",
        })
        if (result.isConfirmed) {
          this.$store.dispatch("auth/updateUserData")

          this.$router.push("/members/edit/member")
        }
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เพิ่มสมาชิกไม่สำเร็จ",
          text: e.response.data.description,
        })
      }
    },
    async loadData() {
      try {
        let response = await this.axios.get("users/add-member-data")
        let data = response.data.data

        this.$store.state.auth.user.userData = data.user
        this.user = data.user
        this.platforms = data.platforms

        this.form.platformId = data.user.platformId
        const permissions = data.permissions.map((item) => {
          item.text = item.name
          item.value = item.code
          return item
        })
        this.permissions = permissions
        this.permissionLevelTemplate = data.permissionLevelTemplate

        if (data.user.level == "PARTNER") {
          this.form.level = "AGENT"
        } else {
          this.form.level = data.user.level
        }

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  created() {
    this.loadData()
  },
}
</script>
