import LottoProvider from "@/resources/lotto_provider"
import swal from "@/helpers/swal"

const lottoService = new LottoProvider()
const state = {
  lottoGroups: [],
  isLottoGroupLoading: false,
  lottoTypes: [],
  isLottoTypeLoading: false,
  lottoCategories: [],
  isLottoCategoryLoading: false,
  lottoGroupPays: [],
  isLottoGroupPayLoading: false,
  lottoPays: [],
  isLottoPayLoading: false
}

const getters = {}

const mutations = {
  setIsLottoGroupLoading: (state, isLoading) => {
    state.isLottoGroupLoading = isLoading
  },
  setLottoGroups: (state, lottoGroups) => {
    state.lottoGroups = lottoGroups
  },
  setAllLottoGroups: (state, lottoGroups) => {
    state.lottoGroups = lottoGroups
  },
  setIsLottoTypeLoading: (state, isLoading) => {
    state.isLottoTypeLoading = isLoading
  },
  setLottoTypes: (state, lottoTypes) => {
    state.lottoTypes = lottoTypes
  },
  setIsLottoCategoryLoading: (state, isLoading) => {
    state.isLottoCategoryLoading = isLoading
  },
  setLottoCategories: (state, lottoCategories) => {
    state.lottoCategories = lottoCategories
  },
  setIsLottoGroupPayLoading: (state, isLoading) => {
    state.isLottoGroupPayLoading = isLoading
  },
  setLottoGroupPays: (state, lottoGroupPays) => {
    state.lottoGroupPays = lottoGroupPays
  },
  setLottoPays: (state, lottoPays) => {
    state.lottoPays = lottoPays
  },
  setIsLottoPayLoading: (state, isLoading) => {
    state.isLottoPayLoading = isLoading
  }
}

const actions = {
  async getLottoGroupList({ commit }) {
    commit("setIsLottoGroupLoading", true)
    // swal.processing()
    const data = await lottoService.getLottoGroupList()
    commit("setIsLottoGroupLoading", false)
    if (data.error) {
      swal.swalError(data.error.message)
      commit("setLottoGroups", [])
    } else {
      commit("setLottoGroups", data.result.list)
    }
  },
  async getAllLottoGroupList({ commit }) {
    commit("setIsLottoGroupLoading", true)
    // swal.processing()
    const data = await lottoService.getAllLottoGroupList()
    commit("setIsLottoGroupLoading", false)
    if (data.error) {
      swal.swalError(data.error.message)
      commit("setAllLottoGroups", [])
    } else {
      commit("setAllLottoGroups", data.result.list)
    }
  },
  async getLottoTypeList({ commit }) {
    commit("setIsLottoTypeLoading", true)
    // swal.processing()
    const data = await lottoService.getLottoTypeList()
    commit("setIsLottoTypeLoading", false)
    if (data.error) {
      swal.swalError(data.error.message)
      commit("setLottoTypes", [])
    } else {
      commit("setLottoTypes", data.result.list)
    }
  },
  async getLottoCategoryList({ commit }) {
    commit("setIsLottoCategoryLoading", true)
    // swal.processing()
    const data = await lottoService.getLottoCategoryList()
    commit("setIsLottoCategoryLoading", false)
    if (data.error) {
      swal.swalError(data.error.message)
      commit("setLottoCategories", [])
    } else {
      commit("setLottoCategories", data.result.list)
    }
  },
  async getLottoGroupPayList({ commit }) {
    commit("setIsLottoGroupPayLoading", true)
    // swal.processing()
    const data = await lottoService.getLottoGroupPayList()
    commit("setIsLottoGroupPayLoading", false)
    if (data.error) {
      swal.swalError(data.error.message)
      commit("setLottoGroupPays", [])
    } else {
      commit("setLottoGroupPays", data.result.list)
    }
  },
  async getLottoPayList({ commit }) {
    commit("setIsLottoPayLoading", true)
    // swal.processing()
    const data = await lottoService.getLottoPayList()
    commit("setIsLottoPayLoading", false)
    if (data.error) {
      swal.swalError(data.error.message)
      commit("setLottoPays", [])
    } else {
      commit("setLottoPays", data.result.list)
    }
  }
}

const modules = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}
