<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'อัตราจ่าย']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายการอัตราจ่าย
      </h5>
      <div class="card-body">
        <button
          class="btn btn-success mb-2"
          @click="$router.push('/setting/pay-rate/add')"
          v-show="canEdit"
        >
          เพิ่มอัตราจ่าย
        </button>
        <loading v-show="isLoading" />
        <h5 class="text-center" v-if="!isLoading && !items.length">
          ไม่มีข้อมูล
        </h5>
        <div class="table-responsive" v-show="!isLoading && items.length">
          <table class="table table-bordered table-dark text-center">
            <thead>
              <tr>
                <th scope="col">ชื่อ</th>
                <th scope="col">ลำดับการแสดง</th>
                <td></td>
                <th v-for="item in categories" :key="item.id">
                  {{ item.name }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in items">
                <tr :key="'payrate' + item.huayPay.id">
                  <td rowspan="3" class="min-width-5 text-gold">
                    {{ item.huayPay.name }}
                  </td>
                  <td rowspan="3" class="min-width-5">
                    {{ item.huayPay.showOrder }}
                  </td>
                  <td class="no-white-space">อัตราจ่าย</td>
                  <td
                    v-for="cate in categories"
                    :key="'payrate' + item.huayPay.id + cate.id"
                  >
                    {{
                      findHuayCategoryPay(item, cate.id)
                        ? findHuayCategoryPay(item, cate.id).payRate
                        : 0
                    }}
                  </td>
                  <td rowspan="3" class="no-white-space">
                    <router-link
                      class="btn btn-primary"
                      :class="{disabled:!canEdit}"
                      :to="{
                        path: '/setting/pay-rate/edit',
                        query: { id: item.huayPay.id },
                      }"
                    >
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </td>
                </tr>
                <tr :key="'discount' + item.huayPay.id">
                  <td class="no-white-space">ส่วนลด</td>
                  <td
                    v-for="cate in categories"
                    :key="'discount' + item.huayPay.id + cate.id"
                  >
                    {{
                      findHuayCategoryPay(item, cate.id)
                        ? findHuayCategoryPay(item, cate.id).discount
                        : 0
                    }}
                  </td>
                </tr>
                <tr :key="'affiliate' + item.huayPay.id">
                  <td class="no-white-space">affiliate</td>
                  <td
                    v-for="cate in categories"
                    :key="'affiliate' + item.huayPay.id + cate.id"
                  >
                    {{
                      findHuayCategoryPay(item, cate.id)
                        ? findHuayCategoryPay(item, cate.id).affiliate
                        : 0
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    categories: function () {
      return this.$store.state.lotto.lottoCategories
    },
    isLoading: function () {
      return (
        this.$store.state.lotto.isLottoCategoryLoading ||
        this.$store.state.setting.isPayRateLoading
      )
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("pay_rate_list_edit") 
    },
    items: function () {
      return this.$store.state.setting.payRates
    },
  },
  mounted() {
    this.getLottoCategoryList()
    this.getPayRateList()
  },
  methods: {
    findHuayCategoryPay(item, categoryId) {
      return (
        item.huayCategoryPays &&
        item.huayCategoryPays.find((ele) => ele.huayCategoryId == categoryId)
      )
    },
    getPayRateList() {
      this.$store.dispatch("setting/getPayRateList", {}, { root: true })
    },
    getLottoCategoryList() {
      this.$store.dispatch("lotto/getLottoCategoryList", {}, { root: true })
    },
  },
}
</script>