import axios from "axios";
import { addHeaderAuthorization, refreshToken } from "@/helpers/setup";

class HttpRequest {
  constructor(baseURL = process.env.VUE_APP_API_URL) {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      timeout: 120000
    });

    this.axiosInstance.interceptors.request.use(
      function(config) {
        addHeaderAuthorization(config);
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      function(response) {
        return makeAxiosResponse(response);
      },
      async function(error) {
        try {
          let res = await refreshToken(error);
          return makeAxiosResponse(res);
        } catch (e) {
          console.log(e);
        }
        console.log(error);
        return {
          error: {
            code:
              error.response && error.response && error.response.data
                ? error.response.data.code
                : -1,
            message:
              error.response && error.response && error.response.data
                ? error.response.data.description
                : "เกิดข้อผิดพลาด"
          }
        };
      }
    );
  }

  setHeader(header) {
    // this.axiosInstance.defaults.headers.common[header.key] = header.value
    this.axiosInstance.defaults.headers.common = header;
    // this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    // this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'
  }

  setHeaderContentTypeFormData() {
    this.axiosInstance.defaults.headers.common["Content-Type"] =
      "multipart/form-data";
  }

  setHeaderContentTypeJson() {
    this.axiosInstance.defaults.headers.common["Content-Type"] =
      "application/json";
  }

  async get(methodName, data, headers = {}) {
    return this.axiosInstance.get(methodName, {
      params: data,
      headers: headers
    });
  }

  async create(methodName, data, headers = {}) {
    return this.axiosInstance.post(methodName, data, {
      headers: headers
    });
  }

  async update(methodName, data, headers = {}) {
    return this.axiosInstance.put(methodName, data, {
      headers: headers
    });
  }

  async delete(methodName, param, data, headers = {}) {
    return this.axiosInstance.delete(methodName, {
      params: param,
      data: data,
      headers: headers
    });
  }

  request(type, url, data, headers = {}) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, {
          params: data,
          headers: headers
        });
        break;
      case "POST":
        promise = axios.post(url, data, {
          headers: headers
        });
        break;
      case "PUT":
        promise = axios.put(url, data, {
          headers: headers
        });
        break;
      case "DELETE":
        promise = axios.delete(url, data, {
          headers: headers
        });
        break;
      default:
        promise = axios.get(url, {
          params: data,
          headers: headers
        });
        break;
    }
    return promise;
  }
}

function makeAxiosResponse(response) {
  var error = null;
  var result = null;
  if (response.data) {
    if (response.data.code != 0) {
      error = {
        code: response.data.code,
        message: response.data.description
      };
    }
    result = response.data.data || null;
  } else {
    error = {
      code: -1,
      message: "เกิดข้อผิดพลาด"
    };
  }

  return {
    error: error,
    result: result
  };
}

export default HttpRequest;
