<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าหวย', 'อัตราจ่ายตามชนิดหวย']" />
    <platform-nav name="อัตราจ่ายตามชนิดหวย"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-if="!isLoading && !isNotFound">
      <div class="alert alert-info" role="alert">
        สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
      </div>
      <div class="d-flex mb-1">
        <input
          id="showPlatformName"
          type="checkbox"
          class="cursor-pointer mr-1 my-auto"
          v-model="showPlatformName"
        />
        <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
      </div>
      <b-card no-body>
        <b-tabs card v-model="huayGroupTabIndex">
          <b-tab
            v-for="(huayGroup, huayGroupIndex) in huayGroups"
            :key="huayGroupIndex"
            :title="huayGroup.name"
            :active="$route.query.groupId == huayGroup.id"
            @click="$route.query.groupId = huayGroup.id"
            class="p-0"
          >
            <b-card-text v-if="huayGroupTabIndex === huayGroupIndex">
              <b-tabs card v-model="huayTypeTabIndex">
                <b-tab
                  v-for="(huayType, huayTypeIndex) in filterHuayType(
                    huayGroup.id
                  )"
                  :key="huayTypeIndex"
                  :title="huayType.name"
                  :active="$route.query.typeId == huayType.id"
                  @click="$route.query.typeId = huayType.id"
                  class="p-0"
                >
                  <b-card-text v-if="huayTypeTabIndex === huayTypeIndex">
                    <b-tabs card v-model="platformRefHuayTypePayTabIndex">
                      <b-tab
                        :title="platformRefHuayTypePay.payName"
                        v-for="(
                          platformRefHuayTypePay, platformRefHuayTypePayIndex
                        ) in filterPlatformRefHuayTypePays(huayType.id)"
                        :key="platformRefHuayTypePayIndex"
                        :active="
                          $route.query.payId == platformRefHuayTypePay.huayPayId
                        "
                        @click="
                          $route.query.payId = platformRefHuayTypePay.huayPayId
                        "
                      >
                        <b-card-text
                          v-if="
                            platformRefHuayTypePayTabIndex ===
                            platformRefHuayTypePayIndex
                          "
                        >
                          <div class="d-flex mb-3">
                            <h5 class="my-auto mr-2">
                              ตั้งค่ากลุ่มหวยตาม{{ huayType.name }} :
                            </h5>
                            <button
                              class="btn btn-sm btn-warning mr-2"
                              v-for="(
                                huayGroupItem, huayGroupIndex
                              ) in huayGroups"
                              :key="huayGroupIndex"
                              @click="
                                openModalSetLikeType(huayGroupItem, huayType)
                              "
                            >
                              กลุ่ม{{ huayGroupItem.name }}
                            </button>
                          </div>
                          <div class="table-responsive">
                            <table
                              class="table table-bordered table-hover table-dark text-center fs-075"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <button
                                      class="btn btn-success mr-1"
                                      :disabled="!canEdit"
                                      @click="
                                        setStatusPlatformHuayTypePay('OPEN')
                                      "
                                    >
                                      เปิด
                                    </button>
                                    <button
                                      class="btn btn-danger mr-1"
                                      :disabled="!canEdit"
                                      @click="
                                        setStatusPlatformHuayTypePay('CLOSE')
                                      "
                                    >
                                      ปิด
                                    </button>
                                  </th>
                                  <th>
                                    <input
                                      type="checkbox"
                                      :disabled="!canEdit"
                                      v-model="
                                        checkAllPlatformHuayTypePay[
                                          platformRefHuayTypePayIndex
                                        ]
                                      "
                                      @change="
                                        toggleCheckAllPlatformHuayTypePay(
                                          huayType.id,
                                          platformRefHuayTypePay.huayPayId,
                                          platformRefHuayTypePayIndex
                                        )
                                      "
                                    />
                                  </th>
                                  <th>สถานะ</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(
                                    platformHuayTypePay,
                                    platformHuayTypePayIndex
                                  ) in filterPlatformHuayTypePay(
                                    huayType.id,
                                    platformRefHuayTypePay.huayPayId
                                  )"
                                  :key="platformHuayTypePayIndex"
                                  :class="{
                                    'table-danger':
                                      platformHuayTypePay.platformStatus ==
                                      'CLOSE',
                                  }"
                                >
                                  <td class="text-left">
                                    <router-link
                                      :to="{
                                        name: 'PlatformHuayTypePayByPlatformId',
                                        params: {
                                          platformId:
                                            platformHuayTypePay.platformId,
                                        },
                                        query: {
                                          typeId: huayType.id,
                                          groupId: huayGroup.id,
                                        },
                                      }"
                                    >
                                      {{ platformHuayTypePay.platformCode }}
                                    </router-link>
                                    <span v-if="showPlatformName"
                                      >({{
                                        platformHuayTypePay.platformName
                                      }})</span
                                    >
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      :disabled="!canEdit"
                                      v-model="platformHuayTypePay.check"
                                    />
                                  </td>
                                  <td>
                                    {{
                                      platformHuayTypePay.status == "OPEN"
                                        ? "เปิด"
                                        : "ปิด"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <b-modal
      id="modal-set-like-type"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      hide-footer
      :title="`ตั้งค่ากลุ่มหวย ${setLikeType.huayGroup.name} ตาม ${setLikeType.huayType.name}`"
    >
      <span>เลือกแพลตฟอร์มที่ต้องการตั้งตาม</span>
      <div
        class="form-check"
        v-for="(platformItem, platformIndex) in platforms"
        :key="platformIndex"
      >
        <input
          type="checkbox"
          class="form-check-input"
          :id="`setLikeType${platformIndex}`"
          v-model="platformItem.isSetLikeType"
        />
        <label class="form-check-label" :for="`setLikeType${platformIndex}`"
          >{{ platformItem.code }} ({{ platformItem.name }})</label
        >
      </div>
      <button class="btn btn-primary mt-3" @click="setGroupLikeType">
        ตั้งค่า
      </button>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPlatformName: true,
      isLoading: true,
      isNotFound: false,
      huayGroups: [],
      huayTypes: [],
      platformHuayTypePays: [],
      platformRefHuayTypePays: [],
      checkAllPlatformHuayTypePay: [],
      huayGroupTabIndex: 0,
      huayTypeTabIndex: 0,
      platformRefHuayTypePayTabIndex: 0,
      platforms: [],
      setLikeType: {
        huayGroup: {},
        huayType: {},
      },
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("platform_huay_type_pay_edit") 
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    async setGroupLikeType() {
      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "platform/huay-type-pay/group",
          data: {
            huayTypeId: this.setLikeType.huayType.id,
            huayGroupId: this.setLikeType.huayGroup.id,
            platformIds: this.platforms
              .filter((item) => item.isSetLikeType)
              .map((item) => item.id),
          },
        })

        await this.swal.success()

        this.swal.close()
      } catch (e) {
        await this.swal.catchError(e)
      }

      this.$store.dispatch("auth/forceReload")
    },
    openModalSetLikeType(huayGroup, huayType) {
      this.setLikeType.huayGroup = huayGroup
      this.setLikeType.huayType = huayType

      this.$bvModal.show("modal-set-like-type")
    },
    toggleCheckAllPlatformHuayTypePay(huayTypeId, huayPayId, index) {
      this.platformHuayTypePays.forEach((item) => {
        if (item.huayTypeId === huayTypeId && item.huayPayId == huayPayId) {
          item.check = this.checkAllPlatformHuayTypePay[index]
        }
      })
    },
    filterHuayType(huayGroupId) {
      return this.huayTypes.filter((item) => item.huayGroupId === huayGroupId)
    },
    filterPlatformHuayTypePay(huayTypeId, huayPayId) {
      return this.platformHuayTypePays.filter(
        (item) => item.huayTypeId === huayTypeId && item.huayPayId == huayPayId
      )
    },
    filterPlatformRefHuayTypePays(huayTypeId) {
      return this.platformRefHuayTypePays.filter(
        (item) => item.huayTypeId === huayTypeId
      )
    },
    async setStatusPlatformHuayTypePay(status) {
      this.swal.processing()

      const platformHuayTypePays = this.platformHuayTypePays
        .filter((item) => item.check)
        .map((item) => {
          item.status = status
          return item
        })

      try {
        await this.axios({
          method: "patch",
          url: "platform/huay-type-pay",
          data: {
            platformHuayTypePays,
          },
        })

        await this.swal.swalSuccess()
      } catch (e) {
        await this.swal.swalError()
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      this.isLoading = true

      try {
        const res = await this.axios({
          method: "get",
          url: "platform/huay-type-pay",
          params: {
            platformId: this.$route.params.platformId,
          },
        })

        const data = res.data.data

        if (data.platforms.length === 0) {
          this.isNotFound = true
        } else {
          this.huayGroups = data.huayGroups
          this.huayTypes = data.huayTypes
          this.platformRefHuayTypePays = data.platformRefHuayTypePays

          const mapPlatforms = new Map()
          data.platforms.forEach((item) => mapPlatforms.set(item.id, item))
          data.platformHuayTypePays.forEach((item) => {
            const platform = mapPlatforms.get(item.platformId)
            item.platformCode = platform.code
            item.platformName = platform.name
            item.platformStatus = platform.status
            item.check = false
          })

          this.platforms = data.platforms
          this.platformHuayTypePays = data.platformHuayTypePays
        }

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
