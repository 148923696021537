<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="[
        'ตั้งค่า',
        {
          name: 'กลุ่มหวย',
          action: () => {
            $router.push('/setting/lotto-group')
          },
        },
        id ? 'แก้ไข' : 'เพิ่ม',
      ]"
    />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        {{ id ? "แก้ไขกลุ่มหวย" : "เพิ่มกลุ่มหวย" }}
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="submitForm"
          novalidate
        >
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <span class="col-12 col-sm-2"> #ID: </span>
                <span class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">{{
                  form.id
                }}</span>
              </div>
              <div class="form-group row">
                <label for="lotto-group-name" class="col-12 col-sm-2">
                  ชื่อ:
                </label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <input
                      id="lotto-group-name"
                      type="text"
                      class="form-control mr-1"
                      v-model="form.name"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label for="showOrder" class="col-12 col-sm-2">
                  ลำดับการแสดง:
                </label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <ValidationProvider rules="required|numeric" v-slot="v">
                    <input
                      id="showOrder"
                      type="text"
                      class="form-control mr-1"
                      v-model.number="form.showOrder"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-2">สถานะ:</label>
                <div class="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                  <ValidationProvider rules="required" v-slot="v">
                    <select v-model="form.status" class="form-control">
                      <option value="OPEN">เปิด</option>
                      <option value="CLOSE">ปิด</option>
                    </select>
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12 col-sm-10 offset-sm-2">
                  <button class="btn btn-primary mr-3" type="submit">
                    {{ id ? "บันทึก" : "เพิ่ม" }}
                  </button>
                  <button
                    class="btn btn-danger"
                    @click="$router.push('/setting/lotto-group')"
                    type="button"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import SettingProvider from "@/resources/setting_provider"
import LottoProvider from "@/resources/lotto_provider"
const settingService = new SettingProvider()
const lottoService = new LottoProvider()
export default {
  data() {
    return {
      id: null,
      form: {
        name: "",
      },
    }
  },
  mounted() {
    this.id = this.$route.query.id
    if (this.id) {
      this.getLottoGroupDetail()
    }
  },
  methods: {
    async getLottoGroupDetail() {
      this.swal.processing()
      const data = await lottoService.getLottoGroupDetail(this.id)
      if (data.error) {
        this.swal.swalError(
          data.error.message,
          function () {
            this.$router.push("/setting/lotto-group")
          }.bind(this)
        )
      } else {
        this.form = data.result
        this.swal.close()
      }
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = !this.id
        ? await settingService.addLottoGroup(this.form)
        : await settingService.editLottoGroup(this.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.id ? "เพิ่มกลุ่มหวยสำเร็จ" : "แก้ไขกลุ่มหวยสำเร็จ",
          () => {
            this.$router.push("/setting/lotto-group")
          }
        )
      }
    },
  },
}
</script>