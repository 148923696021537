<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="[tabName, pageName]" />
    <div class="h4">
      <template v-if="isPageItemOfNumber">
        รายการแทงแยกตามหมายเลข {{ category.name }} {{ $route.params.number }}
      </template>
      <template v-if="isPageItemOfMember">รายการแทงแยกตามสมาชิก</template>
      <template v-if="isPageReportWinning">รายการที่ถูกรางวัล</template>
    </div>
    <round-detail
      v-if="!isPageItemByDateOfMember && !isPageReportByDateOfMember"
    />
    <h5
      class="mb-3 pb-3"
      style="border-bottom: 1px dotted #e2e2e2"
      v-if="isPageItemByDateOfMember || isPageReportByDateOfMember"
    >
      <i class="fa fa-angle-right text-gold mr-1" />
      <span class="text-gold">{{ huayType.name }}</span>
      <template v-if="isPageReportByDateOfMember">
        <span
          >, ตั้งแต่วันที่
          {{ $date($route.params.fromDate).format("DD-MM-YYYY") }} ถึง
          {{ $date($route.params.toDate).format("DD-MM-YYYY") }}</span
        >
      </template>
    </h5>
    <loading v-show="isLoading" />
    <div class="table-responsive" v-show="!isLoading">
      <table
        class="
          table table-sm table-dark table-striped
          text-center
          w-100
          table-bordered
        "
      >
        <thead>
          <tr>
            <th>#</th>
            <th>
              <div class="align-center">
                <span>ชื่อผู้ใช้</span>
                &ensp;
                <input
                  id="showMemberName"
                  type="checkbox"
                  class="form-control-input cursor-pointer"
                  v-model="showMemberName"
                />
                &ensp;
                <label for="showMemberName">แสดงชื่อ</label>
              </div>
            </th>
            <th>โพย#</th>
            <th>Bet#</th>
            <th>อัตราจ่าย</th>
            <th>เวลา</th>
            <th>รายการ</th>
            <th>เรทจ่าย</th>
            <th>ยอดแทง</th>
            <th v-if="showWinColumn">ถูกรางวัล</th>
            <th>หมายเหตุ</th>
            <!-- <th></th> -->
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr
              :key="item.id"
              :class="{
                'bg-blue':
                  showWinColumn && item.winAmount > 0 && !isPageReportWinning,
              }"
            >
              <td>{{ index + 1 }}.</td>
              <td>
                {{ item.username }}
                <span class="text-primary" v-if="showMemberName"
                  >({{ item.name }})</span
                >
              </td>
              <td>{{ item.userHuayBillId }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.huayPayName }}</td>
              <td>
                {{ $date(item.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
              </td>
              <td>
                <span class="font-weight-bold positive">{{
                  item.huayCategoryName
                }}</span>
                =
                <span class="text-warning">{{ item.number }}</span>
              </td>
              <td class="text-right">{{ util.formatMoney(item.payRate) }}</td>
              <td class="text-right">
                {{ util.formatMoney(item.buyAmount) }}
              </td>
              <td
                class="text-right"
                :class="{ positive: item.winAmount > 0 }"
                v-if="showWinColumn"
              >
                {{ util.formatMoney(item.winAmount) }}
              </td>
              <td class="text-left">{{ item.note }}</td>
              <!-- <td class="text-center">
                <button
                  class="btn btn-sm btn-primary"
                  @click="toggleItemExpanded(item)"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </td> -->
            </tr>
            <tr :key="item.id + '_expanded'" v-if="item.expanded">
              <td colspan="11">
                <div class="d-flex justify-content-center">
                  <table class="table text-white table-bordered">
                    <thead>
                      <tr>
                        <th>ชื่อผู้ใช้</th>
                        <th>ระดับ</th>
                        <th>ถือ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="bilItem in item.userBillItems"
                        :key="item.id + '_' + bilItem.id"
                      >
                        <td class="text-left">
                          {{
                            item.users.find((ele) => ele.id == bilItem.userId)
                              .username
                          }}
                        </td>
                        <td>
                          {{
                            item.users.find((ele) => ele.id == bilItem.userId)
                              .levelTH
                          }}
                        </td>
                        <td
                          class="text-right"
                          :class="{
                            negative: parseFloat(bilItem.payAmount) < 0,
                            positive: parseFloat(bilItem.payAmount) > 0,
                          }"
                        >
                          {{ util.formatMoney(bilItem.payAmount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="8">รวม</td>
            <td class="text-right">{{ util.formatMoney(totalBuyAmount) }}</td>
            <td
              v-if="showWinColumn"
              class="text-right"
              :class="{ positive: totalPayAmount > 0 }"
            >
              {{ util.formatMoney(totalPayAmount) }}
            </td>
            <td></td>
            <!-- <td></td> -->
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<style lang="scss">
tbody {
  tr:nth-child(even).bg-blue {
    background-color: #004087 !important;
  }

  tr:nth-child(odd).bg-blue {
    background-color: #0f58aa !important;
  }
}
</style>

<script>
import ReportProvider from "@/resources/report_provider"
import BetProvider from "@/resources/bet_provider"

const reportService = new ReportProvider()
const betService = new BetProvider()

export default {
  data() {
    return {
      isLoading: false,
      showMemberName: false,
      items: [],
      isPageReportWinning: this.$route.name == "ReportWinning",
      isPageReportByMemberOfNumber:
        this.$route.name == "ReportByMemberOfNumber",
      isPageItemOfNumber: this.$route.name == "ItemOfNumber",
      isPageItemOfMember: this.$route.name == "ItemOfMember",
      isPageItemByDateOfMember: this.$route.name == "ItemByDateOfMember",
      isPageReportByDateOfMember: this.$route.name == "ReportByDateOfMember",
      category: {},
      huayType: {},
    }
  },
  computed: {
    showWinColumn() {
      return (
        this.isPageReportWinning ||
        this.isPageReportByMemberOfNumber ||
        this.isPageReportByDateOfMember
      )
    },
    pageName() {
      if (this.isPageReportWinning) {
        return "รายการที่ถูกรางวัล"
      } else if (this.isPageItemOfMember) {
        return "ดูของ สมาชิก/ประเภท"
      } else if (this.isPageItemByDateOfMember) {
        return "รอผลเดิมพัน"
      } else if (this.isPageReportByMemberOfNumber) {
        return "แพ้-ชนะ สมาชิก/ประเภท"
      } else if (this.isPageReportByDateOfMember) {
        return "แพ้ชนะ สุทธิ"
      }

      return "ดูของรวม / คาดคะเนได้เสีย"
    },
    tabName() {
      if (this.isPageReportWinning) {
        return "รายงานแพ้ชนะ"
      } else if (this.isPageItemOfMember) {
        return "รายการแทง"
      } else if (this.isPageItemByDateOfMember) {
        return "รายการแทง"
      } else if (this.isPageReportByMemberOfNumber) {
        return "รายงานแพ้ชนะ"
      } else if (this.isPageReportByDateOfMember) {
        return "รายงานแพ้ชนะ"
      }

      return "รายการแทง"
    },
    roundId() {
      return this.$store.state.auth.roundId
    },
    totalBuyAmount() {
      return this.items.reduce((a, b) => a + b["buyAmount"], 0)
    },
    totalPayAmount() {
      return this.items.reduce((a, b) => a + parseFloat(b.winAmount), 0)
    },
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        this.$store.dispatch("auth/forceReload")
      }
    },
    roundId() {
      this.loadData()
    },
  },
  methods: {
    async getReportItemOfMember() {
      this.isLoading = true
      try {
        let res = await this.axios({
          method: "get",
          url: "report/items/of-member",
          params: {
            roundId:
              this.isPageItemOfMember || this.isPageReportByMemberOfNumber
                ? this.roundId
                : null,
            userId: this.$route.params.userId,
            typeId: this.$route.query.typeId,
            fromDate: this.$route.query.fromDate,
            toDate: this.$route.query.toDate,
            onlyType: this.isPageItemByDateOfMember ? true : false,
            status: this.isPageItemByDateOfMember ? "OPEN" : "",
          },
        })

        let data = res.data.data

        this.items = data.items
        this.huayType = data.huayType

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async getItemsOfNumberList() {
      if (!this.roundId) return

      this.isLoading = true
      const res = await betService.getBillItemOfNumbers(
        this.roundId,
        this.$route.params.categoryId,
        this.$route.params.number
      )
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.category = res.result.huayCategory
        this.items = res.result.items.map((ele) => {
          ele.expanded = false
          ele.userBillItems = []
          ele.users = []
          return ele
        })
      }
    },
    async getWinningList() {
      if (!this.roundId) return

      this.isLoading = true

      const res = await reportService.getWinnings(this.roundId)

      this.isLoading = false

      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.items = res.result.list.map((ele) => {
          ele.expanded = false
          ele.userBillItems = []
          ele.users = []
          return ele
        })
      }
    },
    // async toggleItemExpanded(item) {
    //   item.expanded = !item.expanded
    //   if (item.expanded) {
    //     this.swal.processing()
    //     const res = await betService.getUserHuayBillItemsByUserHuayBillItemID(
    //       item.id
    //     )
    //     if (res.error) {
    //       this.swal.swalError(res.error.message)
    //     } else {
    //       this.swal.close()
    //       item.userBillItems = res.result.userHuayBillItems
    //       item.users = res.result.users
    //     }
    //   }
    // },
    loadData() {
      if (
        this.isPageItemOfMember ||
        this.isPageItemByDateOfMember ||
        this.isPageReportByMemberOfNumber ||
        this.isPageReportByDateOfMember
      ) {
        this.getReportItemOfMember()
        return
      }

      this.isPageReportWinning
        ? this.getWinningList()
        : this.getItemsOfNumberList()
    },
  },
  created() {
    this.loadData()
  },
}
</script>
