import HttpRequest from "./http_request";

class DashboardProvider extends HttpRequest {
  async getLottoNumberSumByRoundId(roundId) {
    return this.get(`/dashboard/huay-number-sum/${roundId}`);
  }

  async getBetExceed(roundId) {
    return this.get(`/dashboard/bet-exceed/${roundId}`);
  }

  async editBetReceiveCategory(data) {
    return this.update("/dashboard/bet-receive-category/edit", data);
  }
}

export default DashboardProvider;
