<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าเกม', 'ขั้นต่ำ/สูงสุด']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายการ ขั้นต่ำ/สูงสุด เกม
      </h5>
      <div class="card-body">
        <button class="btn btn-success mb-2" @click="openFormCreate" v-show="canEdit">
          เพิ่ม
        </button>
        <loading v-show="isLoading" />
        <h5 class="text-center" v-if="!isLoading && !items.length">
          ไม่มีข้อมูล
        </h5>
        <div class="table-responsive" v-show="!isLoading && items.length">
          <table
            class="
              table table-bordered table-striped table-hover table-dark
              text-center
            "
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>ชื่อ</th>
                <th>เวลาสร้าง</th>
                <th>แก้ไขล่าสุด</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>
                  {{ $date(item.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  {{ $date(item.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  <button class="btn btn-primary" @click="openFormEdit(item)" :disabled="!canEdit">
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-form"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      :title="(form.id == null ? 'เพิ่ม' : 'แก้ไข') + ' ขั้นต่ำ/สูงสุด เกม'"
      cancel-title="ยกเลิก"
      ok-title="ตกลง"
      hide-footer
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="submitForm"
        novalidate
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group row" v-if="form.id">
              <span class="col-12"> #ID: </span>
              <span class="col-12">{{ form.id }}</span>
            </div>
            <div class="form-group row">
              <label for="form-game-bet-limit-name" class="col-12">
                ชื่อ:
              </label>
              <div class="col-12">
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    id="form-game-bet-limit-name"
                    type="text"
                    class="form-control mr-1"
                    v-model="form.name"
                    autocomplete="off"
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <label for="">ค่ายเกม</label>
            <div class="pl-0">
              <div
                class="form-group row"
                v-for="gameProduct in gameProducts.filter(
                  (ele) => ele.category == 'LIVECASINO'
                )"
                :key="'form-game-group-' + gameProduct.id"
              >
                <label
                  :for="'form-game-bet-limit-data-' + gameProduct.id"
                  class="col-12 col-sm-12"
                >
                  {{ gameProduct.name }}:
                </label>
                <div class="col-12">
                  <ValidationProvider rules="" v-slot="v">
                    <textarea
                      :id="'form-game-bet-limit-data-' + gameProduct.id"
                      class="form-control mr-1"
                      v-model="
                        form.gameBetLimitProducts.find(
                          (ele) => ele.gameProductId == gameProduct.id
                        ).data
                      "
                      rows="4"
                      autocomplete="off"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <div class="col-12 text-right">
                <button class="btn btn-danger" @click="closeForm" type="button">
                  ยกเลิก
                </button>
                <button class="btn btn-primary ml-3" type="submit">
                  {{ form.id ? "บันทึก" : "เพิ่ม" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style>
</style>

<script>
import GameProvider from "@/resources/game_provider"
import SettingProvider from "@/resources/setting_provider"
const gameService = new GameProvider()
const settingService = new SettingProvider()
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        gameBetLimitProducts: [],
      },
      items: [],
      gameProducts: [],
      gameBetLimitProducts: [],
      isLoading: false,
    }
  },
  mounted() {
    this.clearForm()
    this.getAllGameBetLimit()
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("game_bet_limit_edit") 
    },
  },
  methods: {
    clearForm() {
      this.form.id = null
      this.form.name = ""
      this.form.gameBetLimitProducts = []
      this.gameProducts.forEach((item) => {
        this.form.gameBetLimitProducts.push({
          gameBetLimitId: null,
          gameProductId: item.id,
          data: "",
        })
      })
    },
    openFormCreate() {
      this.clearForm()
      this.$bvModal.show("modal-form")
    },
    openFormEdit(item) {
      this.clearForm()
      this.form.id = item.id
      this.form.name = item.name
      this.form.gameBetLimitProducts = []
      this.gameProducts.forEach((product) => {
        let limitProduct = item.gameBetLimitProducts.find(
          (ele) => ele.gameProductId == product.id
        )
        limitProduct = limitProduct
          ? Object.assign({}, limitProduct)
          : {
              gameBetLimitId: item.id,
              gameProductId: product.id,
              data: "",
            }
        this.form.gameBetLimitProducts.push(limitProduct)
      })
      this.$bvModal.show("modal-form")
    },
    closeForm() {
      this.$bvModal.hide("modal-form")
    },
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      const res = !this.form.id
        ? await settingService.addGameBetLimit(this.form)
        : await settingService.editGameBetLimit(this.form.id, this.form)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          !this.form.id
            ? "เพิ่ม ขั้นต่ำ/สูงสุด เกมสำเร็จ"
            : "แก้ไข ขั้นต่ำ/สูงสุด เกมสำเร็จ",
          () => {
            this.getAllGameBetLimit()
            this.closeForm()
          }
        )
      }
    },
    async getAllGameBetLimit() {
      this.isLoading = true
      const res = await gameService.getAllGameBetLimit()
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }

      res.result.list.forEach((item) => {
        item.gameBetLimitProducts = res.result.gameBetLimitProducts.filter(
          (ele) => ele.gameBetLimitId == item.id
        )
      })
      this.items = res.result.list
      this.gameBetLimitProducts = res.result.gameBetLimitProducts
      this.gameProducts = res.result.gameProducts

      this.isLoading = false
    },
  },
}
</script>