export function formatMoney(amount) {
  if (!amount) {
    return "0.00"
  }
  return parseFloat(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  })
}

export function formatMoneyNormal(amount) {
  if (amount === 0) {
    return "0"
  }

  if (!amount) {
    return ""
  }

  return parseFloat(amount).toLocaleString("en-US")
}

export function getClassAmount(a) {
  if (!a || parseFloat(a) == parseFloat(0)) {
    return ""
  }
  if (parseFloat(a) > parseFloat(0)) {
    return "positive"
  }
  return "negative"
}

export function groupBy(xs, key) {
  let res = xs.reduce(function (rv, x) {
    rv[x[key]] = rv[x[key]] || []
    rv[x[key]].push(x)
    return rv
  }, {})

  let sortable = []
  for (var v in res) {
    sortable.push([v, res[v]])
  }

  sortable.sort(function (a, b) {
    return parseFloat(a[0]) > parseFloat(b[0]) ? 1 : -1
  })

  return sortable
}
