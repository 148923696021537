<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มเกม', 'เปิด-ปิด เกม/ค่ายเกม']"
    />
    <platform-nav name="เปิด-ปิด เกม/ค่ายเกม"></platform-nav>
    <div class="alert alert-info" role="alert" v-if="isNotFound">
      ไม่พบแพลตฟอร์ม
    </div>
    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div v-if="!isNotFound">
        <div class="alert alert-info" role="alert">
          สถานะ: <span class="table-danger text-white">แดง=ระงับ</span>
        </div>
        <div class="d-flex mb-1">
          <input
            id="showPlatformName"
            type="checkbox"
            class="cursor-pointer mr-1 my-auto"
            v-model="showPlatformName"
          />
          <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
        </div>
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="เปิด/ปิด ค่ายเกม"
              class="p-0"
              :active="
                !$route.query.page || $route.query.page == 'game-product'
              "
              @click="setPage('game-product')"
            >
              <b-card-text>
                <b-tabs card>
                  <b-tab
                    :title="item.name"
                    v-for="(item, index) in gameProducts"
                    :key="index"
                    :active="$route.query.id == item.id"
                    @click="setId(item.id)"
                  >
                    <b-card-text>
                      <div class="table-responsive">
                        <table
                          class="
                            table table-bordered table-hover table-dark
                            text-center
                            fs-075
                          "
                        >
                          <thead>
                            <tr>
                              <th>
                                <button
                                  class="btn btn-success mr-1"
                                  :disabled="!canEdit"
                                  @click="saveGameProduct(item.id, 'OPEN')"
                                >
                                  เปิด
                                </button>
                                <button
                                  class="btn btn-danger mr-1"
                                  :disabled="!canEdit"
                                  @click="saveGameProduct(item.id, 'CLOSE')"
                                >
                                  ปิด
                                </button>
                              </th>
                              <th>
                                <input
                                :disabled="!canEdit"
                                  type="checkbox"
                                  v-model="checkAllGameProduct[index]"
                                  @change="
                                    toggleCheckAllGameProduct(item.id, index)
                                  "
                                />
                              </th>
                              <th>สถานะ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                platform, index2
                              ) in filterPlatformGameProduct(item.id)"
                              :key="index2"
                              :class="{
                                'table-danger':
                                  platform.platformStatus == 'CLOSE',
                              }"
                            >
                              <td class="text-left">
                                <router-link
                                  :to="{
                                    name: 'EditPlatformGameOpenCloseByPlatformId',
                                    params: { platformId: platform.platformId },
                                    query: {
                                      page: 'game-product',
                                      id: item.id,
                                    },
                                  }"
                                >
                                  {{ platform.platformCode }}
                                </router-link>
                                <span v-if="showPlatformName"
                                  >({{ platform.platformName }})</span
                                >
                              </td>
                              <td>
                                <input
                                :disabled="!canEdit"
                                  type="checkbox"
                                  v-model="platform.check"
                                />
                              </td>
                              <td>
                                {{
                                  gameProductStatus(
                                    platform.platformId,
                                    item.id
                                  ) == "OPEN"
                                    ? "เปิด"
                                    : "ปิด"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
            <b-tab
              title="เปิด/ปิด เกม"
              :active="$route.query.page == 'game'"
              @click="setPage('game')"
            >
              <div class="form-group">
                <label for="gameProduct">ค่ายเกม</label>
                <select
                  id="gameProduct"
                  class="form form-control w-auto"
                  :disabled="!canEdit"
                  v-model="gameProductItem"
                >
                  <option disabled>เลือกค่ายเกม</option>
                  <option
                    v-for="(
                      gameProductItem, gameProductItemIndex
                    ) in gameProducts"
                    :key="gameProductItemIndex"
                    :value="gameProductItem"
                  >
                    {{ gameProductItem.name }}
                  </option>
                </select>
              </div>
              <div v-if="gameProductItem" class="form-group">
                <label for="game">เกม</label>
                <select
                  id="game"
                  class="form form-control w-auto"
                  v-model="gameItem"
                >
                  <option disabled>เลือกเกม</option>
                  <option
                    v-for="(
                      gameItem, gameItemIndex
                    ) in filterGameByGameProductId(gameProductItem.id)"
                    :key="gameItemIndex"
                    :value="gameItem"
                  >
                    {{ gameItem.name }}
                  </option>
                </select>
                <div v-if="gameItem" class="table-responsive mt-3">
                  <table
                    class="
                      table table-bordered table-hover table-dark
                      text-center
                      fs-075
                    "
                  >
                    <thead>
                      <tr>
                        <th>
                          <button
                            class="btn btn-success mr-1"
                            :disabled="!canEdit"
                            @click="saveGame(gameItem.id, 'OPEN')"
                          >
                            เปิด
                          </button>
                          <button
                            class="btn btn-danger mr-1"
                            :disabled="!canEdit"
                            @click="saveGame(gameItem.id, 'CLOSE')"
                          >
                            ปิด
                          </button>
                        </th>
                        <th>
                          <input
                            type="checkbox"
                            :disabled="!canEdit"
                            v-model="checkAllGame[index]"
                            @change="toggleCheckAllGame(gameItem.id, index)"
                          />
                        </th>
                        <th>สถานะ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(platform, index2) in filterPlatformGame(
                          gameItem.id
                        )"
                        :key="index2"
                        :class="{
                          'table-danger': platform.platformStatus == 'CLOSE',
                        }"
                      >
                        <td class="text-left">
                          <router-link
                            :to="{
                              name: 'EditPlatformGameOpenCloseByPlatformId',
                              params: {
                                platformId: platform.platformId,
                              },
                              query: {
                                page: 'game',
                                id: gameItem.id,
                              },
                            }"
                          >
                            {{ platform.platformCode }}
                          </router-link>
                          <span v-if="showPlatformName"
                            >({{ platform.platformName }})</span
                          >
                        </td>
                        <td>
                          <input type="checkbox" v-model="platform.check" />
                        </td>
                        <td>
                          {{
                            gameStatus(platform.platformId, gameItem.id) ==
                            "OPEN"
                              ? "เปิด"
                              : "ปิด"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPlatformName: true,
      isLoading: true,
      isNotFound: false,
      platformGameProducts: [],
      platformGames: [],
      gameProducts: [],
      games: [],
      checkAllGameProduct: [],
      checkAllGame: [],
      gameProductItem: null,
      gameItem: null,
    }
  },
  computed: {
    filterHuayPayOpen() {
      return this.huayPays.filter((item) => item.status == "OPEN")
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_platform_game_open_close_edit") 
    },
  },
  watch: {
    gameProductItem() {
      this.gameItem = null
    },
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    setId(val) {
      this.$route.query.id = val
    },
    filterGameByGameProductId(gameProductId) {
      return this.games.filter((item) => item.gameProductId === gameProductId)
    },
    filterPlatformGameProduct(gameProductId) {
      return this.platformGameProducts.filter(
        (item) => item.gameProductId == gameProductId
      )
    },
    filterPlatformGame(gameId) {
      return this.platformGames.filter((item) => item.gameId == gameId)
    },
    toggleCheckAllGameProduct(gameProductId, index) {
      this.platformGameProducts.forEach((item) => {
        if (item.gameProductId == gameProductId) {
          item.check = this.checkAllGameProduct[index]
        }
      })
    },
    toggleCheckAllGame(gameId, index) {
      this.platformGames.forEach((item) => {
        if (item.gameId == gameId) {
          item.check = this.checkAllGame[index]
        }
      })
    },
    gameProductStatus(platformId, gameProductId) {
      let result = this.platformGameProducts.find(
        (item) =>
          item.platformId == platformId && item.gameProductId == gameProductId
      )
      if (result) {
        return result.status
      }
    },
    gameStatus(platformId, gameId) {
      let result = this.platformGames.find(
        (item) => item.platformId == platformId && item.gameId == gameId
      )
      if (result) {
        return result.status
      }
    },
    async saveGameProduct(gameProductId, status) {
      let platformGameProducts = this.platformGameProducts.filter(
        (item) => item.check && item.gameProductId == gameProductId
      )

      if (platformGameProducts.length == 0) {
        return
      }

      platformGameProducts.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "platform/openclose-game-product",
          data: {
            platformGameProducts: platformGameProducts,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        }).then(this.initData())
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async saveGame(gameId, status) {
      let platformGames = this.platformGames.filter(
        (item) => item.check && item.gameId == gameId
      )

      if (platformGames.length == 0) {
        return
      }

      platformGames.forEach((item) => {
        item.status = status
      })

      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "platform/openclose-game",
          data: {
            platformGames: platformGames,
          },
        })

        await this.$swal({
          icon: "success",
          text: "สำเร็จ",
        }).then(this.initData())
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }

      this.$store.dispatch("auth/forceReload")
    },
    async initData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "platform/openclose-game",
          params: {
            platformId: this.$route.params.platformId,
          },
        })

        if (res.data.code == -4) {
          this.isNotFound = true
          this.isLoading = false
          return
        }
        let data = res.data.data

        data.platformGameProducts.forEach((item) => {
          let platform = data.platforms.find(
            (item2) => item2.id == item.platformId
          )
          if (platform) {
            item.platformStatus = platform.status
            item.platformCode = platform.code
            item.platformName = platform.name
            item.platformId = platform.id
            item.check = false
          }
        })

        data.platformGames.forEach((item) => {
          let platform = data.platforms.find(
            (item2) => item2.id == item.platformId
          )
          if (platform) {
            item.platformStatus = platform.status
            item.platformCode = platform.code
            item.platformName = platform.name
            item.platformId = platform.id
            item.check = false
          }
        })

        this.platformGameProducts = data.platformGameProducts || []
        this.platformGames = data.platformGames || []
        this.games = data.games || []
        this.gameProducts = data.gameProducts || []

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.initData()
  },
}
</script>
