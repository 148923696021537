<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าบัญชี']" />
    <div class="card border-primary mb-3">
      <h5 class="card-header border-primary bg-primary text-white">
        เปลี่ยนรหัสผ่าน
      </h5>
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          tag="form"
          @submit.prevent="changePassword"
          novalidate
        >
          <div class="form-group">
            <label for="old-password">รหัสผ่านเดิม</label>
            <ValidationProvider rules="required" v-slot="v">
              <input
                id="old-password"
                type="password"
                class="form-control mr-1"
                v-model="form.oldPassword"
                autocomplete="off"
                placeholder="รหัสผ่านเดิม"
              />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label for="new-password">รหัสผ่านใหม่</label>
            <ValidationProvider
              name="password"
              vid="password"
              rules="required|min:8"
              v-slot="v"
            >
              <input
                id="new-password"
                type="password"
                class="form-control mr-1"
                v-model="form.newPassword"
                autocomplete="off"
                placeholder="รหัสผ่านใหม่"
              />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label for="new-password-confirm">รหัสผ่านใหม่ อีกครั้ง</label>
            <ValidationProvider
              rules="required|password_confirm:@password"
              v-slot="v"
            >
              <input
                id="new-password-confirm"
                type="password"
                class="form-control mr-1"
                v-model="form.newPasswordConfirm"
                autocomplete="off"
                placeholder="รหัสผ่านใหม่ อีกครั้ง"
              />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <button type="submit" class="btn btn-primary">ตกลง</button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AuthProvider from "@/resources/auth_provider"
const authService = new AuthProvider()

export default {
  name: "ProfileSetting",
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
    }
  },
  methods: {
    async changePassword() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }
      this.swal.processing()
      const res = await authService.changePassword({
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword,
      })
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        await this.swal.swalSuccess("เปลี่ยนรหัสผ่านสำเร็จ")

        this.$store.dispatch("auth/forceReload")
      }
    },
  },
}
</script>