<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['การเงิน', 'ประวัติการเงิน']" />
    <div class="card border-primary mb-3" style="width: 650px">
      <h5 class="card-header border-primary bg-primary text-white">
        ตัวเลือกการค้นหา
      </h5>
      <div class="card-body">
        <div class="d-flex mb-2">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="fromDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <span class="mx-2 my-auto">ถึง</span>
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="toDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <button class="btn btn-primary ml-2" @click="initData">ค้นหา</button>
        </div>

        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('TODAY')"
        >
          วันนี้
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('YESTERDAY')"
        >
          เมื่อวาน
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('WEEK')"
        >
          สัปดาห์นี้
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('LASTWEEK')"
        >
          สัปดาห์ที่แล้ว
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('MONTH')"
        >
          เดือนนี้
        </button>
        <button
          class="btn btn-sm btn-warning mr-2"
          @click="setDateRange('LASTMONTH')"
        >
          เดือนที่แล้ว
        </button>
      </div>
    </div>
    <loading v-show="isLoading" />
    <b-card no-body style="min-width: 500px" v-show="!isLoading">
      <b-tabs card>
        <b-tab
          title="ฝากถอน"
          :active="page == 'balance'"
          @click="page = 'balance'"
        >
          <b-card-text>
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-hover table-dark
                  text-center
                  fs-075
                "
              >
                <thead>
                  <tr>
                    <th>วันที่-เวลา</th>
                    <th class="width-desc">รายละเอียด</th>
                    <th>จำนวน</th>
                    <th>คงเหลือ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in creditTransferHistory"
                    :key="index"
                    :class="item.class"
                  >
                    <td>
                      {{ $date(item.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
                    </td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.newFromCreditBalance }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab
          title="เครดิต"
          :active="page == 'maximum'"
          @click="page = 'maximum'"
        >
          <b-card-text>
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-hover table-dark
                  text-center
                  fs-075
                "
              >
                <thead>
                  <tr>
                    <th>วันที่-เวลา</th>
                    <th class="width-desc">รายละเอียด</th>
                    <th>เครดิตเดิม</th>
                    <th>เครดิตใหม่</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in creditSetHistory" :key="index">
                    <td>
                      {{ $date(item.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
                    </td>
                    <td>
                      {{ item.fromUsername }} ปรับเครดิตให้
                      {{ item.toUsername }}
                    </td>
                    <td>
                      {{ item.oldCreditMaximum }}
                    </td>
                    <td>
                      {{ item.newCreditMaximum }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<style scoped>
.line-height-38 {
  line-height: 38px;
}

.width-desc {
  width: 20rem;
}
</style>

<script>
export default {
  data() {
    return {
      isLoading: true,
      fromDate: this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      creditTransferHistory: [],
      creditSetHistory: [],
      page: "balance",
    }
  },
  methods: {
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.initData()
    },
    async initData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "post",
          url: "credit/transfer-history",
          data: {
            from: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            to: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        if (res.data.code == -4) {
          this.isLoading = false
          return
        }

        let data = res.data.data
        if (data.creditTransferHistory) {
          data.creditTransferHistory.forEach((item) => {
            let text = "เติมเงินให้"
            item.class = "table-success"
            if (item.code == "WITHDRAW") {
              text = "ถอนเงินจาก"
              item.class = "table-danger"
            }
            item.description = `${item.fromUsername} ${text} ${item.toUsername}`
          })
        }

        this.creditTransferHistory = data.creditTransferHistory
        this.creditSetHistory = data.creditSetHistory
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  async mounted() {
    await this.initData()
  },
}
</script>