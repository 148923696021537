<template>
  <div class="
      d-flex
      justify-content-between
      align-items-center
      rounded
      shadow-sm
      border-white border
      p-3
    ">
    <div class="h3 mb-0 px-3">
      <i class="fas" :class="`fa-${icon}`"></i>
    </div>
    <div class="text-right">
      <div class="text-uppercase fs-sm mb-1">
        {{ header }}
      </div>
      <div class="fs-lg" :class="bodyClass || getClassFromNumber(body)">
        {{ util.formatMoneyNormal(body) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    body: [String, Number],
    bodyClass: String,
    icon: String,
  },
  methods: {
    getClassFromNumber(number) {
      if (number < 0) {
        return "negative"
      } else if (number > 0) {
        return "positive"
      }

      return ""
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-sm {
  font-size: 0.875rem;
}

.fs-lg {
  font-size: 2rem;
}
</style>