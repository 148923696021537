<template>
  <div>
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <CDataTable
        :items="items"
        :fields="fields"
        :column-filter="{ external: true }"
        :items-per-page-select="{ label: 'จำนวนแถว' }"
        :table-filter-value.sync="tableFilterValue"
        :column-filter-value.sync="columnFilterValue"
        :sorter-value.sync="sorterValue"
        :items-per-page.sync="itemsPerPage"
        :loading="tableLoading"
        :noItemsView="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }"
        v-on:pagination-change="paginationChange"
        v-on:update:column-filter-value="columnFilter"
        hover
        sorter
        dark
        border
        size="sm"
        addTableClasses="w-100"
      >
        <template #fromUserId="{ item }">
          <td>
            <router-link
              v-if="item.fromUserId !== 0"
              :to="`/members/${item.fromUserId}`"
              >{{ item.fromUserId }}</router-link
            >
            <span v-if="item.fromUserId === 0">{{ item.fromUserId }}</span>
          </td>
        </template>
        <template #toUserId="{ item }">
          <td>
            <router-link
              v-if="item.toUserId !== 0"
              :to="`/members/${item.toUserId}`"
              >{{ item.toUserId }}</router-link
            >
            <span v-if="item.toUserId === 0">{{ item.toUserId }}</span>
          </td>
        </template>
      </CDataTable>
      <CPagination
        :active-page="activePage"
        :double-arrows="false"
        :pages="totalPage"
        :dots="false"
        v-on:update:activePage="updateActivePage"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          filter: true,
          sorter: false,
        },
        {
          key: "code",
          label: "code",
          sorter: false,
        },
        {
          key: "description",
          label: "รายละเอียด",
          sorter: false,
          filter: false,
        },
        {
          key: "fromUserId",
          label: "#คนโอน",
          sorter: false,
          filter: this.$route.params.userId ? false : true,
        },
        {
          key: "toUserId",
          label: "#คนรับ",
          sorter: false,
          filter: this.$route.params.userId ? false : true,
        },
        {
          key: "amount",
          label: "จำนวนเงิน",
          sorter: false,
        },
        {
          key: "oldFromCreditBalance",
          label: "จำนวนเงินเดิม(คนโอน)",
          sorter: false,
          filter: false,
        },
        {
          key: "newFromCreditBalance",
          label: "จำนวนใหม่(คนโอน)",
          sorter: false,
          filter: false,
        },
        {
          key: "oldToCreditBalance",
          label: "จำนวนเงินเดิม(คนรับ)",
          sorter: false,
          filter: false,
        },
        {
          key: "newToCreditBalance",
          label: "จำนวนใหม่(คนรับ)",
          sorter: false,
          filter: false,
        },
        {
          key: "createdAt",
          label: "เวลา",
          sorter: false,
          filter: false,
        },
      ],
      items: [],
      isLoading: true,
      tableFilterValue: "",
      columnFilterValue: {
        fromUserId: null,
        toUserId: null,
      },
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
    }
  },
  methods: {
    loadData() {
      this.tableLoading = true

      if (this.$route.params.userId) {
        this.columnFilterValue.fromUserId = this.$route.params.userId
        this.columnFilterValue.toUserId = this.$route.params.userId
      }

      this.axios({
        method: "get",
        url: "credit/transfer/history",
        params: {
          ...this.columnFilterValue,
          page: this.activePage,
          pageSize: this.itemsPerPage,
        },
      })
        .then((res) => {
          let data = res.data.data
          data.data?.forEach((item) => {
            let codeClass = ""
            if (item.toUserId !== 0) {
              codeClass = "positive"
            } else {
              codeClass = "negative"
            }

            item._cellClasses = {
              code: codeClass,
              description: "text-wrap",
            }

            item.createdAt = this.$date(item.createdAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
            item.amount = this.util.formatMoney(item.amount)
            item.oldFromCreditBalance = this.util.formatMoney(
              item.oldFromCreditBalance
            )
            item.newFromCreditBalance = this.util.formatMoney(
              item.newFromCreditBalance
            )
            item.oldToCreditBalance = this.util.formatMoney(
              item.oldToCreditBalance
            )
            item.newToCreditBalance = this.util.formatMoney(
              item.newToCreditBalance
            )
          })

          // this.totalPage = data.totalPage;
          this.items = data.data
          this.isLoading = false
          this.tableLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    paginationChange(val) {
      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.items &&
        this.items.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>