<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่า', 'กลุ่มหวย']" />
    <div class="card border-primary">
      <h5 class="card-header border-primary bg-primary text-white">
        รายการกลุ่มหวย
      </h5>
      <div class="card-body">
        <button
          class="btn btn-success mb-2"
          @click="$router.push('/setting/lotto-group/add')"
          v-show="canEdit"
        >
          เพิ่มกลุ่มหวย
        </button>
        <loading v-show="isLoading" />
        <h5 class="text-center" v-if="!isLoading && !items.length">
          ไม่มีข้อมูล
        </h5>
        <div class="table-responsive" v-show="!isLoading && items.length">
          <table
            class="
              table table-bordered table-striped table-hover table-dark
              text-center
            "
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>ชื่อ</th>
                <th>ลำดับการแสดง</th>
                <th>สถานะ</th>
                <th>เวลาสร้าง</th>
                <th>แก้ไขล่าสุด</th>
                <th>แก้ไข</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.showOrder }}</td>
                <td>{{ item.statusTH }}</td>
                <td>
                  {{ $date(item.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  {{ $date(item.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  <router-link
                    class="btn btn-primary"
                    :class="{disabled:!canEdit}"
                    :to="{
                      path: '/setting/lotto-group/edit',
                      query: { id: item.id },
                    }"
                  >
                    <i class="fa fa-edit"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  computed: {
    items: function () {
      return this.$store.state.lotto.lottoGroups
    },
    isLoading: function () {
      return this.$store.state.lotto.isLottoGroupLoading
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("lotto_group_list_edit") 
    },
  },
  mounted() {
    this.getLottoGroupList()
  },
  methods: {
    getLottoGroupList() {
      this.$store.dispatch("lotto/getAllLottoGroupList", {}, { root: true })
    },
  },
}
</script>